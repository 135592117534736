import React, { createContext, useState, useEffect } from "react";

const encryptionKey = "h8s&K9Lq#2oP!xR3T$yU4vW*5zE6A@7S8dF9gJ0hI1jK2";

// encrypt data
const encryptData = (data) => {
  let encryptedData = "";
  for (let i = 0; i < data.length; i++) {
    encryptedData += String.fromCharCode(
      data.charCodeAt(i) ^ encryptionKey.charCodeAt(i % encryptionKey.length)
    );
  }
  return encryptedData;
};

// decrypt data
const decryptData = (encryptedData) => {
  let decryptedData = "";
  for (let i = 0; i < encryptedData.length; i++) {
    decryptedData += String.fromCharCode(
      encryptedData.charCodeAt(i) ^
        encryptionKey.charCodeAt(i % encryptionKey.length)
    );
  }
  return decryptedData;
};

export const UserDataContext = createContext();

export const DataContextProvider = ({ children }) => {
  const initialEncryptedData = localStorage.getItem("userData");
  const [userData, setUserData] = useState(
    initialEncryptedData
      ? JSON.parse(decryptData(initialEncryptedData))
      : { userName: "", employeeRoleId: "", employeeBuId: "" }
  );

  const resetUserData = () => {
    setUserData({
      userName: "",
      employeeRoleId: "",
      employeeBuId: "",
    });
  };

  const updateUserData = (newData) => {
    setUserData((prevData) => ({ ...prevData, ...newData }));
  };

  useEffect(() => {
    if (
      userData.userName !== "" &&
      userData.employeeRoleId !== "" &&
      userData.employeeBuId !== ""
    ) {
      localStorage.setItem("userData", encryptData(JSON.stringify(userData)));
    } else {
      localStorage.removeItem("userData");
    }
  }, [userData]);

  return (
    <UserDataContext.Provider
      value={{
        userData,
        setUserData,
        resetUserData,
        updateUserData,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
