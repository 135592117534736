import React from "react";
import Layout from "../Layout/Layout";
import LeaveBalances from "../../components/Leaves/LeaveBalances/LeaveBalances";

function LeaveBalancesPage() {
  return (
    <Layout>
      <LeaveBalances />
    </Layout>
  );
}

export default LeaveBalancesPage;
