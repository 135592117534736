import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_API } from "../../constants/constants";
import axios from "../../axios/axios";
import "../../assets/css/DashBoard.css";

function UpcommingHolidays() {
  const navigate = useNavigate();
  const [HolidayData, setHolidayData] = useState([]);

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    logInToken &&
      axios
        .post(
          DASHBOARD_API.FETCH_UPCOMING_HOLIDAY,
          {},
          {
            headers: {
              Authorization: `Bearer ${logInToken}`,
              UserId: `${userId}`,
            },
          }
        )
        .then((response) => {
          setHolidayData(response.data);
        })
        .catch((error) => {
          console.error("error", error);
        });
  }, [logInToken, userId]);

  return (
    <>
      <div className="Holiday-wrap">
        <div className="Holiday-wrap-head">
          <h3 className="fzr-1">Upcoming Holidays</h3>
          <button
            onClick={() => {
              navigate("/calendar/holiday");
            }}
            className="viewall-btn"
          >
            View all
          </button>
        </div>
        <div className="Holiday-inner-wrap">
          {HolidayData?.map((data, id) => (
            <div className="Holiday-date-list-box" key={id}>
              <div className="Holiday-date-display">
                <div className="font-bold">{data?.holidayMonth}</div>
                <p>{data?.holidayDay}</p>
              </div>
              <div className="Holiday-details-display">
                <p>{data?.holidayName}</p>
                <p className="holiday_bu-name fzr-08">{data?.buName}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default UpcommingHolidays;
