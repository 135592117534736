import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// pages path import 

import BUcreatepage from "../pages/BU/BUCREATE/CreateBU";

import LeaveListPage from "../pages/LeavePage/LeaveListPage";
import LeaveApplyPage from "../pages/LeavePage/LeaveApplyPage";
import LeaveBalancesPage from "../pages/LeavePage/LeaveBalancesPage";
import ApplyLeaveListPage from "../pages/LeavePage/ApplyLeaveListPage";

import EmployeeListPage from "../pages/Employee/EmployeeList";
import EmployeeAlumniPage from "../pages/Employee/EmployeeAlumniPage";
import EmployeeLeaveAllocation from "../pages/Employee/EmployeeLeaveAllocation";
import AddEmployeeLeaveAllocation from "../pages/Employee/AddEmployeeLeaveAllocation";
import EmployeeCompensationLeave from "../pages/Employee/EmployeeCompensationLeave";
import CompenstationLeaveListPage from "../pages/Employee/CompenstationLeaveListPage";



import AddHolidayPage from "../pages/Holiday/AddHoliday/AddHoliday"; 
import EditHolidayPage from "../pages/Holiday/EditHoliday/EditHoliday"; 
import HolidayCalender from "../pages/Holiday/HolidayCalender/HolidayCalender"; 
import AddWorkingDayPage from "../pages/SpecailWorkingDay/AddWorkingDays/AddworkingDays"; 
import WorkCalender from "../pages/SpecailWorkingDay/WorkingDay/WorkingDayCalender"; 
import AuthorizeRoute from "./AuthorizeRoute";
import BUlistpage from "../pages/BU/BULIST/BUList";


import LeaveRequestsPage from "../pages/LeavePage/LeaveRequestsPage";

import AttendanceReportPage from "../pages/Attendance/AttendanceReportPage";
import DailyLog from "../pages/Administrative/DailyLog/DailyLog";
import ChangePassword from "../pages/Settings/Password/ChangePassword";
import LoginHistory from "../pages/Settings/LoginHistory/LoginHistory";

import AttendancePage from '../pages/Attendance/AttendancePage'


import NotFound from "../pages/Error/NotFound/NotFound";


// lazy loads 
const BUEditPage = lazy(() => import("../pages/BU/BUEDIT/BUEdit"));
const CreateLeavePage = lazy(()=>import("../pages/LeavePage/CreateLeavePage"))
const EditLeavePage = lazy(()=>import("../pages/LeavePage/EditLeavePage"))
const CreateEmployeePage =  lazy(()=>import("../pages/Employee/CreateEmployeePage"))
const EditEmployeePage =  lazy(()=>import("../pages/Employee/EditEmployeePage"))
const EmployeeViewPage =  lazy(()=>import("../pages/Employee/EmployeeViewPage"))
const WorkingDayListPage =  lazy(()=>import("../pages/SpecailWorkingDay/WorkingDayList/WorkingDayList"))
const ListHolidayPage =  lazy(()=>import("../pages/Holiday/HolidayList/HolidayList"))
const LeaveHistoryPage =  lazy(()=>import("../pages/LeavePage/LeaveHistoryPage"))
const LeaveReport =  lazy(()=>import("../pages/Administrative/LeveReport/LeaveReport"))

export const ConfigRoutes = {
  BURoutes : () => {
    const BUcreatepageAuthCheck = AuthorizeRoute(BUcreatepage,"1")
    const BUEditPageAuthCheck = AuthorizeRoute(BUEditPage,"1")  // :lazy 
    return (
      <Routes>
        <Route path="/" element={<Navigate to ="list" />} />
        <Route path="/list" element={<BUlistpage />} />
        <Route path="/create" element={<BUcreatepageAuthCheck />} />
        <Route path="/edit/:buId" element={<BUEditPageAuthCheck />} />
      </Routes>
    )
  },
  LeaveRoutes:  () => {
  const LeaveBalanceAuthCheck = AuthorizeRoute(LeaveBalancesPage,"234")
  const LeaveCreateAuthCheck = AuthorizeRoute(CreateLeavePage,"13") //:lazy
  const LeaveApplyAuthCheck = AuthorizeRoute(LeaveApplyPage,"234")
  const EditLeavePageAuthCheck = AuthorizeRoute(EditLeavePage,"13") //: lazy
  const AppliedLeavesAuthCheck = AuthorizeRoute(ApplyLeaveListPage,"234") 
    return (
      <Routes>
        <Route path="/" element={<Navigate to="balance" />} />
        <Route path="balances" element={<LeaveBalanceAuthCheck />} />  
        <Route path="create" element={<LeaveCreateAuthCheck />}  />
        <Route path="list" element={<LeaveListPage />} />
        <Route path="leave-apply" element={<LeaveApplyAuthCheck />}  />
        <Route path="edit/:leaveId" element={<EditLeavePageAuthCheck />} />
        <Route path="applied-leaves" element={<AppliedLeavesAuthCheck />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    );
  },
  EmployeeRoutes: () => {
    const EmployeeCreateAuthCheck = AuthorizeRoute(CreateEmployeePage,"13") // :lazy
    const EditEmployeePageAuthCheck = AuthorizeRoute(EditEmployeePage,"13") // :lazy
    const EmployeeViewPageAuthCheck = AuthorizeRoute(EmployeeViewPage,"13") // :lazy
    const EmployeeLeaveAllocationAuthCheck = AuthorizeRoute(EmployeeLeaveAllocation,"13")
    const AddEmployeeLeaveAllocationAuthCheck = AuthorizeRoute(AddEmployeeLeaveAllocation,"13")
    const AddCompensationLeaveAuthCheck = AuthorizeRoute(EmployeeCompensationLeave,"13") 
    const CompensationLeaveListAuthCheck = AuthorizeRoute(CompenstationLeaveListPage,"13") 
    return (
      <Routes>
        <Route path="/" element={<Navigate to="list" />} />
        <Route path="list" element={<EmployeeListPage />} />
        <Route path="create" element={<EmployeeCreateAuthCheck />} />
        <Route path="edit/:employeeId" element={<EditEmployeePageAuthCheck />} />
        <Route path="view/:employeeId" element={<EmployeeViewPageAuthCheck />} />
        <Route path="alumni" element={<EmployeeAlumniPage />} />
        <Route path="leave-allocation" element={<EmployeeLeaveAllocationAuthCheck />} />
        <Route path="add-leave-allocation" element={<AddEmployeeLeaveAllocationAuthCheck />}/>
        <Route path="add-compensation-leave-allocation" element={<AddCompensationLeaveAuthCheck />}/>
        <Route path="compensation-leave-list" element={<CompensationLeaveListAuthCheck />}/>
        <Route path="/*" element={<NotFound />} />
      </Routes>
    );
  },
  CalenderRoutes: () => {
    const AddWorkingDayPageAuthCheck = AuthorizeRoute(AddWorkingDayPage,"3") 
    const EditWorkingDayAuthCheck = AuthorizeRoute(WorkingDayListPage,"3")  //lazy 
    const AddHolidayPageAuthCheck = AuthorizeRoute(AddHolidayPage,"13")  
    const ListHolidayPageAuthCheck = AuthorizeRoute(ListHolidayPage,"13")  // lazy
    return(
        <Routes>
        <Route path="/" element={<Navigate to="holiday" />} />
        <Route path="holiday" element={<HolidayCalender />} />
        <Route path="wokingdays/add" element={<AddWorkingDayPageAuthCheck />} />
        <Route path="wokingdays/list" element={<WorkCalender />} />
        <Route path="wokingdays/edit" element={<EditWorkingDayAuthCheck />} />
        <Route path="holiday/add" element={<AddHolidayPageAuthCheck />} />
        <Route path="holiday/list" element={<ListHolidayPageAuthCheck />} />
        <Route path="holiday/edit/:holidayId" element={<EditHolidayPage />} />         
        <Route path="/*" element={<NotFound />} />
      </Routes>
    )
  },
  RequestsRoutes : () => {
    const LeaveRequestsPageAuthCheck = AuthorizeRoute(LeaveRequestsPage,"234") 
    const LeaveHistoryPageAuthCheck = AuthorizeRoute(LeaveHistoryPage,"234") // lazy
    return (
      <Routes>
        <Route path="leaves" element={<LeaveRequestsPageAuthCheck />} />
        <Route path="leaves/history" element={<LeaveHistoryPageAuthCheck />} /> 
        <Route path="/*" element={<NotFound />} />
      </Routes>
    )
  },
  AdministrativeRoutes : () => {
    const LeaveReportAuthCheck = AuthorizeRoute(LeaveReport,"123") // lazy
    const AttendanceReportPageAuthCheck = AuthorizeRoute(AttendanceReportPage,"123") 
    const DailyLogAuthCheck = AuthorizeRoute(DailyLog,"123") 
    return (
      <Routes>
        <Route path="leave-report"  element = {<LeaveReportAuthCheck />}/>
        <Route path="attendance-report" element ={<AttendanceReportPageAuthCheck/>} /> 
        <Route path="first-login-report" element ={<DailyLogAuthCheck/>} /> 
        <Route path="/*" element={<NotFound />} />
      </Routes>
    )
  },
  SettingsRoutes : () => {
    return (
      <Routes>
        <Route path="change-password" element={<ChangePassword />}/>
        <Route path="login-history" element={<LoginHistory />}/>
      </Routes>
    )
  },
  AttendanceRoute: () => {
    const AttendancePageAuthCheck = AuthorizeRoute(AttendancePage,'234')
    return (
      <Routes>
        <Route path="/"  element={<AttendancePageAuthCheck />}/>
      </Routes>
    )
  }
  
};
