import React from 'react'
import Layout from '../Layout/Layout'
import CompensationLeave from '../../components/Employee/CreateEmployee/CompensationLeave'

function EmployeeCompensationLeave() {
  return (
    <Layout>
      <CompensationLeave />
    </Layout>
  )
}

export default EmployeeCompensationLeave