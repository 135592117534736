// authReducer.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alumni: null,
};

const alumniSlice = createSlice({
  name: "alumni",
  initialState,
  reducers: {
    createAlumniList: (state, action) => {
      state.alumni = action.payload;
    },
    updateAlumniList: (state, action) => {
      const alumniList = state.alumni;
      const updatedLeaves = alumniList.filter(
        (leaf) => leaf.id !== action.payload
      );
      state.alumni = updatedLeaves;
    },
  },
});

export const { createAlumniList, updateAlumniList } = alumniSlice.actions;
export default alumniSlice.reducer;
