import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_API } from "../../constants/constants";
import axios from "../../axios/axios";
import "../../assets/css/DashBoard.css";

function WorkingDayList() {
  const navigate = useNavigate();
  const [workingDay, setworkingDay] = useState([]);

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    logInToken &&
      axios
        .post(
          DASHBOARD_API.FETCH_UPCOMING_WORKING_DAY,
          {},
          {
            headers: {
              Authorization: `Bearer ${logInToken}`,
              UserId: `${userId}`,
            },
          }
        )
        .then((response) => {
          setworkingDay(response.data.specialWorkingDays);
        })
        .catch((error) => {
          console.error("error", error);
        });
  }, [logInToken, userId]);

  return (
    <>
      <div className="workingday-wrap">
        <div className="workingday-wrap-head">
          <h3 className="fzr-1">Upcoming Special Working Days</h3>
          <button
            onClick={() => {
              navigate("/calendar/wokingdays/list");
            }}
            className="viewall-btn"
          >
            View all
          </button>
        </div>
        <div className="workingday-inner-wrap">
          {workingDay?.map((data, index) => (
            <div key={index} className="workingday-date-list-box">
              <div className="workingday-date-display">
                <div className="font-bold">{data?.specialWorkingDate}</div>
                {data?.specialWorkingMonth}
              </div>
              <div className="workingday-details-display">
                <div className="holiday_bu-name">{data?.dayOfWeek}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default WorkingDayList;
