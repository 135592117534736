import React, { useState } from "react";
import AttendanceReport from "../AttendanceReport/AttendanceReport";
import CustomDatePicker from "../../global/Datepicker/CustomDatePicker";
import { useSelector } from "react-redux";
import "../AttendanceReport/AttendanceReport.css";
import axios from "../../../axios/axios";
import formateDate from "../../../util/formateDate";
import AttendancesHeader from "../Attendance/AttendancesHeader";
import { Button } from "@mui/material";
import SingleSelect from "../../global/Select/SingleSelect";
import useFetch from "../../../hooks/useFetch";
import { ATTENDANCE, EMPLOYEE_API } from "../../../constants/constants";
import Title from "../../global/Title/Title";

function EmployeeAttendanceReport() {
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const collapsed = useSelector((state) => state.sidebar.collapsed);

  const [selectDate, setSelectDate] = useState();
  const [selectName, setSelectName] = useState("");
  const [Attendance, setAttendance] = useState([]);
  const [AttendanceTotal, setAttendanceTotal] = useState([]);
  const [firstAndLastLog, setFirstAndLastLog] = useState({});
  const [loading, setLoading] = useState(true);

  const selectEmployee = (selectedValue) => {
    setSelectName((preForm) => ({
      ...preForm,
      employeeId: selectedValue?.value,
      employeeName: selectedValue?.label,
    }));
  };

  const selectStartDate = (value) => {
    setSelectDate(value);
  };

  const clearStartDate = () => {
    setSelectDate(null);
  };

  const handleClick = () => {
    const currentDate = new Date().toISOString().split("T")[0];

    const logDate = selectDate === currentDate ? null : selectDate;

    setFirstAndLastLog({
      lastLogout: "",
      firstLogin: "",
    });

    axios
      .post(
        ATTENDANCE.ALL_USER_ATTENDANCE,
        {
          logDate: logDate ? formateDate(logDate) : null,
          employeeId: selectName.employeeId,
        },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: userId,
          },
        }
      )
      .then((res) => {
        const { employeeAttendanceReport } = res.data;
        setAttendance(employeeAttendanceReport);
        setAttendanceTotal(res.data);
        const lastLogout = employeeAttendanceReport.filter(
          (log) => log?.inOrOut === "out"
        )[0];
        if (lastLogout) {
          setFirstAndLastLog({
            lastLogout: lastLogout.LogDate,
            firstLogin: res.data.firstAndLastLogin.firstLogin,
          });
        } else {
          setFirstAndLastLog({
            lastLogout: "",
            firstLogin: res.data.firstAndLastLogin.firstLogin,
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const responseBUEmployeeList = useFetch(
    EMPLOYEE_API.LIST_EMPLOYEES_BUID,
    { buId: 1 }
  );

  const modifyEmployeeList = responseBUEmployeeList?.responseData?.map(
    (employeeData) => ({
      value: employeeData.id,
      label: employeeData.employeeName,
      employeeId: employeeData.employeeId,
    })
  );

  return (
    <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
      <div className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`} />
      <div className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}>
        <div className="BU-page">
          <Title text="Attendance Report" propClass="pb-10" />
          <div className="attendance_report_function">
            <div className="leave-report_query-select">
              <SingleSelect
                selectOptions={modifyEmployeeList}
                handleSelection={selectEmployee}
                isMulty={false}
                Clearable={true}
                placeholder={"Select Employee"}
              />
            </div>
            <div className="leave-report_query-section_data ">
              <CustomDatePicker
                Date={selectDate}
                setDate={selectStartDate}
                className={""}
                placeholderText={`${new Date()
                  .toLocaleDateString("en-GB")
                  .split("/")
                  .join(" - ")}`}
                isClear={true}
                handleClearCalender={clearStartDate}
              />
            </div>
            <Button
              onClick={handleClick}
              className="attendance_report_btn fzr-09"
            >
              Generate
            </Button>
          </div>
          {Attendance.length !== 0 ? (
            <>
              <AttendancesHeader
                AttendanceDatasToday={AttendanceTotal}
                firstAndLastLog={firstAndLastLog}
                loading={loading}
              />
              <AttendanceReport
                AttendanceDatas={Attendance}
                loading={loading}
              />
            </>
          ) : (
            <p className="no-data-message">No Data avaliable</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmployeeAttendanceReport;
