import React from "react";
import Layout from "../Layout/Layout";
import ApplyLeave from "../../components/Leaves/LeaveApply/ApplyLeave";

function LeaveApplyPage() {
  return (
    <Layout>
      <ApplyLeave />
    </Layout>
  );
}

export default LeaveApplyPage;
