import React from "react";
import Layout from "../Layout/Layout";
import LeaveList from "../../components/Leaves/TypeofLeaves/LeaveList";

function LeaveListPage() {
  return (
    <Layout>
      <LeaveList />
    </Layout>
  );
}

export default LeaveListPage;
