export const Stethoscope = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      d="M13.9831 3.50194C12.9135 3.51758 12.0347 4.39015 12.0128 5.45976C11.9971 6.21974 12.4037 6.8859 13.0136 7.23618V10.7578C13.0136 12.5498 11.4436 14.0104 9.51076 14.0104C7.63425 14.0104 6.09552 12.6311 6.01107 10.9079C8.2879 10.4388 10.008 8.41838 10.008 6.00395V1.14379C10.008 0.787259 9.7547 0.477635 9.40442 0.40883L7.43722 0.0147637C7.03064 -0.0665516 6.63658 0.196159 6.55526 0.602736L6.45518 1.09375C6.37387 1.50033 6.63658 1.8944 7.04315 1.97571L8.0033 2.16649V5.96329C8.0033 7.61774 6.68349 8.98759 5.02904 9.00323C3.35895 9.01886 1.99848 7.66778 1.99848 6.00082V2.16962L2.95862 1.97884C3.3652 1.89752 3.62791 1.50346 3.5466 1.09688L3.44964 0.605863C3.36833 0.199287 2.97426 -0.0634241 2.56769 0.0178912L0.603609 0.405702C0.253328 0.477635 0 0.784131 0 1.14379V6.00395C0 8.42151 1.72326 10.445 4.00634 10.9079C4.08766 13.7351 6.52399 16.012 9.50763 16.012C12.5413 16.012 15.012 13.6538 15.012 10.7578V7.23618C15.6094 6.88903 16.0129 6.24476 16.0129 5.50354C16.0129 4.38702 15.0996 3.4863 13.9831 3.50194ZM14.0112 6.00395C13.736 6.00395 13.5108 5.77876 13.5108 5.50354C13.5108 5.22832 13.736 5.00314 14.0112 5.00314C14.2865 5.00314 14.5116 5.22832 14.5116 5.50354C14.5116 5.77876 14.2865 6.00395 14.0112 6.00395Z"
      fill="#80B4DA"
    />
  </svg>
);

export const Child = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="22"
    viewBox="0 0 16 22"
    fill="none"
  >
    <path
      d="M5 3C5 1.34313 6.34312 0 8 0C9.65688 0 11 1.34313 11 3C11 4.65684 9.65688 6.00001 8 6.00001C6.34312 6.00001 5 4.65684 5 3ZM15.6095 3.05721C15.0888 2.5365 14.2445 2.5365 13.7239 3.05721L10.1144 6.66668H5.88558L2.27612 3.05721C1.75545 2.5365 0.911199 2.5365 0.390532 3.05721C-0.130177 3.57792 -0.130177 4.42213 0.390532 4.94284L4.33333 8.88559V20C4.33333 20.7364 4.93029 21.3334 5.66666 21.3334H6.33333C7.06971 21.3334 7.66667 20.7364 7.66667 20V15.3334H8.33333V20C8.33333 20.7364 8.93029 21.3334 9.66667 21.3334H10.3333C11.0697 21.3334 11.6667 20.7364 11.6667 20V8.88559L15.6095 4.9428C16.1302 4.42209 16.1302 3.57792 15.6095 3.05721Z"
      fill="#80B4DA"
    />
  </svg>
);
export const Casual = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="22"
    viewBox="0 0 16 22"
    fill="none"
  >
    <path
      d="M5 3C5 1.34313 6.34312 0 8 0C9.65688 0 11 1.34313 11 3C11 4.65684 9.65688 6.00001 8 6.00001C6.34312 6.00001 5 4.65684 5 3ZM15.6095 3.05721C15.0888 2.5365 14.2445 2.5365 13.7239 3.05721L10.1144 6.66668H5.88558L2.27612 3.05721C1.75545 2.5365 0.911199 2.5365 0.390532 3.05721C-0.130177 3.57792 -0.130177 4.42213 0.390532 4.94284L4.33333 8.88559V20C4.33333 20.7364 4.93029 21.3334 5.66666 21.3334H6.33333C7.06971 21.3334 7.66667 20.7364 7.66667 20V15.3334H8.33333V20C8.33333 20.7364 8.93029 21.3334 9.66667 21.3334H10.3333C11.0697 21.3334 11.6667 20.7364 11.6667 20V8.88559L15.6095 4.9428C16.1302 4.42209 16.1302 3.57792 15.6095 3.05721Z"
      fill="#80B4DA"
    />
  </svg>
);
export const CreditCard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
  >
    <path
      d="M16.2845 0H1.48377C0.663224 0 0 0.663224 0 1.48069V12.339C0 13.1565 0.663224 13.8197 1.48377 13.8197H16.2845C17.105 13.8197 17.7682 13.1565 17.7682 12.339V1.48069C17.7682 0.663224 17.105 0 16.2845 0ZM1.66886 1.48069H16.0994C16.2012 1.48069 16.2845 1.56397 16.2845 1.66577V2.96137H1.48377V1.66577C1.48377 1.56397 1.56706 1.48069 1.66886 1.48069ZM16.0994 12.339H1.66886C1.56706 12.339 1.48377 12.2558 1.48377 12.154V6.90986H16.2845V12.154C16.2845 12.2558 16.2012 12.339 16.0994 12.339ZM5.92274 9.25428V10.4882C5.92274 10.6918 5.75616 10.8584 5.55257 10.8584H3.33154C3.12795 10.8584 2.96137 10.6918 2.96137 10.4882V9.25428C2.96137 9.05069 3.12795 8.88411 3.33154 8.88411H5.55257C5.75616 8.88411 5.92274 9.05069 5.92274 9.25428ZM11.8455 9.25428V10.4882C11.8455 10.6918 11.6789 10.8584 11.4753 10.8584H7.28004C7.07644 10.8584 6.90986 10.6918 6.90986 10.4882V9.25428C6.90986 9.05069 7.07644 8.88411 7.28004 8.88411H11.4753C11.6789 8.88411 11.8455 9.05069 11.8455 9.25428Z"
      fill="#80B4DA"
    />
  </svg>
);

export const Strat_bg = () => (
  <svg
    width="1728"
    height="1117"
    viewBox="0 0 1728 1117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_275_377)">
      <ellipse
        cx="1453.42"
        cy="893.815"
        rx="973"
        ry="431"
        transform="rotate(-36.0229 1453.42 893.815)"
        fill="url(#paint0_linear_275_377)"
      />
      <ellipse
        cx="1392.19"
        cy="794.089"
        rx="973"
        ry="431"
        transform="rotate(-45.9646 1392.19 794.089)"
        fill="url(#paint1_linear_275_377)"
      />
      <ellipse
        cx="1371.87"
        cy="1157.25"
        rx="973"
        ry="431"
        transform="rotate(-20.109 1371.87 1157.25)"
        fill="url(#paint2_linear_275_377)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_275_377"
        x1="1453.42"
        y1="462.815"
        x2="1453.42"
        y2="1324.81"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FD838E" />
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_275_377"
        x1="1392.19"
        y1="363.089"
        x2="1392.19"
        y2="1225.09"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FD838E" />
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_275_377"
        x1="1371.87"
        y1="726.25"
        x2="1371.87"
        y2="1588.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FB2034" />
        <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_275_377">
        <rect width="1728" height="1117" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const Profile_ico = ({ style }) => (
  <div style={style}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
    >
      <path
        d="M11.2348 13.0959C14.7438 13.0959 17.5878 10.2479 17.5878 6.7337C17.5878 3.21956 14.7438 0.37146 11.2348 0.37146C7.72583 0.37146 4.88189 3.21956 4.88189 6.7337C4.88189 10.2479 7.72583 13.0959 11.2348 13.0959ZM15.9896 14.7163L13.6172 24.2299L12.029 17.47L13.6172 14.6865H8.85248L10.4407 17.47L8.85248 24.2299L6.48005 14.7163C2.94127 14.8853 0.117188 17.7831 0.117188 21.3669V23.4346C0.117188 24.7518 1.18428 25.8204 2.49954 25.8204H19.9701C21.2854 25.8204 22.3525 24.7518 22.3525 23.4346V21.3669C22.3525 17.7831 19.5284 14.8853 15.9896 14.7163Z"
        fill="#898989"
      />
    </svg>
  </div>
);

// -------------------- sidebar icon set -----------------------------

export const SettingsIco = ({ style, color }) => (
  <div style={style}>
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 142.27 148"
      fill={color}
    >
      <defs></defs>
      <g id="finish">
        <g id="Layer_10" data-name="Layer 10">
          <g>
            <path
              className="cls-1"
              d="M28.63,65.48l-.79-.29c-.71-.26-1.46-.49-2.3-.71l-1.5-.39V3.47c0-1.91-1.56-3.47-3.47-3.47s-3.47,1.56-3.47,3.47v60.64l-1.52.37c-1.19.29-2.36.7-3.48,1.22-4.99,2.25-8.81,6.31-10.75,11.44-1.94,5.12-1.77,10.7.49,15.69,2.6,5.77,7.61,9.95,13.75,11.47l1.52.38v39.85c0,1.91,1.56,3.47,3.47,3.47s3.47-1.56,3.47-3.47v-39.85l1.52-.38c10.99-2.73,17.71-13.9,14.97-24.88-1.56-6.27-5.98-11.43-11.9-13.94ZM29.59,87.79c-.89,2.38-2.74,4.35-5.06,5.39-1.27.58-2.61.87-3.98.87-3.77,0-7.21-2.22-8.77-5.65-1.06-2.34-1.15-4.95-.25-7.36.9-2.41,2.69-4.32,5.03-5.38,1.26-.57,2.59-.86,3.97-.86,1.18,0,2.32.21,3.4.63,2.42.91,4.33,2.68,5.39,5.02,1.06,2.33,1.15,4.94.26,7.34Z"
            />
            <path
              className="cls-1"
              d="M141.66,90.36c-1.56-6.27-5.98-11.43-11.9-13.94l-.79-.29c-.71-.26-1.46-.49-2.3-.71l-1.5-.39V3.47c0-1.91-1.56-3.47-3.47-3.47s-3.47,1.56-3.47,3.47v71.57l-1.52.38c-1.19.3-2.36.71-3.49,1.22-4.99,2.25-8.81,6.31-10.75,11.44-1.94,5.12-1.77,10.7.49,15.69,2.6,5.77,7.61,9.95,13.75,11.47l1.52.38v28.91c0,1.91,1.56,3.47,3.47,3.47s3.47-1.56,3.47-3.47v-28.91l1.52-.38c5.32-1.32,9.81-4.64,12.64-9.34s3.66-10.22,2.33-15.54ZM130.73,98.72c-.89,2.38-2.74,4.35-5.06,5.39-1.27.58-2.61.87-3.98.87-3.77,0-7.21-2.22-8.77-5.65-1.06-2.34-1.15-4.96-.25-7.36s2.69-4.32,5.03-5.38c1.26-.57,2.59-.86,3.97-.86,1.19,0,2.32.21,3.4.63,4.98,1.86,7.5,7.4,5.65,12.36Z"
            />
            <path
              className="cls-2"
              d="M89.8,35.28l-.03-.1c-2.64-5.63-7.61-9.7-13.66-11.2l-1.52-.38V3.47c0-1.91-1.56-3.47-3.47-3.47s-3.47,1.56-3.47,3.47v20.14l-1.52.37c-1.19.29-2.37.7-3.48,1.19-10.33,4.68-14.92,16.89-10.23,27.22,2.26,4.99,6.33,8.8,11.47,10.74.73.28,1.49.53,2.26.72l1.51.38v80.29c0,1.91,1.56,3.47,3.47,3.47s3.47-1.56,3.47-3.47v-80.3l1.52-.38c1.2-.3,2.38-.7,3.49-1.19,10.32-4.68,14.93-16.89,10.27-27.22l-.07-.16ZM80.2,47.21c-1.42,3.72-5.05,6.22-9.04,6.22-1.15,0-2.29-.21-3.38-.61-2.39-.92-4.29-2.72-5.34-5.08s-1.12-4.98-.2-7.38c.91-2.37,2.68-4.26,4.99-5.32,1.22-.54,2.54-.82,3.91-.83,5.33,0,9.64,4.3,9.67,9.58,0,1.17-.2,2.32-.61,3.42Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export const LeaveIco = ({ style, color }) => (
  <div style={style}>
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 159.99 152"
      fill={color}
    >
      <defs></defs>
      <g id="finish">
        <g id="Layer_10" data-name="Layer 10">
          <g>
            <path
              className="cls-1"
              d="M36.95,35.95h4.19c3.8,0,6.99-3,6.99-6.99V6.99c0-3.8-3-6.99-6.99-6.99h-4.19c-3.8,0-6.99,3-6.99,6.99v22.17c0,3.8,3,6.79,6.99,6.79ZM37.95,7.99h2v19.97h-2V7.99Z"
            />
            <path
              className="cls-1"
              d="M118.84,35.95h4.19c3.8,0,6.99-3,6.99-6.99V6.99c0-3.8-3-6.99-6.99-6.99h-4.19c-3.8,0-6.99,3-6.99,6.99v22.17c0,3.8,3,6.79,6.99,6.79ZM119.84,7.99h2v19.97h-2V7.99Z"
            />
            <path
              className="cls-1"
              d="M55.93,21.97h47.94c2.2,0,3.99-1.8,3.99-3.99s-1.8-3.99-3.99-3.99h-47.94c-2.2,0-3.99,1.8-3.99,3.99s1.8,3.99,3.99,3.99Z"
            />
            <path
              className="cls-1"
              d="M143.21,13.98h-5.39c-2.2,0-3.99,1.8-3.99,3.99s1.8,3.99,3.99,3.99h5.39c4.79,0,8.59,3.8,8.59,8.59v104.86c0,4.79-3.8,8.59-8.59,8.59H16.58c-4.79,0-8.59-3.8-8.59-8.59V30.56c0-4.79,3.8-8.59,8.59-8.59h5.39c2.2,0,3.99-1.8,3.99-3.99s-1.8-3.99-3.99-3.99h-5.39C7.39,13.98,0,21.37,0,30.56v104.86c0,9.19,7.39,16.58,16.58,16.58h126.83c9.19,0,16.58-7.39,16.58-16.58V30.56c-.2-9.19-7.59-16.58-16.78-16.58Z"
            />
            <path
              className="cls-2"
              d="M67.43,118.68c0-7.03,4.91-12.06,11.93-12.06s11.67,5.04,11.8,12.06c0,6.89-4.64,12.06-11.8,12.06s-11.93-5.17-11.93-12.06ZM71.81,99.99l-3.05-60.05h21.08l-2.92,60.05h-15.11Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export const EmployeeIco = ({ style, color }) => (
  <div style={style}>
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 117.31 152.49"
      fill={color}
    >
      <defs></defs>
      <g id="finish">
        <g id="Layer_10" data-name="Layer 10">
          <g>
            <path
              className="cls-2"
              d="M105.1,86.66c-7.79-5.8-16.69-9.93-26.14-12.12,8.07-8.34,12.56-19.5,12.52-31.11h0v-10.11C91.48,15.2,76.79.5,58.66.5S25.84,15.2,25.84,33.32v10.1h0c-.04,11.61,4.46,22.77,12.52,31.12-9.46,2.19-18.36,6.32-26.14,12.12C-1.3,97,.59,149.47.59,149.47c0,1.39,1.13,2.52,2.52,2.52h111.1c1.39,0,2.52-1.13,2.52-2.52h0s1.74-52.6-11.63-62.81ZM73.91,78.76c1.85.22,3.68.58,5.48,1.08l-8.44,12.6-7.45-9.27c3.73-.76,7.27-2.26,10.4-4.42h0ZM53.36,146.94l3.81-45.47c.89.65,2.09.65,2.98,0l3.81,45.47h-10.61ZM58.66,96.28l-5.53-4.18,5.53-6.89,5.53,6.89-5.53,4.18ZM30.89,33.32c0-15.34,12.43-27.77,27.77-27.77s27.77,12.43,27.77,27.77v7.2c-2.47-.74-4.48-2.54-5.49-4.91l-2.16-5.03c-1.91-4.46-6.3-7.36-11.15-7.36h-17.62c-4.86,0-9.25,2.89-11.15,7.36l-2.16,5.03c-1.17,2.36-3.28,4.12-5.81,4.86v-7.15ZM30.99,45.64c4.52-.91,8.35-3.89,10.35-8.04l2.16-5.03c1.12-2.61,3.68-4.3,6.51-4.3h17.62c2.84,0,5.4,1.69,6.51,4.3l2.16,5.03c1.8,4.19,5.56,7.21,10.03,8.06-1.03,17.54-13.74,32.99-27.67,32.99-13.94,0-26.66-15.47-27.67-33.02ZM53.83,83.17l-7.45,9.27-8.44-12.6c1.8-.5,3.63-.86,5.48-1.08h0c3.14,2.15,6.67,3.66,10.4,4.42ZM15.47,90.52c5.26-4,11.15-7.08,17.43-9.13l11.2,16.72c.77,1.16,2.34,1.47,3.5.7.22-.14.41-.32.57-.52l1.8-2.25,2.44,1.84-4.12,49.06H5.64c.02-14.16.51-48.57,9.83-56.42ZM69.03,146.94l-4.12-49.06,2.44-1.84,1.8,2.25c.85,1.09,2.43,1.28,3.51.43.22-.17.4-.37.55-.6l11.2-16.72c6.28,2.04,12.17,5.13,17.43,9.12,9.32,7.85,9.81,42.26,9.83,56.42h-42.65Z"
            />
            <path
              className="cls-1"
              d="M33.41,104.02h-12.62c-1.39,0-2.52,1.13-2.52,2.52s1.13,2.52,2.52,2.52h12.62c1.39,0,2.52-1.13,2.52-2.52s-1.13-2.52-2.52-2.52Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export const ErrorExclamation = ({ style, color }) => (
  <div style={style}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill={color}
    >
      <path
        d="M30.2213 15.1106C30.2213 23.4579 23.4554 30.2213 15.1106 30.2213C6.76585 30.2213 0 23.4579 0 15.1106C0 6.76828 6.76585 0 15.1106 0C23.4554 0 30.2213 6.76828 30.2213 15.1106ZM15.1106 18.1571C13.5627 18.1571 12.3079 19.412 12.3079 20.9599C12.3079 22.5078 13.5627 23.7627 15.1106 23.7627C16.6586 23.7627 17.9134 22.5078 17.9134 20.9599C17.9134 19.412 16.6586 18.1571 15.1106 18.1571ZM12.4496 8.08261L12.9016 16.3691C12.9228 16.7568 13.2434 17.0604 13.6317 17.0604H16.5896C16.9779 17.0604 17.2985 16.7568 17.3197 16.3691L17.7716 8.08261C17.7945 7.66377 17.461 7.3116 17.0416 7.3116H13.1796C12.7602 7.3116 12.4268 7.66377 12.4496 8.08261Z"
        fill="#E53434"
      />
    </svg>
  </div>
);

export const DriveIco = ({ style, color }) => (
  <div style={style}>
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 176 139.24"
      fill={color}
    >
      <defs></defs>
      <g id="finish">
        <g id="Layer_10" data-name="Layer 10">
          <g>
            <path
              className="cls-2"
              d="M101.27,107.54v9.97c0,1.63-1.31,2.95-2.94,2.95-1.63,0-2.95-1.31-2.95-2.94,0,0,0,0,0-.01v-9.97c0-1.63,1.32-2.94,2.95-2.94,1.62,0,2.93,1.32,2.94,2.94Z"
            />
            <path
              className="cls-2"
              d="M98.33,124.53c-1.63,0-2.94,1.32-2.94,2.94v8.83c0,1.63,1.32,2.94,2.95,2.94,1.62,0,2.93-1.32,2.94-2.94v-8.83c0-1.63-1.32-2.94-2.94-2.94Z"
            />
            <path
              className="cls-2"
              d="M132.85,80.23l-23.29-23.13c-1.15-1.14-3-1.14-4.15,0l-23.3,23.13c-1.15,1.15-1.16,3.01-.01,4.16.55.56,1.3.87,2.09.87h11.19v12.31c0,1.63,1.31,2.95,2.94,2.95,1.63,0,2.95-1.31,2.95-2.94,0,0,0,0,0-.01v-15.26c0-1.63-1.32-2.94-2.94-2.94h-7l16.15-16.04,16.15,16.04h-7c-1.63,0-2.94,1.32-2.94,2.94v15.26c0,1.63,1.32,2.94,2.94,2.94s2.94-1.32,2.94-2.94v-12.31h11.19c1.63,0,2.94-1.32,2.94-2.94,0-.78-.31-1.53-.87-2.09Z"
            />
            <path
              className="cls-2"
              d="M116.64,124.53c-1.63,0-2.94,1.32-2.94,2.94v8.83c0,1.63,1.32,2.94,2.94,2.94s2.94-1.32,2.94-2.94v-8.83c0-1.63-1.32-2.94-2.94-2.94Z"
            />
            <path
              className="cls-2"
              d="M116.64,104.6c-1.63,0-2.94,1.32-2.94,2.94v9.97c0,1.63,1.32,2.94,2.94,2.94s2.94-1.32,2.94-2.94v-9.97c0-1.63-1.32-2.94-2.94-2.94Z"
            />
            <path
              className="cls-1"
              d="M141,27.07c-4.45,0-8.85.85-12.98,2.51C121.22,7.47,97.78-4.94,75.67,1.86c-13.25,4.08-23.63,14.46-27.71,27.71-17.93-7.17-38.28,1.56-45.45,19.49-7.17,17.93,1.56,38.28,19.49,45.45,4.13,1.65,8.54,2.5,12.98,2.5h2.62c1.63,0,2.94-1.32,2.94-2.94s-1.32-2.94-2.94-2.94h-2.62c-16.06-.01-29.08-13.04-29.07-29.11.01-16.05,13.02-29.06,29.07-29.07,4.73-.01,9.39,1.15,13.56,3.37,1.44.76,3.22.21,3.98-1.23.13-.24.22-.5.28-.76,4.18-19.44,23.32-31.81,42.76-27.63,13.84,2.97,24.66,13.79,27.63,27.63.34,1.59,1.9,2.6,3.49,2.26.27-.06.52-.15.76-.28,4.17-2.22,8.83-3.38,13.56-3.37,16.06-.01,29.1,13,29.11,29.07.01,16.06-13,29.1-29.07,29.11-.01,0-.03,0-.04,0h-4.44c-1.63,0-2.94,1.32-2.94,2.94s1.32,2.94,2.94,2.94h4.44c19.31.01,34.98-15.63,35-34.95.01-19.31-15.63-34.98-34.95-35-.02,0-.03,0-.05,0h0Z"
            />
            <path
              className="cls-1"
              d="M90.93,13.24c0-1.63-1.32-2.94-2.94-2.94-13.08.01-24.74,8.25-29.13,20.58-.54,1.53.26,3.21,1.79,3.76,0,0,0,0,0,0,.32.11.65.17.98.17,1.25,0,2.36-.79,2.77-1.96,3.55-9.98,12.99-16.64,23.58-16.66,1.63,0,2.94-1.32,2.94-2.94Z"
            />
            <path
              className="cls-1"
              d="M70.05,75.04c-1.63,0-2.94,1.32-2.94,2.94v9.97c0,1.63,1.32,2.94,2.94,2.94,1.62,0,2.94-1.32,2.94-2.94v-9.97c0-1.63-1.32-2.94-2.94-2.94Z"
            />
            <path
              className="cls-1"
              d="M73,59.19c0-1.63-1.32-2.94-2.94-2.94-1.62,0-2.94,1.32-2.94,2.94v8.83c0,1.63,1.32,2.94,2.94,2.94,1.62,0,2.94-1.32,2.94-2.94v-8.83Z"
            />
            <path
              className="cls-1"
              d="M84.19,110.22h-11.19v-12.31c0-1.63-1.32-2.94-2.94-2.94-1.62,0-2.94,1.32-2.94,2.94v15.25c0,1.63,1.32,2.94,2.94,2.94h7l-16.15,16.04-16.15-16.04h7c1.63,0,2.94-1.32,2.94-2.94v-15.25c0-1.63-1.32-2.94-2.95-2.94-1.62,0-2.93,1.32-2.94,2.94v12.31h-11.19c-1.63,0-2.94,1.32-2.94,2.94,0,.78.31,1.53.87,2.09l23.29,23.13c1.15,1.14,3,1.14,4.15,0l23.3-23.13c1.15-1.15,1.16-3.01.01-4.16-.55-.56-1.3-.87-2.09-.87Z"
            />
            <path
              className="cls-1"
              d="M51.74,70.96c1.63,0,2.94-1.32,2.94-2.94v-8.83c0-1.63-1.32-2.94-2.95-2.94-1.62,0-2.93,1.32-2.94,2.94v8.83c0,1.63,1.32,2.94,2.94,2.94Z"
            />
            <path
              className="cls-1"
              d="M48.79,87.95c0,1.63,1.32,2.94,2.95,2.94,1.62,0,2.93-1.32,2.94-2.94v-9.97c0-1.63-1.31-2.95-2.94-2.95-1.63,0-2.95,1.31-2.95,2.94,0,0,0,0,0,.01v9.97Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export const DashboardIco = ({ style, color }) => (
  <div style={style}>
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 152 152"
      fill={color}
    >
      <defs></defs>
      <g id="finish">
        <g id="Layer_10" data-name="Layer 10">
          <g>
            <path
              className="cls-2"
              d="M15.2,0C6.81,0,0,6.81,0,15.2v55.73c0,8.39,6.81,15.2,15.2,15.2h40.53c8.39,0,15.2-6.81,15.2-15.2V15.2c0-8.39-6.81-15.2-15.2-15.2H15.2ZM10.13,15.2c0-2.8,2.27-5.07,5.07-5.07h40.53c2.8,0,5.07,2.27,5.07,5.07v55.73c0,2.8-2.27,5.07-5.07,5.07H15.2c-2.8,0-5.07-2.27-5.07-5.07V15.2Z"
            />
            <path
              className="cls-1"
              d="M96.27,152c-8.39,0-15.2-6.81-15.2-15.2v-55.73c0-8.39,6.81-15.2,15.2-15.2h40.53c8.39,0,15.2,6.81,15.2,15.2v55.73c0,8.39-6.81,15.2-15.2,15.2h-40.53ZM91.2,136.8c0,2.8,2.27,5.07,5.07,5.07h40.53c2.8,0,5.07-2.27,5.07-5.07v-55.73c0-2.8-2.27-5.07-5.07-5.07h-40.53c-2.8,0-5.07,2.27-5.07,5.07v55.73Z"
            />
            <path
              className="cls-2"
              d="M0,111.47c0-8.39,6.81-15.2,15.2-15.2h40.53c8.39,0,15.2,6.81,15.2,15.2v25.33c0,8.39-6.81,15.2-15.2,15.2H15.2c-8.39,0-15.2-6.81-15.2-15.2v-25.33ZM15.2,106.4c-2.8,0-5.07,2.27-5.07,5.07v25.33c0,2.8,2.27,5.07,5.07,5.07h40.53c2.8,0,5.07-2.27,5.07-5.07v-25.33c0-2.8-2.27-5.07-5.07-5.07H15.2Z"
            />
            <path
              className="cls-2"
              d="M96.27,55.73c-8.39,0-15.2-6.81-15.2-15.2V15.2c0-8.39,6.81-15.2,15.2-15.2h40.53c8.39,0,15.2,6.81,15.2,15.2v25.33c0,8.39-6.81,15.2-15.2,15.2h-40.53ZM91.2,40.53c0,2.8,2.27,5.07,5.07,5.07h40.53c2.8,0,5.07-2.27,5.07-5.07V15.2c0-2.8-2.27-5.07-5.07-5.07h-40.53c-2.8,0-5.07,2.27-5.07,5.07v25.33Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export const CalendarIcon = ({ style, color }) => (
  <div style={style}>
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 143.26 152"
      fill={color}
    >
      <defs></defs>
      <g id="finish">
        <g id="Layer_10" data-name="Layer 10">
          <g>
            <path
              className="cls-1"
              d="M128.44,12.35h-11.21c-1.9,0-3.61,1.52-3.61,3.61s1.52,3.61,3.61,3.61h11.21c4.18,0,7.6,3.42,7.6,7.6v13.87H7.22v-13.87c0-4.18,3.42-7.6,7.6-7.6h11.21c1.9,0,3.61-1.52,3.61-3.61s-1.52-3.61-3.61-3.61h-11.21C6.65,12.35,0,19,0,27.17v110.01c0,8.17,6.65,14.82,14.82,14.82h113.62c8.17,0,14.82-6.65,14.82-14.82V27.17c0-8.17-6.65-14.82-14.82-14.82ZM136.04,137.18c0,4.18-3.42,7.6-7.6,7.6H14.82c-4.18,0-7.6-3.42-7.6-7.6V48.26h128.82v88.92Z"
            />
            <path
              className="cls-1"
              d="M45.6,19.57h52.06c1.9,0,3.61-1.52,3.61-3.61s-1.52-3.61-3.61-3.61h-52.06c-1.9,0-3.61,1.52-3.61,3.61s1.71,3.61,3.61,3.61Z"
            />
            <path
              className="cls-1"
              d="M35.91,33.63c3.8,0,7.03-3.04,7.03-7.03,0-2.47-1.33-4.75-3.42-5.89V3.61c0-1.9-1.52-3.61-3.61-3.61s-3.61,1.52-3.61,3.61v17.1c-2.09,1.14-3.42,3.42-3.42,5.89,0,3.99,3.04,7.03,7.03,7.03Z"
            />
            <path
              className="cls-1"
              d="M107.35,33.63c3.8,0,7.03-3.04,7.03-7.03,0-2.47-1.33-4.75-3.42-5.89V3.61c0-1.9-1.52-3.61-3.61-3.61s-3.42,1.52-3.42,3.61v17.1c-2.09,1.14-3.42,3.42-3.42,5.89,0,3.99,3.04,7.03,6.84,7.03Z"
            />
            <path
              className="cls-1"
              d="M26.41,91.96h19.19c1.9,0,3.61-1.52,3.61-3.61v-19.19c0-1.9-1.52-3.61-3.61-3.61h-19.19c-1.9,0-3.61,1.52-3.61,3.61v19.19c0,1.9,1.71,3.61,3.61,3.61ZM30.02,72.77h12.16v12.16h-12.16v-12.16Z"
            />
            <path
              className="cls-2"
              d="M61.94,91.96h19.19c1.9,0,3.61-1.52,3.61-3.61v-19.19c0-1.9-1.52-3.61-3.61-3.61h-19.19c-1.9,0-3.61,1.52-3.61,3.61v19.19c.19,1.9,1.71,3.61,3.61,3.61ZM65.55,72.77h12.16v12.16h-12.16v-12.16Z"
            />
            <path
              className="cls-1"
              d="M97.66,91.96h19.19c1.9,0,3.61-1.52,3.61-3.61v-19.19c0-1.9-1.52-3.61-3.61-3.61h-19.19c-1.9,0-3.61,1.52-3.61,3.61v19.19c0,1.9,1.52,3.61,3.61,3.61ZM101.08,72.77h12.16v12.16h-12.16v-12.16Z"
            />
            <path
              className="cls-1"
              d="M26.41,127.11h19.19c1.9,0,3.61-1.52,3.61-3.61v-19.19c0-1.9-1.52-3.61-3.61-3.61h-19.19c-1.9,0-3.61,1.52-3.61,3.61v19.19c0,1.9,1.71,3.61,3.61,3.61ZM30.02,107.73h12.16v12.16h-12.16v-12.16Z"
            />
            <path
              className="cls-2"
              d="M61.94,127.11h19.19c1.9,0,3.61-1.52,3.61-3.61v-19.19c0-1.9-1.52-3.61-3.61-3.61h-19.19c-1.9,0-3.61,1.52-3.61,3.61v19.19c.19,1.9,1.71,3.61,3.61,3.61ZM65.55,107.73h12.16v12.16h-12.16v-12.16Z"
            />
            <path
              className="cls-1"
              d="M97.66,127.11h19.19c1.9,0,3.61-1.52,3.61-3.61v-19.19c0-1.9-1.52-3.61-3.61-3.61h-19.19c-1.9,0-3.61,1.52-3.61,3.61v19.19c0,1.9,1.52,3.61,3.61,3.61ZM101.08,107.73h12.16v12.16h-12.16v-12.16Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export const BUIcon = ({ style, color }) => (
  <div style={style}>
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 168.19 152.35"
      fill={color}
    >
      <defs></defs>
      <g id="finish">
        <g id="Layer_10" data-name="Layer 10">
          <g>
            <path
              className="cls-1"
              d="M163.9,143.77h-6.54v-68.97c0-5.01-4.07-9.07-9.08-9.08h-24.03V9.08c0-5.01-4.07-9.07-9.08-9.08h-62.09c-5.01,0-9.07,4.07-9.08,9.08v56.63h-24.03c-5.01,0-9.07,4.07-9.08,9.08v68.97h-6.61c-2.37,0-4.29,1.92-4.29,4.29s1.92,4.29,4.29,4.29h159.61c2.37,0,4.29-1.92,4.29-4.29s-1.92-4.29-4.29-4.29ZM148.26,74.29c.27,0,.5.22.5.5h0v68.97h-24.58v-69.47h24.08ZM52.57,9.08c0-.27.22-.5.5-.5h62.04c.27,0,.5.22.5.5h0v134.69h-63.02l-.02-134.69ZM19.41,74.79c0-.27.22-.5.5-.5h24.03v69.47h-24.52v-68.97Z"
            />
            <path
              className="cls-2"
              d="M66.93,131.2h34.32c2.37,0,4.29-1.92,4.29-4.29s-1.92-4.29-4.29-4.29h-34.32c-2.37,0-4.29,1.92-4.29,4.29s1.92,4.29,4.29,4.29Z"
            />
            <path
              className="cls-2"
              d="M66.93,110.78h34.32c2.37,0,4.29-1.92,4.29-4.29s-1.92-4.29-4.29-4.29h-34.32c-2.37,0-4.29,1.92-4.29,4.29s1.92,4.29,4.29,4.29Z"
            />
            <path
              className="cls-2"
              d="M66.93,90.36h34.32c2.37,0,4.29-1.92,4.29-4.29s-1.92-4.29-4.29-4.29h-34.32c-2.37,0-4.29,1.92-4.29,4.29s1.92,4.29,4.29,4.29Z"
            />
            <path
              className="cls-2"
              d="M66.93,69.95h34.32c2.37,0,4.29-1.92,4.29-4.29s-1.92-4.29-4.29-4.29h-34.32c-2.37,0-4.29,1.92-4.29,4.29s1.92,4.29,4.29,4.29Z"
            />
            <path
              className="cls-2"
              d="M66.93,49.53h34.32c2.37,0,4.29-1.92,4.29-4.29s-1.92-4.29-4.29-4.29h-34.32c-2.37,0-4.29,1.92-4.29,4.29s1.92,4.29,4.29,4.29Z"
            />
            <path
              className="cls-2"
              d="M66.93,29.11h34.32c2.37,0,4.29-1.92,4.29-4.29s-1.92-4.29-4.29-4.29h-34.32c-2.37,0-4.29,1.92-4.29,4.29s1.92,4.29,4.29,4.29Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);
