import React from "react";
import "./AllocatedLeaves.css";

const AllocatedLeaves = ({ allocatedLeavesList }) => {
  return (
    <div>
      {allocatedLeavesList?.map((leaves) => {
        return (
          <div
            className="allocatedleaves_single-leave"
            key={leaves?.leaveTypeId}
          >
            <div className="single-leave_header .d-flex-center">
              <h1 className="fz-10 pb-5 ">{leaves?.leaveTypeName}</h1>
            </div>
            <div className="single-leave_details">
              <p className="single-leave_taken fz-8">
                Taken : {leaves?.usedDays}
              </p>
              <p className="single-leave_available fz-8">
                Available : {leaves?.remainingDays}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AllocatedLeaves;
