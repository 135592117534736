import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

function FirstLoginTable({
  tableHeaderList,
  sortable = [],
  tableDataList,
  filterList = null,
}) {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    setPage(0);
  }, [tableDataList]);

  let filterItemHeader = [];
  let loopStatus = 0;
  const filteredData = tableDataList?.map((dataItem) => {
    let filteredItem = {};
    for (const key in dataItem) {
      if (key in filterList) {
        filteredItem[key] = dataItem[key];
        if (loopStatus === 0) {
          filterItemHeader.push(key);
        }
      }
    }
    loopStatus = 1;
    return filteredItem;
  });

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = orderBy
    ? filteredData.sort((a, b) => {
        if (order === "asc") {
          return a[orderBy] < b[orderBy] ? 1 : -1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      })
    : filteredData;

  return (
    <>
      <TableContainer
        sx={{
          marginTop: "1rem",
          boxShadow: "none",
          backgroundColor: "transparent",
          maxHeight: "65vh",
        }}
        className="table_container"
        component={Paper}
      >
        <Table stickyHeader aria-label="sticky table" className="table">
          <TableHead>
            <TableRow>
              {tableHeaderList.map((header) => (
                <TableCell key={header}>
                  {sortable.includes(header) ? (
                    <TableSortLabel
                      active={orderBy === header}
                      direction={orderBy === header ? order : "asc"}
                      onClick={() => handleRequestSort(header)}
                      sx={{
                        "& .MuiTableSortLabel-icon": {
                          opacity: 1, 
                        },
                      }}
                    >
                      {header}
                    </TableSortLabel>
                  ) : (
                    header
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((obj, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                  <TableCell sx={{ minWidth: "250px" }}>
                    {obj.employeeName}
                  </TableCell>
                  <TableCell>{obj.employeeId}</TableCell>
                  <TableCell>{obj.time.substring(0, 8)}</TableCell>
                  <TableCell>{obj.earlyBy.substring(0, 8)}</TableCell>
                  <TableCell>{obj.lateBy.substring(0, 8)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          overflow: "hidden",
        }}
        component="div"
        count={sortedData.length}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </>
  );
}

export default FirstLoginTable;
