import { useContext } from "react";
import { UserDataContext } from "../context/UserContext";
import { AUTH_API } from "../constants/constants";
import { useNavigate } from "react-router-dom";
import axios from "../axios/axios";

function useTerminateSession() {
  const navigate = useNavigate();
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const { resetUserData } = useContext(UserDataContext);

  const handleLogout = () => {
    axios
      .post(
        AUTH_API.USER_LOGOUT,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then(() => {
        resetUserData();
      })
      .catch((error) => {
        console.error("Error", error);
      });

    localStorage.removeItem("logInToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userData");
    navigate("/login");
  };

  return handleLogout;
}

export default useTerminateSession;
