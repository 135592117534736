import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EMPLOYEE_API } from "../../../constants/constants";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Skeleton,
} from "@mui/material";
import Search from "../../global/Search/Search";
import Title from "../../global/Title/Title";
import axios from "../../../axios/axios";

function CompenstationLeaveList() {
  const [compLeaves, setCompLeaves] = useState([]);
  const [filteredLeaves, setFilteredLeaves] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const collapsed = useSelector((state) => state.sidebar.collapsed);

  useEffect(() => {
    axios
      .post(
        EMPLOYEE_API.LIST_COMPENSATION_LEAVES,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setCompLeaves(response.data.data);
        setFilteredLeaves(response.data.data); // Initialize filteredLeaves with the data
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("error", error);
      });
  }, [logInToken, userId]);

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchText(searchTerm);

    // Filter the leaves based on the search text
    const filteredLeaves = compLeaves.filter((leaf) => {
      const employeeNameMatch = leaf.employeeName
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());
      const employeeIdMatch = leaf.employeeId
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());
      const noOfDaysMatch = leaf.noOfDays
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());

      return employeeNameMatch || employeeIdMatch || noOfDaysMatch;
    });

    setFilteredLeaves(filteredLeaves);
  };

  return (
    <>
      <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
        <div
          className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
        />

        <div
          className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
        >
          <div className="BU-page">
            <Title text="Comp-off leave list" propClass="pb-10" />

            <div className="table-area">
              <Search SearchStyle={{width: "35%"}} searchValue={searchText} handleSearch={handleSearch} />
              <TableContainer
                sx={{
                  marginTop: "2rem",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  maxHeight: "60vh",
                }}
                className="table_container"
                component={Paper}
              >
                <Table stickyHeader aria-label="sticky table" className="table">
                  <TableHead>
                    <TableRow>
                      <TableCell>SN</TableCell>
                      <TableCell>Employee Name</TableCell>
                      <TableCell>Employee Id</TableCell>
                      <TableCell>No of Days</TableCell>
                      <TableCell>Reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredLeaves
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((leaf, index) => (
                          <TableRow key={leaf?.id}>
                            <TableCell>
                              {index + 1 + page * rowsPerPage}
                            </TableCell>
                            <TableCell>{leaf?.employeeName}</TableCell>
                            <TableCell>{leaf?.employeeId}</TableCell>
                            <TableCell>{leaf?.noOfDays}</TableCell>
                            <TableCell>{leaf?.reason}</TableCell>
                          </TableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                sx={{
                  overflow: "hidden",
                }}
                component="div"
                count={filteredLeaves.length}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompenstationLeaveList;
