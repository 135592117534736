import React from "react";

function TodayLogin() {
  return (
    <svg
      width="30"
      height="27"
      viewBox="0 0 47 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.3112 28.1804C35.3097 28.0846 35.299 27.9891 35.2792 27.8952C35.2792 27.8456 35.2792 27.7961 35.2344 27.7403C35.1896 27.6845 35.2344 27.6535 35.1896 27.6101C35.1615 27.5483 35.1294 27.4883 35.0936 27.4304C35.0936 27.4304 35.0936 27.3684 35.0424 27.3436C34.9832 27.2562 34.9146 27.1751 34.8376 27.1018L24.0529 16.6515C23.9042 16.5072 23.7277 16.3927 23.5333 16.3146C23.3389 16.2364 23.1305 16.1962 22.92 16.1962C22.7095 16.1962 22.5011 16.2364 22.3067 16.3146C22.1123 16.3927 21.9357 16.5072 21.7871 16.6515C21.6381 16.7954 21.5199 16.9664 21.4392 17.1547C21.3586 17.3429 21.317 17.5448 21.317 17.7486C21.317 17.9524 21.3586 18.1542 21.4392 18.3425C21.5199 18.5307 21.6381 18.7017 21.7871 18.8457L29.8453 26.6494H1.60011C1.17573 26.6494 0.768744 26.8126 0.468665 27.1032C0.168586 27.3938 0 27.788 0 28.1989C0 28.6099 0.168586 29.0041 0.468665 29.2947C0.768744 29.5853 1.17573 29.7485 1.60011 29.7485H29.8837L21.8191 37.5522C21.6701 37.6962 21.5519 37.8672 21.4712 38.0554C21.3905 38.2437 21.349 38.4455 21.349 38.6493C21.349 38.8531 21.3905 39.055 21.4712 39.2432C21.5519 39.4315 21.6701 39.6025 21.8191 39.7464C21.9678 39.8905 22.1444 40.0047 22.3388 40.0823C22.5333 40.16 22.7417 40.1996 22.952 40.1989C23.1623 40.1996 23.3707 40.16 23.5651 40.0823C23.7596 40.0047 23.9362 39.8905 24.0849 39.7464L34.876 29.2961V29.2651C34.9404 29.2026 34.9983 29.134 35.0488 29.0605C35.0769 29.0164 35.1025 28.9709 35.1256 28.9242C35.1256 28.8808 35.1768 28.8374 35.196 28.794C35.2152 28.7506 35.2344 28.6762 35.2472 28.6204C35.2638 28.5824 35.2767 28.543 35.2856 28.5027C35.3061 28.4026 35.3168 28.3009 35.3176 28.1989L35.3112 28.1804Z"
        fill="#47A7FF"
      />
      <path
        d="M13.1593 33.7774V42.0831C13.1643 45.7804 14.6832 49.3248 17.3828 51.9392C20.0824 54.5535 23.7424 56.0244 27.5603 56.0293H31.9766C35.7944 56.0244 39.4544 54.5535 42.154 51.9392C44.8536 49.3248 46.3725 45.7804 46.3776 42.0831V14.3146C46.3725 10.6174 44.8536 7.07296 42.154 4.4586C39.4544 1.84424 35.7944 0.373328 31.9766 0.368408H27.5603C23.7424 0.373328 20.0824 1.84424 17.3828 4.4586C14.6832 7.07296 13.1643 10.6174 13.1593 14.3146V22.1059C13.1593 22.5169 13.3279 22.911 13.6279 23.2016C13.928 23.4922 14.335 23.6555 14.7594 23.6555C15.1838 23.6555 15.5907 23.4922 15.8908 23.2016C16.1909 22.911 16.3595 22.5169 16.3595 22.1059V14.3146C16.3629 11.4388 17.544 8.68173 19.6439 6.64822C21.7437 4.61472 24.5907 3.47085 27.5603 3.46757H31.9766C34.9462 3.47085 37.7932 4.61472 39.893 6.64822C41.9928 8.68173 43.174 11.4388 43.1774 14.3146V42.0831C43.174 44.9589 41.9928 47.716 39.893 49.7495C37.7932 51.783 34.9462 52.9269 31.9766 52.9302H27.5603C24.5907 52.9269 21.7437 51.783 19.6439 49.7495C17.544 47.716 16.3629 44.9589 16.3595 42.0831V33.7774C16.3595 33.3664 16.1909 32.9723 15.8908 32.6816C15.5907 32.391 15.1838 32.2278 14.7594 32.2278C14.335 32.2278 13.928 32.391 13.6279 32.6816C13.3279 32.9723 13.1593 33.3664 13.1593 33.7774Z"
        fill="#47A7FF"
      />
    </svg>
  );
}

export default TodayLogin;
