// API BASE URL key
export const API_BASE_URL = "https://api-strathrms.strat-staging.com";
// export const API_BASE_URL = "http://localhost:8080/";

// APIs END POINTS

export const DASHBOARD_API = {
  FETCH_DASHBOARD_DATA: "dashboard/get_dashboard_data",
  FETCH_ON_LEAVE_TODAY: "dashboard/get_whose_on_leave",
  FETCH_ON_LEAVE_PENDING: "dashboard/get_whose_on_leave_pending",
  FETCH_UPCOMING_HOLIDAY: "dashboard/get_dashboard_holidays",
  FETCH_UPCOMING_WORKING_DAY: "dashboard/get_dashborad_special_working_days",
};

export const BU_API = {
  CREATE_BU: "/businessunit/create_bu",
  LIST_BU: "/businessunit",
  LIST_BU_BY_ID: "/businessunit/bu_details/",
  EDIT_BU: "/businessunit/edit_bu/",
  DELETE_BU: "/businessunit/delete_bu",
};

export const EMPLOYEE_API = {
  LIST_DESIGNATIONS: "/employee/get_designation_list",
  LIST_DEPARTMENTS: "/employee/get_departments_list",
  LIST_REPORTING: "/employee/get_employee_list",
  ADD_EMPLOYEE: "/employee/add_employee",
  LIST_EMPLOYEES: "/employee",
  LIST_EMPLOYEES_BUID: "/employee/get_employee_list_buId",
  GET_EMPLOYEE_BY_ID: "/employee/employee_details/",
  EDIT_EMPLOYEE: "/employee/edit_employee/",
  DELETE_EMPLOYEE: "employee/delete_employee",
  LIST_ALUMIN: "/employee/alumnus",
  ALLOCATE_LEAVE: "/employee/employee_leave_allocation_data",
  ALLOCATE_LEAVE_SAVE: "employee/leave_allocation_employee_save/",
  COMPENSATE_LEAVE: "/employee/supplemental_leave_allocation",
  LIST_COMPENSATION_LEAVES: "employee/get_comp_leave_list",
};

export const LEAVE_API = {
  APPLY_LEAVE: "leaves/employee_leave_save",
  LIST_APPLIED_LEAVES: "leaves/employee_leave_list/",
  CANCEL_APPLIED_LEAVE: "/leaves/employee_leave_withdraw",
  CREATE_LEAVE_TYPE: "/leaves/create_leave_types",
  LIST_LEAVE_TYPES: "/leaves",
  DELETE_LEAVE_TYPE: "/leaves/delete_leave_types",
  EDIT_LEAVE_TYPE: "/leaves/edit_leave_types/",
  GET_LEAVE_BALANCE: "leaves/employee_leave_allocation_data/",
  FORWARD_LEAVE: "leaves/employee_leave_forward_save",
  REQUEST_LEAVE: "leaves/employee_leave_requests",
  LIST_FORWARD_LEAVES: "leaves/employee_leave_forwarders_list",
  MARK_LEAVE_VIEWED: "leaves/leave_viewed",
  LIST_PENDING_LEAVES: "leaves/employee_pending_leave_requests",
  TAKE_LEAVE_ACTION: "leaves/employee_leave_action",
};

export const PROFILE_API = {
  GET_USER_PROFILE: "employee/employee_profile_data/",
};

export const HOLIDAY_API = {
  ADD_HOLIDAY: "/calendar/add_holidays",
  LIST_HOLIDAYS: "/calendar",
  GET_HOLIDAY_BY_ID: "/calendar/get_holiday_data/",
  EDIT_HOLIDAY: "/calendar/edit_holidays/",
  DELETE_HOLIDAY: "calendar/delete_holiday",
  GET_HOLIDAY_CALENDAR: "calendar/holiday_calendar_data",
};

export const WORKING_DAY_API = {
  ADD_WORKING_DAY: "/calendar/add_special_working_day",
  LIST_WORKING_DAYS: "/calendar/get_special_working_days",
  DELETE_WORKING_DAY: "calendar/delete_special_working_day",
  GET_WORKING_DAY_CALENDER:
    "/calendar/get_special_working_days_in_calendar_format",
};

export const STRAT_DRIVE_API = {
  LIST_FILES: "/stratdrive",
  UPLOAD_FILE: "stratdrive/file_upload",
  DELETE_FILE: "stratdrive/file_delete",
};

export const AUTH_API = {
  USER_LOGIN: "/login/auth",
  USER_LOGOUT: "/login/auth_destroy",
  USER_LOGIN_HISTORY: "login/get_user_login_information",
  FORGOT_PASSWORD: "/login/forget_password",
  VERIFY_OTP: "/login/forget_password_reset_verification",
  CHANGE_PASSWORD: "login/change_password",
  RESET_PASSWORD: "/login/reset_password",
  GET_USER_ROLE_ID: "/login/get_employee_role_id",
};

export const ATTENDANCE = {
  USER_ATTENDANCE: "/attendance/get_user_attendance_log",
  USER_FIRST_LOGIN: "attendance/get_first_login_report",
  ALL_USER_ATTENDANCE: "/attendance/get_employee_attendance_log_by_date",
};
