import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axios from "../../axios/axios";
import "../../assets/css/UpdatePassword.css";
import { AUTH_API } from "../../constants/constants";
import useTerminateSession from "../../hooks/useTerminateSession"

function UpdatePassword() {
  const handleLogout = useTerminateSession()
  const [formData, setFormData] = useState({
    newPassword: "",
    reEnteredPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [statusError, setStatusError] = useState("");
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors("");
  };

  const handlevalidate = () => {
    const validationErrors = { ...errors };

    if (formData.newPassword !== formData.reEnteredPassword) {
      validationErrors.reEnteredPassword = "Passwords do not match";
    } else {
      validationErrors.reEnteredPassword = "";
    }
    setErrors(validationErrors);
  };

  const togglenewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (formData.newPassword?.trim() === "") {
      validationErrors.newPassword = "New password is required";
    } else if (formData.newPassword?.trim().length < 8) {
      validationErrors.newPassword =
        "New password must be at least 8 characters long";
    }

    if (formData.reEnteredPassword?.trim() === "") {
      validationErrors.reEnteredPassword = "Re enter password is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      axios
        .post(AUTH_API.RESET_PASSWORD, formData, {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        })
        .then(() => {
          handleLogout();
        })
        .catch((error) => {
          if (
            error.response ||
            error.response.status === 404 ||
            error.response.status === 401
          ) {
            setStatusError(error.response.data.userMessage);
            console.error("Resource not found:", error.response.data.message);
          } else {
            console.error("An error occurred:", error);
          }
        });
    }
  };


  return (
    <div className="updatePassword-page">
      <div className="updatePassword-container">
        <h2 className="fzr-175 forgotPassword-title">Set New Password</h2>
        <form onSubmit={handleSubmit}>
          <label className="fzr-08 font-bold">
            Enter New Password
            <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
          </label>
          <div className="updatePassword-input">
            <input
              type={newPasswordVisible ? "text" : "password"}
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
              placeholder="Enter new password"
            />
            {newPasswordVisible ? (
              <IconButton onClick={togglenewPasswordVisibility}>
                <AiOutlineEyeInvisible />
              </IconButton>
            ) : (
              <IconButton onClick={togglenewPasswordVisibility}>
                <AiOutlineEye />
              </IconButton>
            )}
          </div>
          {errors.newPassword && (
            <span className="error-message">{errors.newPassword}</span>
          )}

          <label className="fzr-08 font-bold">
            Confirm Password
            <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
          </label>
          <div className="updatePassword-input">
            <input
              type="password"
              name="reEnteredPassword"
              value={formData.reEnteredPassword}
              onChange={handleChange}
              onKeyUp={handlevalidate}
              placeholder="Confirm password"
            />
          </div>
          {errors.reEnteredPassword && (
            <>
              <span className="error-message">{errors.reEnteredPassword}</span>
              <span>{statusError}</span>
            </>
          )}

          <div className="login-button-area">
            <Button className="forgotPassword-button" type="submit">
              Change Password
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdatePassword;
