// authReducer.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employee: null,
  employeeTodelete: null,
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    createEmployeeList: (state, action) => {
      state.employee = action.payload;
    },
    updateEmployeeList: (state, action) => {
      const employeeList = state.employee;
      const updatedLeaves = employeeList.filter(
        (leaf) => leaf.id !== action.payload
      );
      state.employee = updatedLeaves;
    },
    updateEmployeeToDelete: (state, action) => {
      state.employeeTodelete = action.payload;
    },
  },
});

export const {
  createEmployeeList,
  updateEmployeeList,
  updateEmployeeToDelete,
} = employeeSlice.actions;
export default employeeSlice.reducer;
