import React from "react";
import useTimeExtractor from "../../../hooks/useTimeExtractor";
import "../AttendanceReport/AttendanceReport.css";

import TotalTime from "../../../assets/icons/TotalTime";
import TodayLate from "../../../assets/icons/TodayLate";
import TodayLogin from "../../../assets/icons/TodayLogin";
import TodayLogout from "../../../assets/icons/TodayLogout";

function AttendancesHeader({
  AttendanceDatasToday,
  firstAndLastLog,
  loading,
}) {
  const extractTime = useTimeExtractor;
  return (
    <div className="attendances-container">
      {loading ? (
        <div className="attendances-details-box"></div>
      ) : (
        <div className="attendances-details-box">
          <div className="attendance_item_list">
            <>
              <TotalTime />
            </>
            <div className="attendances-item-details">
              <p className="fzr-08">Total Time</p>
              <p className="fzr-09 font-bold">
                {AttendanceDatasToday.totalTime
                  ? AttendanceDatasToday.totalTime
                  : "-- : --"}
              </p>
            </div>
          </div>

          <div className="attendance_item_list">
            <>
              <TodayLate />
            </>
            <div className="attendances-item-details">
              <p className="fzr-08">Late By</p>
              <p className="fzr-09 font-bold">
                {AttendanceDatasToday.lateBy
                  ? AttendanceDatasToday.lateBy
                  : "-- : --"}
              </p>
            </div>
          </div>

          <div className="attendance_item_list">
            <>
              <TodayLogin />
            </>
            <div className="attendances-item-details">
              <p className="fzr-08">First Login</p>
              <p className="fzr-1">
                {firstAndLastLog?.firstLogin
                  ? extractTime(firstAndLastLog?.firstLogin)
                  : "-- : --"}
              </p>
            </div>
          </div>

          <div className="attendance_item_list">
            <>
              <TodayLogout />
            </>
            <div className="attendances-item-details">
              <p className="fzr-08">Last Logout</p>
              <p className="fzr-1">
                {firstAndLastLog?.lastLogout
                  ? extractTime(firstAndLastLog?.lastLogout)
                  : "-- : -- "}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AttendancesHeader;
