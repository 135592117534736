import React from "react";
import Layout from "../Layout/Layout";
import AddLeaveAllocation from "../../components/Employee/CreateEmployee/AddLeaveAllocation";

function AddEmployeeLeaveAllocation() {
  return (
    <Layout>
      <AddLeaveAllocation />
    </Layout>
  );
}

export default AddEmployeeLeaveAllocation;
