import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    collapsedOnDesk: false,
    collapsedOnMob: true,
  },
  reducers: {
    toggleCollapsedOnDesk: (state) => {
      state.collapsedOnDesk = !state.collapsedOnDesk;
    },
    togglecollapsedOnMob: (state) => {
      state.collapsedOnMob = !state.collapsedOnMob;
    },
  },
});

export const { toggleCollapsedOnDesk, togglecollapsedOnMob } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
