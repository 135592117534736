import React from "react";
import Select from "react-select";
import "./react-select.css";

export default function SingleSelect({
  selectOptions,
  selectedValue,
  handleSelection,
  placeholder,
  isMulty,
  Clearable,
}) {
  return (
    <>
      <Select
        name="applyingTo"
        options={selectOptions}
        value={selectedValue}
        onChange={handleSelection}
        isClearable={Clearable}
        className="react-select"
        isMulti={isMulty}
        placeholder={`${placeholder}`}
        styles={{
          control: (provided) => ({
            ...provided,
            width: 300,
            height: 55,
            margin: "5px 0",
          }),
          input: (provided) => ({
            ...provided,
          }),
        }}
      />
    </>
  );
}
