import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../../axios/axios";
import "./LeaveBalances.css";
import Title from "../../global/Title/Title";
import { LEAVE_API } from "../../../constants/constants";
import LoadingIndicator from "../../global/Loading/LoadingIndicator";

function LeaveBalances() {
  const [leave, setLeave] = useState([]);
  const [loading, setloading] = useState(true);
  const collapsed = useSelector((state) => state.sidebar.collapsed);
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const year = new Date().getFullYear();

  useEffect(() => {
    axios
      .post(
        `${LEAVE_API.GET_LEAVE_BALANCE + userId}`,
        { userId, year },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setLeave(response.data.data);
        setloading(false);
      })
      .catch((error) => {
        console.error("error", error);
        setloading(false);
      });
  }, [logInToken, userId, year]);

  return (
    <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
      <div className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`} />

      <div className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}>
        <div className="BU-page">
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Title text="Leave Balances" propClass="pb-5" />

              <div className="table-area">
                <div className="leave-balances-list">
                  {leave?.length === 0 ? (
                    <div className="no-leave-message">
                      looks like the leave scheme is not assigned to you
                    </div>
                  ) : (
                    leave?.map((data, id) => (
                      <div className="leave-balance-card shadow" key={id}>
                        <div className="leave-balance-card-head">
                          <div>{data?.leaveTypeName}</div>
                          <div>Granted : {data?.allocatedDays} </div>
                        </div>
                        <div className="leave-balance-total">
                          <h1>{data?.remainingDays}</h1>
                          <p>Balances</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeaveBalances;
