import React, { useState, useEffect, useContext } from "react";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { MdOutlineDelete } from "react-icons/md";
import { GoDownload } from "react-icons/go";
import { BsEye } from "react-icons/bs";
import { FcFile } from "react-icons/fc";
import axios from "../../axios/axios";
import { UserDataContext } from "../../context/UserContext";
import useFormatDate from "../../hooks/useFormatDate";
import ConfirmationModal from "../../components/global/model/ConfirmationModal";
import { STRAT_DRIVE_API } from "../../constants/constants";
import Title from "../../components/global/Title/Title";
import Layout from "../Layout/Layout";
import "./StratDrive.css";
import LoadingIndicator from "../../components/global/Loading/LoadingIndicator";

function StratDrive() {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [fileError, setFileError] = useState(null);

  const formatDate = useFormatDate;

  const collapsed = useSelector((state) => state.sidebar.collapsed);

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const { userData } = useContext(UserDataContext);
  const employeeRoleId = userData.employeeRoleId;

  useEffect(() => {
    axios
      .post(
        STRAT_DRIVE_API.LIST_FILES,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setDocument(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("error", error);
        setLoading(false);
      });
  }, [logInToken, userId, refreshData]);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = () => {
        setFile(reader.result);
      };

      reader.readAsDataURL(selectedFile);

      setFileError(null);
    }
  };

  const handlesubmit = (e) => {
    e.preventDefault();

    if (!file) {
      setFileError("Please select a file");
      return;
    }

    const formData = new FormData();
    formData.append("file", e.target[0].files[0]);

    axios
      .post(STRAT_DRIVE_API.UPLOAD_FILE, formData, {
        headers: {
          Authorization: `Bearer ${logInToken}`,
          UserId: `${userId}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setRefreshData(!refreshData);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const openConfirmationModal = (fileId) => {
    setIsConfirmationModalOpen(true);
    setItemToDelete(fileId);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirmed = () => {
    handleremove(itemToDelete);
    closeConfirmationModal();
  };

  const handleremove = (fileId) => {
    axios
      .post(
        STRAT_DRIVE_API.DELETE_FILE,
        { fileId },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        const updatedDocument = document.filter((data) => data.id !== fileId);
        setDocument(updatedDocument);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  return (
    <Layout>
      <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
        <div
          className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
        />

        <div
          className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
        >
          <div className="BU-page">
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                {employeeRoleId === "1" ? (
                  <>
                    <Title text="Upload Document" propClass="pb-5" />

                    <div className="document-upload-page-area">
                      <form onSubmit={handlesubmit}>
                        <div className="document-label">
                          <label htmlFor="document">
                            Document <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="document-upload">
                            <input
                              type="file"
                              name="document"
                              onChange={handleChange}
                            />
                            <Button
                              type="submit"
                              className="document-upload-btn"
                            >
                              Upload
                            </Button>
                          </div>
                          {fileError && (
                            <p className="error-message">{fileError}</p>
                          )}
                        </div>
                      </form>
                    </div>
                  </>
                ) : (
                  <Title text="Strat Drive" propClass="pb-5" />
                )}

                <div className="document-page-area">
                  <div className="document-list">
                    {document.length > 0 ? (
                      document.map((data, id) => (
                        <div className="document-file" key={id}>
                          <>
                            <div className="document-preview">
                              <FcFile className="view-icon" />
                              <div>
                                <p className="font-bold fzr-1">
                                  {data.fileName}
                                </p>
                                {/* <p>Type : {data.fileType}</p> */}
                                <p className="fzr-08">
                                  Updated on : {formatDate(data.createdDate)}
                                </p>
                              </div>
                            </div>

                            <div className="icon-container">
                              <Tooltip title="View" arrow>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(`${data.fileLocation}`);
                                  }}
                                >
                                  <BsEye className="download-icon" />
                                </IconButton>
                              </Tooltip>

                              <a
                                href={data.fileLocation}
                                download="file"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Tooltip title="Download" arrow>
                                  <IconButton>
                                    <GoDownload className="download-icon" />
                                  </IconButton>
                                </Tooltip>
                              </a>
                              {employeeRoleId === "1" ? (
                                <Tooltip title="Delete" arrow>
                                  <IconButton
                                    onClick={() =>
                                      openConfirmationModal(data.id)
                                    }
                                  >
                                    <MdOutlineDelete className="delete-icon" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        </div>
                      ))
                    ) : (
                      <p>No document available</p>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onConfirm={handleDeleteConfirmed}
        onCancel={closeConfirmationModal}
      />
    </Layout>
  );
}

export default StratDrive;
