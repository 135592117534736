import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert, Button, Snackbar, IconButton } from "@mui/material";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axios from "../../../axios/axios";
import "./ChangePassword.css";
import { AUTH_API } from "../../../constants/constants";
import Title from "../../../components/global/Title/Title";
import Layout from "../../Layout/Layout";

function ChangePassword() {
  const navigate = useNavigate();
  const collapsed = useSelector((state) => state.sidebar.collapsed);
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    reEnteredPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [statusError, setStatusError] = useState("");
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors("");
  };

  const handlevalidate = () => {
    const validationErrors = { ...errors };

    if (formData.newPassword !== formData.reEnteredPassword) {
      validationErrors.reEnteredPassword = "Passwords do not match";
    } else {
      validationErrors.reEnteredPassword = "";
    }
    setErrors(validationErrors);
  };

  const togglecurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };
  const togglenewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (formData.currentPassword?.trim() === "") {
      validationErrors.currentPassword = "Current password is required";
    }

    if (formData.newPassword?.trim() === "") {
      validationErrors.newPassword = "New password is required";
    } else if (formData.newPassword?.trim().length < 8) {
      validationErrors.newPassword =
        "New password must be at least 8 characters long";
    }

    if (formData.reEnteredPassword?.trim() === "") {
      validationErrors.reEnteredPassword = "Re enter password is required";
    }

    // if (formData.newPassword !== formData.reEnteredPassword) {
    //   validationErrors.reEnteredPassword = "Passwords do not match";
    // }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Submit the form or perform further actions
      // You can add your submit logic here
      axios
        .post(AUTH_API.CHANGE_PASSWORD, formData, {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        })
        .then((response) => {
          setShowSnackbar(true);
        })
        .catch((error) => {
          setShowSnackbar(false);
          if (
            error.response ||
            error.response.status === 404 ||
            error.response.status === 401
          ) {
            setStatusError(error.response.data.userMessage);
            console.error("Resource not found:", error.response.data.message);
          } else {
            console.error("An error occurred:", error);
          }
        });
    }
  };

  return (
    <Layout>
      <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
        <div
          className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
        />

        <div
          className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
        >
          <div className="BU-page">
            <Title text="Change Password" propClass="pb-10" />

            <div className="password-page-area">
              <form onSubmit={handleSubmit}>
                <div className="password-form-input">
                  <label htmlFor="currentPassword">
                    Current Password
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <div className="password-box">
                    <input
                      type={currentPasswordVisible ? "text" : "password"}
                      name="currentPassword"
                      value={formData.currentPassword}
                      onChange={handleChange}
                      placeholder="Enter current password"
                    />
                    {currentPasswordVisible ? (
                      <IconButton onClick={togglecurrentPasswordVisibility}>
                        <AiOutlineEyeInvisible />
                      </IconButton>
                    ) : (
                      <IconButton onClick={togglecurrentPasswordVisibility}>
                        <AiOutlineEye />
                      </IconButton>
                    )}
                  </div>
                  <span className="error-message">{statusError}</span>
                  {errors.currentPassword && (
                    <span className="error-message">
                      {errors.currentPassword}
                    </span>
                  )}
                </div>

                <div className="password-form-input">
                  <label htmlFor="newPassword">
                    New Password
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <div className="password-box">
                    <input
                      type={newPasswordVisible ? "text" : "password"}
                      name="newPassword"
                      value={formData.newPassword}
                      onChange={handleChange}
                      placeholder="Enter new password"
                    />
                    {newPasswordVisible ? (
                      <IconButton onClick={togglenewPasswordVisibility}>
                        <AiOutlineEyeInvisible />
                      </IconButton>
                    ) : (
                      <IconButton onClick={togglenewPasswordVisibility}>
                        <AiOutlineEye />
                      </IconButton>
                    )}
                  </div>
                  {errors.newPassword && (
                    <span className="error-message">{errors.newPassword}</span>
                  )}
                </div>

                <div className="password-form-input">
                  <label htmlFor="reEnteredPassword">
                    Confirm New Password
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <input
                    type="password"
                    name="reEnteredPassword"
                    value={formData.reEnteredPassword}
                    onChange={handleChange}
                    onKeyUp={handlevalidate}
                    placeholder="Re-enter new password"
                  />
                  {errors.reEnteredPassword && (
                    <span className="error-message">
                      {errors.reEnteredPassword}
                    </span>
                  )}
                </div>

                <div className="password-form-input">
                  <div className="button-group">
                    <Button className="save-button" type="submit">
                      Update
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      className="exit-button"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                <Snackbar
                  open={showSnackbar}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}
                >
                  <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    sx={{
                      width: "100%",
                      backgroundColor: "green",
                      color: "white",
                    }}
                  >
                    Password Updated successfully
                  </Alert>
                </Snackbar>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ChangePassword;
