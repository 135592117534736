import React from "react";
import { IconButton } from "@mui/material";
import { IoSearchSharp } from "react-icons/io5";
import "./Search.css"

function Search({ searchValue, handleSearch , SearchStyle }) {
  return (
    <div className="search-tab shadow" style={SearchStyle}>
      <IconButton>
        <IoSearchSharp
          size={"1.4rem"}
          opacity={0.7}
          color="rgba(71, 167, 255, 1)"
        />
      </IconButton>
      <input
        type="text"
        placeholder="Search..."
        value={searchValue}
        onChange={handleSearch}
      />
    </div>
  );
}

export default Search;
