import { useState, useEffect } from "react";
import axios from "../axios/axios";

const useFetch = (endPoint, body) => {
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(true);

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${endPoint}`,
          { ...(body || {}) },
          {
            headers: {
              Authorization: `Bearer ${logInToken}`,
              UserId: `${userId}`,
            },
          }
        );
        setResponseData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching employee data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [ logInToken , userId]);

  return { responseData, loading };
};

export default useFetch;
