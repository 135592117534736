import React from "react";
import "./RequestTille.css";

export const RequestTile = ({
  name,
  signal,
  details,
  forwardedToMe,
  active,
  isViewed,
  date
}) => {
  return (
    <div
      class={`requestTile ${isViewed === '1' ? 'tile-visited' : 'tile_not-visited'} ${
        active ? 'border-r-5' : isViewed !== '1' && 'border-1' } `}
    >
      <div class="requestTile_header">
        <h2 class="requestTile_employee fz-8">{name}</h2>
        <p
          class={`requestTile_signal fz-6 ${
            forwardedToMe ? "clr-purple" : "clr-green"
          }`}
        >
          {forwardedToMe ? "Forwarded Leave" : "Leave Application"}
        </p>
      </div>
      <p className="requestTile_date fzr-06 font-regular">{date}</p>
      <div class="requestTile_content">
        <p class="requestTile_description fz-8">
          Leave application for {details}
        </p>
      </div>
    </div>
  );
};
