import { IconButton } from "@mui/material";
import DatePicker from "react-datepicker";
//icons
import { HiOutlineCalendar } from "react-icons/hi2";
import { IoIosClose } from "react-icons/io";
import "./CustomDatePicker.css";
export default function CustomDatePicker({
  className,
  placeholderText,
  Date,
  setDate,
  minDate,
  isClear,
  handleClearCalender
}) {
  return (
    <DatePicker
      className={`${className}`}
      selected={Date}
      onChange={(date) => setDate(date)}
      placeholderText={`${placeholderText}`}
      dateFormat="dd - MM - yyyy"
      minDate={minDate}
      showPopperArrow={false} // to visible the downarrow we prefer not show that
      customInput={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0px",
          }}
        >
          <IconButton>
            <HiOutlineCalendar color="purple" size="1.2rem" />
          </IconButton>
          <div className={isClear ? `selected_date_n_clear` : 'selected_date'} >
            <span>{Date?.toLocaleDateString("en-GB").replace(/\//g, " - ")}{!Date && placeholderText}</span>
            {isClear && <button className="calender-date_clear" onClick={handleClearCalender}><IoIosClose size={'1.4rem'}/></button>}
          </div>
          
        </div>
      }
    />
  );
}
