import React, { useState } from "react";
import useTimeExtractor from "../../../hooks/useTimeExtractor";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Skeleton,
} from "@mui/material";

function AttendanceReport({ AttendanceDatas , loading }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const extractTime = useTimeExtractor;

  return (
    <div className="table-area">
      <TableContainer
        sx={{
          marginTop: "1.5rem",
          boxShadow: "none",
          backgroundColor: "transparent",
          maxHeight: "50vh",
        }}
        className="table_container"
        component={Paper}
      >
        <Table stickyHeader aria-label="sticky table" className="table">
          <TableHead>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>IN/OUT</TableCell>
              <TableCell>SWIPE TIME</TableCell>
              <TableCell>LOCATION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
               <TableCell colSpan={4}>
                  <Skeleton animation="wave" height={48} />
                </TableCell>
              </TableRow>
            ) : (
              AttendanceDatas.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                AttendanceDatas.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((data, index) => (
                <TableRow key={data?.index}>
                  <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                  <TableCell
                    sx={{
                      textTransform: "uppercase",
                    }}
                  >
                    {data?.inOrOut}
                  </TableCell>
                  <TableCell>{extractTime(data?.LogDate)}</TableCell>
                  <TableCell>{data?.floorLocation}</TableCell>
                </TableRow>
              ))
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          overflow: "hidden",
        }}
        component="div"
        count={AttendanceDatas.length}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </div>
  );
}

export default AttendanceReport;
