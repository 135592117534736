import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LEAVE_API } from "../../../constants/constants";
import useFetch from "../../../hooks/useFetch";
import SingleSelect from "../../global/Select/SingleSelect";
import "./LeaveApplication.css";

const LeaveApplication = ({
  requestData,
  url,
  Reject,
  Accept,
  // leaveRespondId,
  isResponsable,
  successRepose,
  Forward,
  isRejectable,
  isAcceptable,
  canForward
}) => {
  const [showAttachments, setShowAttachments] = useState(true);

  // ----------------- local storage ---------------
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");


  const [searchQuery, setSearchQuery] = useState("");

  const [viewAttach,setViewAttach] = useState(true)
  // const [showForward, setShowForward] = useState("");

  const LeaveRequests = requestData;

  const [formData, setFormData] = useState({
    forwardReason: "",
    forwardTo: "",
    leaveId: LeaveRequests?.id,
  });

  // api calling
  const { responseData: userforwardList } = useFetch(
    LEAVE_API.LIST_FORWARD_LEAVES
  );

  const forwardOptions = userforwardList.data
    ?.filter((data) =>
      data.employeeName?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
    .map((data) => ({
      value: data.id,
      label: data.employeeName,
    }));

  const handleForwardToSelection = (value) => {
    if (value) {
      setFormData((prevData) => {
        return {
          ...prevData,
          forwardTo: value.value,
        };
      });
    } else {
      setFormData((prevData) => {
        return {
          ...prevData,
          forwardTo: "",
        };
      });
    }
  };
  const handleReason = (e) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        forwardReason: e.target.value,
      };
    });
  };
  const handleTabKey = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          forwardReason: prevFormData.reason + "    ",
        };
      });
    }
  };

  if (Object.keys(LeaveRequests).length === 0 || successRepose) {
    return (
      <div className="leavelist_not_select">
        Please select any leave request to view
      </div>
    );
  }


  const handlePreviewClick = () => {
    setShowAttachments(!showAttachments);
    setViewAttach(pre=>!pre)
  };

  return (
    <div className="leave-application">
      <div className="content-wrapper">
        {LeaveRequests?.forwardedToName &&
        LeaveRequests?.isForwardedByMe === "1" ? (
          <>
            <div className="leave-forwarded-box">
              <div className="leave-forwarded_message">
                <p className="fzr-08">
                  Forwarded to <span>{LeaveRequests?.forwardedToName}</span>
                </p>
              </div>
              <div className="leave-forwarded_reason">
                <p className=" leave-forwarded_p fzr-08 font-medium op-7">
                  Message :
                </p>
                <p className="leave-forwarded_message-p fzr-09">
                  {LeaveRequests?.forwardReason}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            {LeaveRequests.isForwarded === "1" ? (
              <>
                <div className="leave-forwarded-box">
                  <div className="leave-forwarded_message">
                    <p className="fz-8">
                      Forwarded by <span>{LeaveRequests?.forwardedBy}</span>
                    </p>
                  </div>
                  <div className="leave-forwarded_reason">
                    <p className=" leave-forwarded_p fz-8">Message :</p>
                    <p className="fz-8">{LeaveRequests?.forwardReason}</p>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        )}

        <div className="leave-application__from">
          <div className="leave_application_items">
            <div className="leave_application_list-datas">
              <p className="fz-9">Leave application from</p>
            </div>
            <div className="leave-application__bold">
              <p className="fz-9">{LeaveRequests?.employeeName}</p>
            </div>
          </div>
        </div>
        <div className="leave-application__days fz-9">
          <div className="leave_application_items">
            <div className="leave_application_list-datas">
              Applying for total of
            </div>
            <div className="leave-application__blue">
              {LeaveRequests?.noOfDaysOfLeave} days
            </div>
          </div>
          <div className="leave_application_items">
            <div className="leave_application_list-datas">From</div>
            <div className="leave-application__blue">
              {LeaveRequests?.startDate}
            </div>
            {LeaveRequests?.startSession === "1" ? (
              <div className="leave-application__blue">Morning Session</div>
            ) : (
              <div className="leave-application__blue">Afternoon Session</div>
            )}
          </div>
          <div className="leave_application_items">
            <div className="leave_application_list-datas">To</div>
            <div className="leave-application__blue">
              {LeaveRequests?.endDate}
            </div>
            {LeaveRequests?.endSession === "1" ? (
              <div className="leave-application__blue">Morning Session</div>
            ) : (
              <div className="leave-application__blue">Afternoon Session</div>
            )}
          </div>
        </div>

        <div className="leave-application__medical fz-9">
          <div className="leave_application_items">
            <div className="leave_application_list-datas">Applying For </div>
            <div className="leave-application__blue">
              {LeaveRequests?.leaveName}
            </div>
          </div>
          <div className="leave_application_items">
            <div className="leave_application_list-datas">Reason</div>
            <div className="leave-application_reasons">
              {LeaveRequests?.reason}
            </div>
          </div>
        </div>

        {LeaveRequests?.attachment !== null ? (
          <div className="leave-application__attach">
            <div
              className="leave-application__round"
              onClick={handlePreviewClick}
            >
              {
                !viewAttach ? 
                  <div className="leave-application__line"></div> 
                  :
                  <div className="leave-application__plus"></div>
              }
            </div>
            <div className="leave-application__grey">
              {showAttachments ? "Show" : "Hide"} Attached Files
            </div>
          </div>
        ) : null}

        {!showAttachments && (
          <div className="leave-application__image">
            <img src={`${url}${LeaveRequests?.attachment}`} alt="" />
          </div>
        )}

        {LeaveRequests?.isApproved === "0" &&
        LeaveRequests?.isForwardedByMe === "0" ? (
          <div className="leave-application__buttons">
            <LoadingButton
              onClick={() => Accept(LeaveRequests.id)}
              loading={!isAcceptable}
              className={`save-button ${!isRejectable || !canForward ? 'op-5' : 'op-10' }`}
              disabled={!isRejectable || !canForward ? true : false}
            >
              {!isAcceptable ? <CircularProgress size={20} /> : "Approve"}
            </LoadingButton>
            <LoadingButton
              onClick={() => Reject(LeaveRequests.id)}
              loading={!isRejectable}
              disabled={!isAcceptable || !canForward ? true : false}
              className={`exit-button ${!isAcceptable || !canForward ? 'op-5' : 'op-10' }`}
            >
              {!isRejectable ? <CircularProgress size={20} /> : "Reject"}
            </LoadingButton>
          </div>
        ) : (
          <></>
        )}

        {LeaveRequests?.isForwarded === "0" ? (
          <div className="leave-application__req">
            <div className="leave-application__link">
              <div>Forward the leave request to :</div>
            </div>
            <div className="leave-application__check">
              <div className="select_wrapper">
                <SingleSelect
                  selectOptions={forwardOptions}
                  handleSelection={handleForwardToSelection}
                  // value={selectedOption}
                  placeholder={"Select"}
                  isMulty={false}
                />
              </div>
            </div>
            <div className="leave-application__reason">
              <textarea
                cols="15"
                rows="4"
                type="text"
                res
                placeholder="Reason..."
                onChange={handleReason}
                onKeyDown={handleTabKey}
              />
            </div>
            <div className="leave-application__buttons">
              <LoadingButton
                className={`leave-application__button ${!isRejectable || !isAcceptable ? 'op-5' : 'op-10' }`}
                onClick={() => Forward(formData, LeaveRequests?.id)}
                loading={!canForward}
                disabled={!isRejectable || !isAcceptable ? true : false}
              >
                {!canForward ? <CircularProgress size={20} /> : "Forward"}
              </LoadingButton>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default LeaveApplication;
