export function convertToMilliSeconds(time) {
  // 00:00:00.000
  const [timePart, milliSeconds] = time.split(".");
  const [hour, minute, seconds] = timePart.split(":").map(Number);

  const hourInMS = hour * 60 * 60 * 1000;
  const minuteInMS = minute * 60 * 1000;
  const secondsInMS = seconds * 1000;

  return hourInMS + minuteInMS + secondsInMS;
}

export function convertToStandardTime(ms) {
  const absOf_ms = Math.abs(ms);
  const date = new Date(absOf_ms);
  const time = date.toISOString().split("T")[1].split("Z");
  return time[0];
}
