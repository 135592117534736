import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Login from "./pages/Auth/Login";

import StratDrive from "./pages/StratDrive/StratDrive";
import loaderGif from "./assets/images/StratAgile.png";
import UserProfile from "./pages/User/UserProfile";
import "./App.css";

// administrative
import { ConfigRoutes } from "./routes/ConfigRoutes";

// forgot password
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Otp from "./pages/Auth/Otp";
import UpdatePassword from "./pages/Auth/UpdatePassword";

import NotFound from "./pages/Error/NotFound/NotFound";
import NoInternet from "./pages/Error/NoInternet/NoInternet";


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <div className="background-loader">
          <img src={loaderGif} alt="Loading..." />
        </div>
      ) : (
        ""
      )}
      <Router>
         <Suspense>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/update-password" element={<UpdatePassword />} />

            <Route path="/bu/*" element={<ConfigRoutes.BURoutes />} />
            <Route path="/leave/*" element={<ConfigRoutes.LeaveRoutes />} />
            <Route
              path="/employee/*"
              element={<ConfigRoutes.EmployeeRoutes />}
            />
            <Route
              path="/calendar/*"
              element={<ConfigRoutes.CalenderRoutes />}
            />
            <Route
              path="/request/*"
              element={<ConfigRoutes.RequestsRoutes />}
            />
            <Route
              path="/administrative/*"
              element={<ConfigRoutes.AdministrativeRoutes />}
            />
            <Route
              path="/settings/*"
              element={<ConfigRoutes.SettingsRoutes />}
            />
            <Route
              path="/attendance"
              element={<ConfigRoutes.AttendanceRoute />}
            />

            <Route path="/stratdrive" element={<StratDrive />} />
            <Route path="/profile" element={<UserProfile />} />

            <Route path="/*" element={<NotFound />} />
            <Route path="/no-interent" element={<NoInternet />} />
          </Routes>
         </Suspense>
      </Router>
    </>
  );
}

export default App;
