import React from "react";
import { Button } from "@mui/material";
import "./ConfirmationModal.css";

function ConfirmationModal({ isOpen, onConfirm, onCancel }) {
  if (!isOpen) return null;

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div>Are you sure you want to delete this item?</div>
        <div className="modal-buttons">
          <Button className="confirm-button" onClick={onConfirm}>
            Confirm
          </Button>
          <Button className="cancel-button" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
