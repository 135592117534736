import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi";
import { LoadingButton } from "@mui/lab";
import axios from "../../axios/axios";
import "../../assets/css/ForgotPassword.css";
import { CircularProgress } from "@mui/material";
import { AUTH_API } from "../../constants/constants";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState({ email: "" });
  const [statusError, setStatusError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = () => {
    setStatusError("");
    setFormError("");
  };

  const validateForm = () => {
    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.?\S*$/.test(email.trim())) {
      errors.email = "Invalid email address";
    }

    setFormError(errors);
    setStatusError(null);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      try {
        const response = await axios.post(
          AUTH_API.FORGOT_PASSWORD,
          {
            employeeEmail: email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        navigate("/otp", {
          state: { employeeEmail: email },
        });
        setLoading(false);
        if (response.status === 200) {
        } else {
          console.error("Login failed.");
        }
      } catch (error) {
        setLoading(false);
        if (
          error.response ||
          error.response.status === 404 ||
          error.response.status === 401
        ) {
          setStatusError(error.response.data.userMessage);
          console.error("Resource not found:", error.response.data);
        } else {
          setLoading(false);
          console.error("An error occurred:", error);
        }
      }
    }
  };
  return (
    <div className="forgotPassword-page">
      <div className="forgotPassword-container">
        <h2 className="fzr-175 forgotPassword-title">
          Did you forgot your password?
        </h2>
        <div className="fzr-09 forgotPassword-para">
          Enter the email address you're using for your account below, and we
          will send you a one-time password (OTP) for account recovery
        </div>

        <form onSubmit={handleSubmit}>
          <label className="fzr-08 font-bold" htmlFor="">
            Enter Email Address
          </label>
          <div className="forgotPassword-input">
            <HiOutlineMail style={{ width: "26px", height: "22px" }} />
            <input
              type="text"
              placeholder="Enter Email Address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                handleInputChange();
              }}
            />
          </div>
          <div className="error-message">
            {formError.email}
            {statusError}
          </div>

          <LoadingButton
            loading={loading}
            className="forgotPassword-button"
            type="submit"
          >
            {loading ? <CircularProgress size={20} /> : "Confirm"}
          </LoadingButton>

          <div
            onClick={() => {
              navigate(-1);
            }}
            className="backToLoginPage fzr-08"
          >
            Back to Login
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
