import React from "react";
import Layout from "../Layout/Layout";
import ApplyLeaveList from "../../components/Leaves/ApplyLeaveList/ApplyLeaveList";

function ApplyLeaveListPage() {
  return (
    <Layout>
      <ApplyLeaveList />
    </Layout>
  );
}

export default ApplyLeaveListPage;
