import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { BU_API, HOLIDAY_API } from "../../../constants/constants";
import Title from "../../../components/global/Title/Title";
import axios from "../../../axios/axios";
import DatePicker from "react-datepicker";
import Layout from "../../Layout/Layout";
import "react-datepicker/dist/react-datepicker.css";
import "./EditHoliday.css"; // Adjust the CSS path

function EditHoliday() {
  const [bu, setBu] = useState([]);
  const { holidayId } = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const collapsed = useSelector((state) => state.sidebar.collapsed);
  const [formData, setFormData] = useState({
    holidayName: "",
    holidayDate: "",
    buId: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  useEffect(() => {
    axios
      .post(
        BU_API.LIST_BU,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setBu(response.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [logInToken, userId]);

  useEffect(() => {
    axios
      .post(
        `${HOLIDAY_API.GET_HOLIDAY_BY_ID}${holidayId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...response.data[0],
        }));
        setStartDate(new Date(response.data[0].holidayDate)); // Set the date in the DatePicker
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [logInToken, userId, holidayId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!formData.holidayName || formData.holidayName?.trim() === "") {
      validationErrors.holidayName = "Name is required";
    } else if (
      !/^[A-Za-z0-9,.\s]+$/.test(formData.holidayName) ||
      /[()<>"?;'!#$%*]/.test(formData.holidayName)
    ) {
      validationErrors.holidayName =
        "Name should only contain letters, numbers, and spaces";
    }

    if (!formData.buId || formData.buId.trim() === "") {
      validationErrors.buId = "BU is required";
    }

    formData.holidayDate = startDate;

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      axios
        .post(`${HOLIDAY_API.EDIT_HOLIDAY}${holidayId}`, formData, {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        })
        .then((response) => {
          navigate("/calendar/holiday/list");
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };

  return (
    <Layout>
      <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
        <div
          className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
        />

        <div
          className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
        >
          <div className="BU-page">
            <Title text="Edit Holidays" propClass="pb-5" />

            <div className="create-BU-area">
              <form onSubmit={handleSubmit}>
                <div className="BU-form-input">
                  <label htmlFor="name">
                    Name
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="holidayName"
                    value={formData.holidayName}
                    onChange={handleChange}
                    placeholder="Enter the name"
                  />
                  {errors.holidayName && (
                    <span className="error-message">{errors.holidayName}</span>
                  )}
                </div>

                <div className="BU-form-input">
                  <label htmlFor="Date">
                    Date
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <DatePicker
                    className="custom-datepicker"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Select a date"
                    dateFormat="dd - MM - yyyy"
                  />
                </div>

                <div className="BU-form-input">
                  <label htmlFor="BU">
                    BU
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <select
                    name="buId"
                    value={formData.buId}
                    onChange={handleChange}
                  >
                    <option value="">Please Select the BU</option>
                    {bu.map((bu, id) => (
                      <option key={id} value={bu.id}>
                        {bu.buName}
                      </option>
                    ))}
                  </select>
                  {errors.buId && (
                    <span className="error-message">{errors.buId}</span>
                  )}
                  <div className="edit-button-group">
                    <Button className="save-button" type="submit">
                      Update
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      className="exit-button"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default EditHoliday;
