import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { MdHistory } from "react-icons/md";
import { Button } from "@mui/material";
import { RequestTile } from "./RequestTile";
import { LEAVE_API } from "../../../constants/constants";
import axios from "../../../axios/axios";
import LeaveApplication from "./LeaveApplication";
import ErrorPopup from "../../global/Error/ErrorPopup";
import Title from "../../global/Title/Title";
import "./LeaveRequests.css";
import "./RequestLeave.css";
import LoadingIndicator from "../../global/Loading/LoadingIndicator";

function RequestLeave() {
  const navigate = useNavigate();
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const [loading, setLoading] = useState(true);

  const [leaveRespondId, setLeaveRespondId] = useState(null);
  const [activeRequestId, setActiveRequestId] = useState(null);

  const [baseURL, setBaseURL] = useState();

  const [leaves, setLeaves] = useState([]);
  const [filteredLeaves, setFilteredLeaves] = useState([]);

  const [leaveViewerData, setLeaveViewerData] = useState({});

  const [isResponsable, setIsResponsable] = useState(true);
  const [isAcceptable, setIsAcceptable] = useState(true);
  const [isRejectable, setIsRejectable] = useState(true);
  const [canForward, setCanForward] = useState(true);
  const [successRepose, setSuccessResponse] = useState(false);

  const [validationError, setValidationError] = useState({
    errorState: false,
    mailIdError: "",
    reasonError: "",
  });

  const collapsed = useSelector((state) => state.sidebar.collapsed);

  const leavePendingList = useCallback(
    (response) => setFilteredLeaves(response),
    []
  );
  const leaveList = useCallback((response) => setLeaves(response), []);

  // this function handle visiting each tiles
  const handleLeaveTileVisit = (id) => {
    axios
      .post(
        LEAVE_API.MARK_LEAVE_VIEWED,
        { leaveId: `${id}`, status: 1 },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((res) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleData = (requestData) => {
    setLeaveViewerData((prevData) => ({
      ...prevData,
      ...requestData,
    }));
    setActiveRequestId(requestData.id);
    let leavesToUpdate = leaves.map((leave) =>
      leave.id === requestData.id ? { ...leave, isViewed: "1" } : leave
    );
    setLeaves(leavesToUpdate);
    handleLeaveTileVisit(requestData.id);
    setSuccessResponse(false);
  };

  const handleFilterLeaves = (leaveId) => {
    const updatedLeaves = leaves.filter((leave) => leave.id !== leaveId);
    setLeaves(updatedLeaves);
    setLeaveRespondId(leaveId);
  };

  useEffect(() => {
    axios
      .post(
        LEAVE_API.LIST_PENDING_LEAVES,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        const resData = response.data.data;
        setBaseURL(response.data.baseURL);
        const leavePendings = resData.filter((obj) => obj.isApproved === "0");
        leaveList(leavePendings);
        leavePendingList(leavePendings);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("error", error);
      });
  }, [
    logInToken,
    userId,
    leavePendingList,
    // forceState
  ]);

  // leave approve api calling
  const handleAccept = (leaveId) => {
    setIsAcceptable(false);
    axios
      .post(
        LEAVE_API.TAKE_LEAVE_ACTION,
        { leaveId, status: 1 },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        handleFilterLeaves(leaveId);
        setIsAcceptable(true);
        setSuccessResponse(true);
      })
      .catch((error) => {
        setIsAcceptable(true);
      });
  };

  // leave reject api calling
  const handleReject = (leaveId) => {
    setIsRejectable(false);

    axios
      .post(
        LEAVE_API.TAKE_LEAVE_ACTION,
        { leaveId, status: 2 },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        handleFilterLeaves(leaveId);
        setIsRejectable(true);
        setSuccessResponse(true);
      })
      .catch((error) => {
        console.error("Error", error);
        setIsRejectable(true);
      });
  };

  useEffect(() => {
    if (validationError.errorState) {
      const timer = setTimeout(() => {
        setValidationError((prevState) => ({
          ...prevState,
          errorState: false,
        }));
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [validationError]);

  const onSubmit = (formData, idOfLeave) => {
    setCanForward(false);
    let validationErrorObj = {
      errorState: false,
      mailIdError: "",
      reasonError: "",
    };

    if (formData.forwardTo.length === 0) {
      validationErrorObj = {
        ...validationErrorObj,
        errorState: true,
        reasonError: "Please provide reason",
      };
    }

    if (formData.forwardTo.length === 0) {
      validationErrorObj = {
        ...validationErrorObj,
        errorState: true,
        mailIdError: "Please select forward to",
      };
    } else if (formData.forwardReason.length < 10) {
      validationErrorObj = {
        ...validationErrorObj,
        errorState: true,
        reasonError: "Reason must have minimum of 10 characters.",
      };
    }

    if (formData.forwardReason !== "" && formData.forwardReason.length > 10) {
      const dataList = { ...formData, leaveId: idOfLeave };
      axios
        .post(LEAVE_API.FORWARD_LEAVE, dataList, {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        })
        .then((response) => {
          handleFilterLeaves(formData.id);
          setSuccessResponse(true);
          setCanForward(true);
        })
        .catch((error) => {
          console.error("error", error);
          setCanForward(true);
        });
    } else {
      setValidationError((pre) => ({ ...pre, ...validationErrorObj }));
      setCanForward(true);
    }
  };

  return (
    <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
      <div className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`} />

      <div className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}>
        <div className=" page">
          {validationError.errorState && <ErrorPopup data={validationError} />}
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Title
                  text="Leave Requests"
                  propClass="pb-10 leave-list-title"
                />

                <Button
                  endIcon={<MdHistory />}
                  onClick={() => {
                    navigate("/request/leaves/history");
                  }}
                  className="leave_history-btn"
                >
                  History
                </Button>
              </div>
              {leaves?.length === 0 ? (
                <p className="notfound_request">No leave requests found.</p>
              ) : (
                <section className="Request-page">
                  {/* ------------------------------- request tile section ------------------------- */}
                  <section className="requestTiles_section">
                    <div className="requestTiles_container">
                      {leaves?.map((request) => (
                        <div
                          key={request.id}
                          onClick={() => handleData(request)}
                        >
                          <RequestTile
                            key={request.id}
                            details={request.leaveName}
                            name={request.employeeName}
                            date={
                              request.isForwarded === "1"
                                ? request.forwardDateTime
                                : request.AppliedOn
                            }
                            active={activeRequestId === request.id}
                            signal={"Leave Application"}
                            isViewed={request.isViewed}
                          />
                        </div>
                      ))}
                    </div>
                  </section>

                  {/* ------------------------------------ request application section ---------------------  */}
                  <section className="requestApplication_section">
                    <div className="requestApplication_container">
                      <LeaveApplication
                        Reject={handleReject}
                        Accept={handleAccept}
                        Forward={onSubmit}
                        isResponsable={isResponsable}
                        url={baseURL}
                        requestData={leaveViewerData}
                        leaveRespondId={leaveRespondId}
                        successRepose={successRepose}
                        isRejectable={isRejectable}
                        isAcceptable={isAcceptable}
                        canForward={canForward}
                        // disabeleForward={isFor}
                      />
                    </div>
                  </section>
                </section>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default RequestLeave;
