export function sortLeavesWithIncDate(leaveList) {
  const sortByStartDate = leaveList.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );
  return sortByStartDate;
}
export function sortListWithIncTime(list) {
  const sortedList = list?.sort(
    (a, b) => new Date(`1970-01-01T${a.time}`).getTime() - new Date(`1970-01-01T${b.time}`).getTime()
  );
  return sortedList;
}

export function sortByMonth(leaveList) {
  const leaveSortByMonth = {};
  leaveList.forEach((element) => {
    const month = element.startDate.slice(0, 7);
    if (!leaveSortByMonth[month]) {
      leaveSortByMonth[month] = [];
    }
    leaveSortByMonth[month].push(element);
  });
  return leaveSortByMonth;
}



export function splitLeaves(leaveList) {
  const daysName = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
  let updatedLeaveList = [];
  let leaveDay = ''
  const findDaysDifference = (toDate, fromDate) => {
    const timeDifference = toDate.getTime() - fromDate.getTime(); // we need to make it into milliseconds and find difference
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };
  leaveList.forEach((leave) => {
    if (leave.startDate !== leave.endDate) {
      const fromDate = new Date(leave.startDate);
      let nextDate = fromDate;
      const toDate = new Date(leave.endDate);
      let updatedLeave = leave;

      // convert milliseconds to days
      let daysDifference = null;
      let daysCount = 0;
      let maxCount = null;
      do {
        daysDifference = findDaysDifference(toDate, nextDate);
        if (daysCount === 0) {
          maxCount = daysDifference;
          leave.startSession === "1"
            ? (updatedLeave = { ...leave, session: "Full Day" })
            : (updatedLeave = { ...leave, session: "Afternoon" });
        } else if (daysCount === maxCount) {
          leave.endSession === "1"
            ? (updatedLeave = { ...leave, session: "Forenoon" })
            : (updatedLeave = { ...leave, session: "Full Day" });
        } else {
          updatedLeave = { ...leave, session: "Full Day" };
        }
        leaveDay = daysName[nextDate.getDay()] 
        daysCount = daysCount + 1;
        updatedLeave = {
          ...updatedLeave,
          date: nextDate.toISOString().split("T")[0],
          day:leaveDay
        };

        nextDate.setDate(fromDate.getDate() + 1);
        updatedLeaveList.push(updatedLeave);
      } while (daysDifference !== 0);

    } else {
      let leaveToUpdate = leave;
      const date = new Date(leaveToUpdate.startDate)
      leaveDay = daysName[date.getDay()] 
      if (parseFloat(leave.endSession) - parseFloat(leave.startSession) !== 0) {
        leaveToUpdate = { ...leaveToUpdate, session: "Full Day" };
      } else if (parseFloat(leave.startSession) === 1) {
        leaveToUpdate = { ...leaveToUpdate, session: "Forenoon" };
      } else {
        leaveToUpdate = { ...leaveToUpdate, session: "Afternoon" };
      }
      leaveToUpdate = { ...leaveToUpdate, date: leave.startDate ,day:leaveDay};
      updatedLeaveList.push(leaveToUpdate);
    }
  });
  return updatedLeaveList;
}

export function refineLeaveListWithSessionAndDate(leaveList) {}
