import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../NotFound/NotFound.css";

function NoInternet() {
  const navigate = useNavigate();
  return (
    <div className="page_404">
      <div className="page_404_container">
        <div className="notfound-title fzr-175">Disconnected?</div>
        <p className="notfount-para">
          Please check your network and internet service.
        </p>
        <div className="contant_box_404">
          <Button
            onClick={() => {
              navigate(-1);
            }}
            className="link_404"
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NoInternet;
