import React, { useContext } from 'react'
import { constants } from '../constants'
import { UserDataContext } from '../context/UserContext';
import useTerminateSession from '../hooks/useTerminateSession';

const CheckAuth = (accessCode) => {
  const { userData } = useContext(UserDataContext);
  const handleLogout = useTerminateSession()

  try {
    const employeeRoleId = parseInt(userData.employeeRoleId);
    const accessList = constants.accessList[accessCode];

    if (Array.isArray(accessList) && accessList.includes(employeeRoleId)) {
      return true;
    } else {
      throw new Error(`Unauthorized access: Employee role ID ${employeeRoleId} not found in access list for code ${accessCode}`);
    }
  } catch (error) {
    console.error("Authorization error:", error.message);
    handleLogout()
  }
};

const AuthorizeRoute = (AuthWrapperComponent, accessCode) => ({props}) => {

  if (CheckAuth(accessCode)) {
    return <AuthWrapperComponent {...props} />
  } 
}

export default AuthorizeRoute