import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { BU_API } from "../../../constants/constants";
import axios from "../../../axios/axios";
import Title from "../../../components/global/Title/Title";
import "../BUCREATE/CreateBU.css";
import Layout from "../../Layout/Layout";

function CreateBU() {
  const navigate = useNavigate();
  const collapsed = useSelector((state) => state.sidebar.collapsed);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    country: "",
    state: "",
    city: "",
    address: "",
    zipCode: "",
    buContactNumber: "",
    buEmail: "",
  });

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (formData.name.trim() === "") {
      validationErrors.name = "Name is required";
    } else if (
      !/^[A-Za-z,.\s]+$/.test(formData.name) ||
      /[()<>"?;'!#$%*]/.test(formData.name)
    ) {
      validationErrors.name =
        "Name should only contain letters, spaces, commas, and periods, and should not contain special characters";
    }

    if (formData.country.trim() === "") {
      validationErrors.country = "Country is required";
    } else if (/[()<>"'?;!#$%*]/.test(formData.country)) {
      validationErrors.country =
        "Country should not contain special characters";
    }

    if (formData.state.trim() === "") {
      validationErrors.state = "State is required";
    } else if (/[()<>?'";!#$%*]/.test(formData.state)) {
      validationErrors.state = "State should not contain special characters";
    }

    if (formData.city.trim() === "") {
      validationErrors.city = "City is required";
    } else if (/[()<>'"?;!#$%*]/.test(formData.city)) {
      validationErrors.city = "City should not contain special characters";
    }
    if (!/^\+?\d{10,20}$/.test(formData.buContactNumber)) {
      validationErrors.buContactNumber =
        "Phone Number should be a 10 to 20-digit number, possibly with a leading plus sign";
    }

    if (formData.buEmail.trim() === "") {
    } else if (!formData.buEmail.includes("@")) {
      validationErrors.buEmail = "Please enter a valid email address.";
    } else if (/[()<>"'?;!#$%*&_]/.test(formData.buEmail)) {
      validationErrors.buEmail = "Please enter a valid email address.";
    }

    if (formData.address.trim() === "") {
      validationErrors.address = "Address is required";
    }

    if (formData.zipCode.trim() === "") {
      validationErrors.zipCode = "Zip Code is required";
    } else if (/[()<>'";?!#$%*]/.test(formData.zipCode)) {
      validationErrors.zipCode =
        "Zipcode should not contain special characters";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      axios
        .post(BU_API.CREATE_BU, formData, {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        })
        .then(() => {
          navigate("/bu/list");
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };

  return (
    <Layout>
      <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
        <div
          className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
        />

        <div
          className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
        >
          <div className="BU-page">
            <Title text="Add Business Unit" propClass="pb-5" />

            <div className="create-BU-area">
              <form onSubmit={handleSubmit}>
                <div className="BU-form-input">
                  <label htmlFor="name">
                    Name
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter the name"
                  />
                  {errors.name && (
                    <span className="error-message">{errors.name}</span>
                  )}
                </div>

                <div className="BU-form-input">
                  <label htmlFor="country">
                    Country
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    placeholder="Enter country name"
                  />
                  {errors.country && (
                    <span className="error-message">{errors.country}</span>
                  )}
                </div>

                <div className="BU-form-input">
                  <label htmlFor="state">
                    State
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    placeholder="Enter state name"
                  />
                  {errors.state && (
                    <span className="error-message">{errors.state}</span>
                  )}
                </div>

                <div className="BU-form-input">
                  <label htmlFor="city">
                    City
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="Enter city name"
                  />
                  {errors.city && (
                    <span className="error-message">{errors.city}</span>
                  )}
                </div>

                <div className="BU-form-input">
                  <label htmlFor="buContactNumber">Contact Number</label>
                  <input
                    type="text"
                    name="buContactNumber"
                    value={formData.buContactNumber}
                    onChange={handleChange}
                    placeholder="Enter contact number"
                  />
                  {errors.buContactNumber && (
                    <span className="error-message">
                      {errors.buContactNumber}
                    </span>
                  )}
                </div>

                <div className="BU-form-input">
                  <label htmlFor="buContactNumber">Contact Email</label>
                  <input
                    type="text"
                    name="buEmail"
                    value={formData.buEmail}
                    onChange={handleChange}
                    placeholder="Enter contact email"
                  />
                  {errors.buEmail && (
                    <span className="error-message">{errors.buEmail}</span>
                  )}
                </div>

                <div className="BU-form-input">
                  <label htmlFor="address">
                    Address
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <textarea
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="Enter the address"
                  />
                  {errors.address && (
                    <span className="error-message">{errors.address}</span>
                  )}
                </div>

                <div className="BU-form-input">
                  <label htmlFor="zipCode">
                    Zip Code
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <input
                    type="number"
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleChange}
                    placeholder="Enter zipcode"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                  {errors.zipCode && (
                    <span className="error-message">{errors.zipCode}</span>
                  )}

                  <div className="button-group">
                    <Button className="save-button" type="submit">
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      className="exit-button"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CreateBU;
