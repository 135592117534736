import React from "react";
import Layout from "../Layout/Layout";
import CompenstationLeaveList from "../../components/Employee/EmployeeList/CompenstationLeaveList";

function CompenstationLeaveListPage() {
  return (
    <Layout>
      <CompenstationLeaveList />
    </Layout>
  );
}

export default CompenstationLeaveListPage;
