import React from "react";
import Layout from "../Layout/Layout";
import Alumni from "../../components/Employee/Alumni/Alumni";

function EmployeeAlumniPage() {
  return (
    <Layout>
      <Alumni />
    </Layout>
  );
}

export default EmployeeAlumniPage;
