import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "../../../context/UserContext";

//icons
import { IoEyeOutline } from "react-icons/io5";
import { MdOutlineDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
export default function DropDown({ id, handleDeleteClicked }) {
  const navigate = useNavigate();
  const { userData } = useContext(UserDataContext);
  const employeeRoleId = userData.employeeRoleId;

  return (
    <div className="dropdown_content shadow ">
      <button
        className="dropdown_p"
        onClick={() => {
          navigate(`/employee/view/${id}`);
        }}
      >
        <i>
          {" "}
          <IoEyeOutline size={"1rem"} />
        </i>
        View
      </button>
      <button
        className="dropdown_p"
        onClick={() => {
          navigate(`/employee/edit/${id}`);
        }}
      >
        <i>
          <FiEdit size={"1rem"} />
        </i>
        Edit
      </button>
      {employeeRoleId === "1" ? (
        <button className="dropdown_p" onClick={() => handleDeleteClicked(id)}>
          <i>
            <MdOutlineDelete size={"1.2rem"} />
          </i>
          Delete
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
