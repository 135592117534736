import React from "react";
import Layout from "../Layout/Layout";

function AdministrativeLayout({ children }) {
  return (
    <Layout>
      <div className="administrative_page page-area px-container">
        <section className="administrative_page_header">
          {/* <p>this is administrative page</p> */}
        </section>
        <div className="administrative_page_main">
          {children ? children : <p>No Data</p>}
        </div>
      </div>
    </Layout>
  );
}

export default AdministrativeLayout;
