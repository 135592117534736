import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { LEAVE_API } from "../../../constants/constants";
import { Button } from "@mui/material";
import useFormatDate from "../../../hooks/useFormatDate";
import axios from "../../../axios/axios";
import Title from "../../global/Title/Title";
import "./ApplyLeaveList.css";
import LoadingIndicator from "../../global/Loading/LoadingIndicator";

function ApplyLeaveList() {
  const [leaves, setLeaves] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(true);
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const collapsed = useSelector((state) => state.sidebar.collapsed);

  useEffect(() => {
    axios
      .post(
        `${LEAVE_API.LIST_APPLIED_LEAVES + userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setLeaves(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("error", error);
      });
  }, [logInToken, userId, refreshKey]);

  const formatDate = useFormatDate;

  const handleremove = (leaveId) => {
    axios
      .post(
        LEAVE_API.CANCEL_APPLIED_LEAVE,
        { leaveId },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  return (
    <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
      <div className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`} />

      <div className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}>
        <div className="BU-page">
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Title text="Leave List" propClass="pb-10" />

              <div className="leaveapply-list-area">
                {leaves?.length > 0 ? (
                  leaves?.map((data, id) => (
                    <div className="leavelist-card" key={id}>
                      <div className="leavelist-box-content">
                        <div className="leave-info">
                          <div className="info-label">Leave Type</div>
                          <div className="info-value">{data?.leaveName}</div>
                        </div>

                        <div className="leave-info">
                          <div className="info-label">Duration:</div>
                          <div className="info-value">
                            {formatDate(data?.startDate)} to{" "}
                            {formatDate(data?.endDate)}
                          </div>
                        </div>

                        <div className="leave-info">
                          <div className="info-label">No of Days</div>
                          <div className="info-value">
                            {data?.noOfDaysOfLeave}
                          </div>
                        </div>
                        <div className="leave-info">
                          <div className="info-label">Status</div>
                          {data?.isCancelled === "1" ? (
                            <div
                              className="info-value"
                              style={{ color: "red" }}
                            >
                              CANCELLED
                            </div>
                          ) : data?.isApproved === "0" ? (
                            <div
                              className="info-value"
                              style={{ color: "orange" }}
                            >
                              PENDING
                            </div>
                          ) : data?.isApproved === "1" ? (
                            <div
                              className="info-value"
                              style={{ color: "green" }}
                            >
                              APPROVED
                            </div>
                          ) : data?.isApproved === "2" ? (
                            <div
                              className="info-value"
                              style={{ color: "red" }}
                            >
                              REJECTED
                            </div>
                          ) : data?.isCancelled === "1" ? (
                            <div
                              className="info-value"
                              style={{ color: "red" }}
                            >
                              CANCELLED
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="leavelist-box-content-row">
                        <div className="leavelist-box-content-head">Reason</div>
                        <div className="info-value">{data?.reason}</div>
                      </div>
                      <div className="leavelist-button">
                        <div className="leavelist-box-content-row">
                          <div className="leavelist-applied-date">
                            Applied on
                          </div>
                          <div className="info-value">
                            {formatDate(data?.createdOn)}
                          </div>
                        </div>
                        <div className="leavelist-button">
                          {!(
                            data?.isCancelled === "1" ||
                            data?.isApproved === "1" ||
                            data?.isApproved === "2"
                          ) && (
                            <Button
                              className="leavelist-withdraw-button"
                              onClick={() => handleremove(data.id)}
                            >
                              Withdraw
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no_data_message">
                    Looks Like you have no data here!
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ApplyLeaveList;
