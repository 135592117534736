import React, { useEffect, useState } from "react";
import AdministrativeLayout from "../AdministrativeLayout";
import { sortListWithIncTime } from "../../../util/leaveReportListOperations";
import {
  convertToMilliSeconds,
  convertToStandardTime,
} from "../../../util/formatTime";
import CustomDatePicker from "../../../components/global/Datepicker/CustomDatePicker";
import FirstLoginTable from "../../../components/Administrative/DailyLog/FirstLoginTable";
import formateDate from "../../../util/formateDate";
import axios from "../../../axios/axios";
import Title from "../../../components/global/Title/Title";
import { ATTENDANCE } from "../../../constants/constants";

function DailyLog() {
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const [selectDate, setSelectDate] = useState();
  const [responseData, setResponseData] = useState([]);

  const currentDate = new Date().toISOString().split("T")[0];

  const selectLogDate = (value) => {
    setSelectDate(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          ATTENDANCE.USER_FIRST_LOGIN,
          {
            logDate: selectDate
              ? formateDate(selectDate).toISOString().split("T")[0]
              : currentDate,
          },
          {
            headers: {
              Authorization: `Bearer ${logInToken}`,
              UserId: `${userId}`,
            },
          }
        );
        setResponseData(response.data);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchData();
  }, [logInToken, userId, selectDate, currentDate]);

  const dailyLogRes = responseData?.data;

  const listOfLogs = dailyLogRes?.map((logs) => {
    const dateAndTime = logs.firstLogDate.split(" ");
    const date = dateAndTime[0];
    const time = dateAndTime[1];
    return {
      date: date,
      time: time,
      ...logs,
    };
  });

  const sortedListWithTime = sortListWithIncTime(listOfLogs);
  const refTimeInMS = 34200000; // 09:30:00 (without milliseconds)
  let differenceAddedList = [];
  if (sortedListWithTime?.length > 0) {
    differenceAddedList = sortedListWithTime.map((obj) => {
      const timeDifferenceInMS = refTimeInMS - convertToMilliSeconds(obj.time);
      const difference = convertToStandardTime(timeDifferenceInMS);

      if (timeDifferenceInMS >= 0) {
        return { ...obj, earlyBy: difference, lateBy: "00:00:00" };
      } else {
        return { ...obj, earlyBy: "00:00:00", lateBy: difference };
      }
    });
  }

  return (
    <AdministrativeLayout>
      <div className="flr_header">
        <Title text="First Login Report" propClass="pb-10" />
        <div className="leave-report_query-section_data ">
          <CustomDatePicker
            Date={selectDate}
            setDate={selectLogDate}
            className={""}
            placeholderText={`${new Date()
              .toLocaleDateString("en-GB")
              .split("/")
              .join(" - ")}`}
          />
        </div>
      </div>

      <main>
        <FirstLoginTable
          tableHeaderList={[
            "SN",
            "Name",
            "Employee Id",
            "Login Time",
            "Early by",
            "Late by",
          ]}
          sortable={["Login Time", "Early by", "Late by"]}
          tableDataList={differenceAddedList}
          filterList={{
            employeeName: "",
            employeeId: "",
            time: "",
            earlyBy: "",
            lateBy: "",
          }}
        />
      </main>
    </AdministrativeLayout>
  );
}

export default DailyLog;
