import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_API, EMPLOYEE_API } from "../../constants/constants";
import {
  createEmployeeList,
  updateEmployeeList,
} from "../../redux/employeeReducer";
import Layout from "../Layout/Layout";
import axios from "../../axios/axios";
import useFetch from "../../hooks/useFetch";
import ConfirmationModal from "../../components/global/model/ConfirmationModal";
import Title from "../../components/global/Title/Title";
import EmployeeFilterComponent from "../../components/Employee/UpdatedEmployeeList/EmployeeFilterComponent";
import EmployeeCard from "../../components/Employee/UpdatedEmployeeList/EmployeeCard";
import "../../components/Employee/EmployeeList/EmployeeList.css";
import LoadingIndicator from "../../components/global/Loading/LoadingIndicator";

export default function EmployeeList() {
  const dispatch = useDispatch();

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const [searchFilteredEmployee, setSearchFilteredEmployee] = useState([]);

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(null);

  const [searchTerm, setSearcTerm] = useState("");

  const employeeList = useSelector((state) => state.employee.employee);

  const handleFilterSearch = (searchTerm) => {
    setSearcTerm(searchTerm);
    if (searchTerm === "Department") {
      setSearchFilteredEmployee(employeeList);
      return;
    }
    const filteredLeaves = employeeList.filter((data) => {
      const employeeNameMatch = data?.employeeName
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());
      const buNameMatch = data?.buName
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());
      const designationNameMatch = data?.designationName
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());

      return employeeNameMatch || buNameMatch || designationNameMatch;
    });
    setSearchFilteredEmployee(filteredLeaves);
  };

  useEffect(() => {
    setSearchFilteredEmployee(employeeList);
  }, [employeeList]);

  // filter employee as per designation 
  const filterWithDesignation = (designation) => {
    setSearcTerm("");
    if (designation) {
      const updatedEmployeeList = employeeList.filter(
        (employee) => employee.designationName === designation
      );
      setSearchFilteredEmployee(updatedEmployeeList);
    } else {
      setSearchFilteredEmployee(employeeList);
    }
  };

  // redux state
  const collapsed = useSelector((state) => state.sidebar.collapsed);

  const { responseData: employeeBUID, loading: employeeRoleIdLoding } =
    useFetch(AUTH_API.GET_USER_ROLE_ID);

  useLayoutEffect(() => {
    axios
      .post(
        EMPLOYEE_API.LIST_EMPLOYEES,
        { employeeBUID },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        dispatch(createEmployeeList(response.data));
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [logInToken, userId, employeeBUID, dispatch]);

  const handleDeleteConfirmed = () => {
    axios
      .post(
        EMPLOYEE_API.DELETE_EMPLOYEE,
        { employeeId: isConfirmationModalOpen },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then(() => {
        dispatch(updateEmployeeList(isConfirmationModalOpen));

        setConfirmationModalOpen(null);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const handleDeleteClicked = (id) => {
    setConfirmationModalOpen(id);
  };
  const closeConfirmationModal = () => {
    setConfirmationModalOpen(null);
  };

  return (
    <Layout>
      <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
        <div
          className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
        />
        <div
          className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
        >
          <section className="BU-page">
            {employeeRoleIdLoding ? (
              <LoadingIndicator />
            ) : (
              <>
                <Title text="Employee List" propClass="pb-5" />

                <EmployeeFilterComponent
                  searchText={searchTerm}
                  handleFilterSearch={handleFilterSearch}
                  filterWithDesignation={filterWithDesignation}
                />

                <div className="BU-page_cards_container">
                  <div
                    className={` BU-page_cards ${
                      !collapsed ? `cards-collapsed` : ""
                    }`}
                  >
                    {searchFilteredEmployee?.length === 0 && (
                      <p className="no-data_message fz-12 p-relative">
                        No data to dispaly
                      </p>
                    )}
                    {searchFilteredEmployee?.map((obj) => (
                      <EmployeeCard
                        key={obj?.id}
                        id={obj?.id}
                        buName={obj?.buName}
                        employeeId={obj?.employeeId}
                        designation={obj?.designationName}
                        employeeName={obj?.employeeName}
                        workEmail={obj?.workEmail}
                        skypeId={obj?.skypeId}
                        employeeProfilePicture={obj?.employeeProfilePicture}
                        handleDeleteClicked={handleDeleteClicked}
                        isConfirmationModalOpen={isConfirmationModalOpen}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          </section>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onConfirm={handleDeleteConfirmed}
        onCancel={closeConfirmationModal}
      />
    </Layout>
  );
}
