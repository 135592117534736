// import React, { useContext } from "react";
// import { useSelector } from "react-redux";
// import { UserDataContext } from "../../context/UserContext";
// import DashBoardCard from "../../components/DashBoard/DashBoardCard";
// import UpcommingHolidays from "../../components/DashBoard/UpcommingHolidays";
// import WorkingDayList from "../../components/DashBoard/WorkingDayList";
// import OnLeaveToday from "../../components/DashBoard/OnLeaveToday";
// import OnLeavePending from "../../components/DashBoard/OnLeavePending";
// import Title from "../../components/global/Title/Title";
// import Layout from "../Layout/Layout";
// import "../../assets/css/DashBoard.css";

// function Home() {
//   const collapsed = useSelector((state) => state.sidebar.collapsed);
//   const date = new Date();
//   const formattedDate = formatCustomDate(date);

//   const { userData } = useContext(UserDataContext);
//   const employeeRoleId = userData.employeeRoleId;

//   return (
//     <Layout>
//       <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
//         <div
//           className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
//           />

//         <div
//           className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
//           >
//           <div className="BU-page">
//             <Title text="Dashboard" propClass="pb-10" />
//             <p
//               className="dashboard-date-display fzr-09"
//               dangerouslySetInnerHTML={{ __html: formattedDate }}
//             ></p>
//           </div>

//           <div className="dashboard-list-page">
//             <div className="dashboard-item-left">
//               <DashBoardCard />
//               <OnLeaveToday />
//               <WorkingDayList />
//               {employeeRoleId === "4" ? (
//                 <div className="dashboard_marginview" />
//               ) : (
//                 <OnLeavePending />
//               )}
//             </div>
//             <div className="dashboard-item-right">
//               {employeeRoleId === "1" ? <></> : <UpcommingHolidays />}
//             </div>
//           </div>
//         </div>
//       </div>
//     </Layout>
//   );
// }

// export default Home;

// function formatCustomDate(date) {
//   const day = date.getDate();
//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   const month = monthNames[date.getMonth()];
//   const year = date.getFullYear();

//   return `${day} ${month} ${year}`;
// }

import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { UserDataContext } from "../../context/UserContext";
import DashBoardCard from "../../components/DashBoard/DashBoardCard";
import UpcommingHolidays from "../../components/DashBoard/UpcommingHolidays";
import WorkingDayList from "../../components/DashBoard/WorkingDayList";
import OnLeaveToday from "../../components/DashBoard/OnLeaveToday";
import OnLeavePending from "../../components/DashBoard/OnLeavePending";
import Title from "../../components/global/Title/Title";
import Layout from "../Layout/Layout";
import "../../assets/css/DashBoard.css";

function Home() {
  const collapsed = useSelector((state) => state.sidebar.collapsed);
  const { userData } = useContext(UserDataContext);
  const employeeRoleId = userData.employeeRoleId;
  const formattedDate = formatCustomDate(new Date());

  return (
    <Layout>
      <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
        <div
          className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
        />

        <div
          className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
        >
          <div className="BU-page">
            <Title text="Dashboard" propClass="pb-10" />
            <p
              className="dashboard-date-display fzr-09"
              dangerouslySetInnerHTML={{ __html: formattedDate }}
            ></p>
          </div>

          <div className="dashboard-list-page">
            <div className="dashboard-item-left">
              <DashBoardCardMemoized />
              <OnLeaveToday />
              <WorkingDayListMemoized />
              {employeeRoleId === "4" ? (
                <div className="dashboard_marginview" />
              ) : (
                <OnLeavePendingMemoized />
              )}
            </div>
            <div className="dashboard-item-right">
              {employeeRoleId !== "1" && <UpcommingHolidaysMemoized />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

// Memoized components
const DashBoardCardMemoized = React.memo(DashBoardCard);
const WorkingDayListMemoized = React.memo(WorkingDayList);
const OnLeavePendingMemoized = React.memo(OnLeavePending);
const UpcommingHolidaysMemoized = React.memo(UpcommingHolidays);

function formatCustomDate(date) {
  const day = date.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export default Home;
