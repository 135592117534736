import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert, Button, Snackbar } from "@mui/material";
import { EMPLOYEE_API } from "../../../constants/constants";
import axios from "../../../axios/axios";
import Select from "react-select";
import Title from "../../global/Title/Title";
import "../CreateEmployee/CreateEmployee.css";

function CompensationLeave() {
  const navigate = useNavigate();
  const collapsed = useSelector((state) => state.sidebar.collapsed);

  const [employeeId, setEmployeeId] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [noOfDays, setNoOfDays] = useState("");
  const [reason, setReason] = useState("");

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i <= 2030; i++) {
    years.push({ label: i, value: i });
  }

  // employee name list
  useEffect(() => {
    axios
      .post(
        "/employee",
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setEmployeeData(response.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [logInToken, userId]);

  useEffect(() => {
    if (employeeId) {
      axios
        .post(
          `/employee/employee_details/${employeeId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${logInToken}`,
              UserId: `${userId}`,
            },
          }
        )
        .then((response) => {
          setEmployeeDetails(response.data[0]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [logInToken, userId, employeeId]);


  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const employeeOptions = employeeData
    .filter((data) =>
      data.employeeName?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
    .map((data) => ({
      value: data.id,
      label: data.employeeName,
    }));

  const LeaveTypeOptions = leaves
    .filter((data) =>
      data.leaveName?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
    .map((data) => ({
      value: data.id,
      label: data.leaveName,
    }));

  const handleLeaveSelection = (selectedLeaveOption) => {
    setSelectedLeaveType(selectedLeaveOption);
  };

  const handleEmployeeSelectChange = (selectedOption) => {
    if (selectedOption) {
      setEmployeeId(selectedOption.value);
      setSelectedEmployee(selectedOption);
      setSelectedLeaveType(selectedOption.value);
      setSelectedDate(null);
    } else {
      setEmployeeId(null);
      setSelectedEmployee(null);
      setSelectedDate(null);
      setSelectedLeaveType(null);
      setEmployeeDetails([]);
      setLeaves([]);
    }
  };


  const handleEmployeeSelectDate = (selectedDateOption) => {
    console.log("Option selected:", selectedDateOption);

    if (selectedDateOption) {
      setSelectedDate(selectedDateOption);

      setLeaves([]);

      if (employeeId) {
        const year = selectedDateOption.value;
        axios
          .post(
            EMPLOYEE_API.ALLOCATE_LEAVE,
            { employeeId, year },
            {
              headers: {
                Authorization: `Bearer ${logInToken}`,
                UserId: `${userId}`,
              },
            }
          )
          .then((response) => {
            setLeaves(response.data);
          })
          .catch((error) => {
            console.error("Error submitting leave allocation:", error);
          });
      }
    } else {
      setSelectedDate(null);
    }
  };

  const handlesubmit = (e) => {
    e.preventDefault();

    if (employeeId && selectedDate) {
      const year = selectedDate.value;

      const dataToSubmit = {
        employeeId,
        year,
        leaveId: selectedLeaveType.value,
        noOfDays,
        reason,
      };

      axios
        .post(EMPLOYEE_API.COMPENSATE_LEAVE, dataToSubmit, {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        })
        .then((response) => {
          setShowSnackbar(true);
          navigate("/employee/compensation-leave-list");
        })
        .catch((error) => {
          console.error("Error submitting leave allocation:", error);
        });
    } else {
      console.error("Please select an employee and a year before submitting.");
    }
  };

  return (
    <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
      <div className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`} />

      <div className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}>
        <div className="BU-page">
          <Title text="Compensation Leave Allocation" propClass="pb-5" />

          <div className="create-BU-area">
            <form onSubmit={handlesubmit}>
              <div className="employee-personal-data">
                <div className="employee-leave-add">
                  <Select
                    options={employeeOptions}
                    value={selectedEmployee}
                    onChange={handleEmployeeSelectChange}
                    isClearable
                    placeholder="Search for an employee"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: 400,
                        height: 40,
                        margin: "5px 0",
                      }),
                      input: (provided) => ({
                        ...provided,
                      }),
                    }}
                  /> 
                  <Select
                    options={years}
                    value={selectedDate}
                    onChange={handleEmployeeSelectDate}
                    placeholder="year"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 40,
                        margin: "5px 0",
                      }),
                    }}
                  />
                  <Select
                    options={LeaveTypeOptions}
                    value={selectedLeaveType}
                    onChange={handleLeaveSelection}
                    placeholder="Select Leave"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: 200,
                        height: 40,
                        margin: "5px 0",
                      }),
                      input: (provided) => ({
                        ...provided,
                      }),
                    }}
                  />
                </div>
                {/* Render the employee details */}
                {employeeDetails && (
                  <div className="employee-data">
                    <p>Employee ID : {employeeDetails?.employeeId}</p>
                  </div>
                )}
              </div>

              <div className="employee-ompensationLeave-data">
                <div className="checkbox-list">
                  <div className="employee-compensationLeave">
                    <label>Number of days</label>
                    <input
                      type="text"
                      name="noOfDays"
                      value={noOfDays}
                      onChange={(e) => setNoOfDays(e.target.value)}
                      placeholder="Enter the number of days"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                        const decimalIndex = e.target.value.indexOf(".");
                        if (decimalIndex !== -1) {
                          const decimalPart = e.target.value.substring(
                            decimalIndex + 1
                          );
                          if (decimalPart.length > 2) {
                            e.target.value = e.target.value.slice(
                              0,
                              decimalIndex + 3
                            );
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="employee-compensationLeave">
                  <label>Reason</label>
                  <textarea
                    name="reason"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Enter Reason"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>
              <div className="compenstation-leave-btn">
                <div className="button-group">
                  <Button className="save-button" type="submit">
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                    className="exit-button"
                  >
                    Cancel
                  </Button>
                </div>
                <Snackbar
                  open={showSnackbar}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}
                >
                  <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    sx={{
                      width: "100%",
                      backgroundColor: "green",
                      color: "white",
                    }}
                  >
                    Leave allocated successfully
                  </Alert>
                </Snackbar>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompensationLeave;
