import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert, Button, Snackbar } from "@mui/material";
import { EMPLOYEE_API } from "../../../constants/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../global/Title/Title";
import Select from "react-select";
import axios from "../../../axios/axios";
import "../CreateEmployee/CreateEmployee.css";

function AddLeaveAllocation() {
  const navigate = useNavigate();
  const location = useLocation();
  const collapsed = useSelector((state) => state.sidebar.collapsed);

  const [leaves, setLeaves] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [leaveAllocationData, setLeaveAllocationData] = useState({});
  const [showSnackbar, setShowSnackbar] = useState(false);

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const { firstName, lastName, employeeId } = location.state;

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i <= 2030; i++) {
    years.push({ label: i, value: i });
  }

  useEffect(() => {
    if (employeeId) {
      axios
        .post(
          `/employee/employee_details/${employeeId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${logInToken}`,
              UserId: `${userId}`,
            },
          }
        )
        .then((response) => {
          setEmployeeDetails(response.data[0]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [logInToken, userId, employeeId]);

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleEmployeeSelectDate = (selectedDateOption) => {
    if (selectedDateOption) {
      setSelectedDate(selectedDateOption);

      setLeaves([]);
      setLeaveAllocationData([]);

      // If an employee is selected, make the API call here
      if (employeeId) {
        const year = selectedDateOption.value;
        axios
          .post(
            EMPLOYEE_API.ALLOCATE_LEAVE,
            { employeeId, year },
            {
              headers: {
                Authorization: `Bearer ${logInToken}`,
                UserId: `${userId}`,
              },
            }
          )
          .then((response) => {
            setLeaves(response.data);
          })
          .catch((error) => {
            console.error("Error submitting leave allocation:", error);
          });
      }
    } else {
      setSelectedDate(null);
    }
  };

  const handlesubmit = (e) => {
    e.preventDefault();

    if (employeeId && selectedDate) {
      const year = selectedDate.value;

      const allocations = Object.keys(leaveAllocationData).map((leaveName) => ({
        leaveName,
        days: leaveAllocationData[leaveName].value,
        leaveTypeId: leaveAllocationData[leaveName].id,
      }));

      const dataToSubmit = {
        employeeId,
        year,
        allocations,
      };

      axios
        .post(
          `${EMPLOYEE_API.ALLOCATE_LEAVE_SAVE}${employeeId}`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${logInToken}`,
              UserId: `${userId}`,
            },
          }
        )
        .then((response) => {
          setShowSnackbar(true);
          navigate("/employee/list");
        })
        .catch((error) => {
          console.error("Error submitting leave allocation:", error);
        });
    } else {
      console.error("Please select an employee and a year before submitting.");
    }
  };

  return (
    <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
      <div className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`} />

      <div className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}>
        <div className="BU-page">
          <Title text="Leave Allocation" propClass="pb-5" />

          <div className="create-BU-area">
            <form onSubmit={handlesubmit}>
              <div className="employee-personal-data">
                <div className="employee-leave-add">
                  <div>
                    {firstName}
                    {lastName}
                  </div>
                  <Select
                    options={years}
                    value={selectedDate}
                    onChange={handleEmployeeSelectDate}
                    placeholder="year"
                  />
                </div>
                {employeeDetails && (
                  <div className="employee-data">
                  </div>
                )}
              </div>

              <div className="employee-personal-leave-data">
                {leaves?.map((data, id) => (
                  <div className="checkbox-list" key={id}>
                    <div className="employee-leave-input">
                      <label>{data?.leaveName}</label>
                      <input
                        type="text"
                        defaultValue={data.leaveAllocated || "0"}
                        name={data.leaveName}
                        value={leaveAllocationData[data.leaveName]?.value}
                        placeholder="Enter the number of days"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9.]/g,
                            ""
                          );
                          const decimalIndex = e.target.value.indexOf(".");
                          if (decimalIndex !== -1) {
                            const decimalPart = e.target.value.substring(
                              decimalIndex + 1
                            );
                            if (decimalPart.length > 2) {
                              e.target.value = e.target.value.slice(
                                0,
                                decimalIndex + 3
                              );
                            }
                          }
                        }}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          const leaveName = data.leaveName;
                          const leaveId = data.id;

                          setLeaveAllocationData((prevData) => ({
                            ...prevData,
                            [leaveName]: {
                              id: leaveId,
                              value: newValue,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ padding: "5px 1rem" }}>
                <Button className="BU-submit-button" type="submit">
                  Save
                </Button>
                <Snackbar
                  open={showSnackbar}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}
                >
                  <Alert
                    onClose={handleSnackbarClose}
                    variant
                    severity="sucess"
                    sx={{
                      width: "100%",
                      backgroundColor: "green",
                      color: "white",
                    }}
                  >
                    Leave allocated successfully
                  </Alert>
                </Snackbar>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddLeaveAllocation;
