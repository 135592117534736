import React from "react";
import Layout from "../Layout/Layout";
import EmployeeAttendanceReport from "../../components/Attendance/EmployeeAttendanceReport/EmployeeAttendanceReport";

function AttendanceReportPage() {
  return (
    <Layout>
      <EmployeeAttendanceReport />
    </Layout>
  );
}

export default AttendanceReportPage;
