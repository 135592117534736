import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BiSolidPencil } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { UserDataContext } from "../../../context/UserContext";
import { BU_API } from "../../../constants/constants";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Skeleton,
  IconButton,
  Tooltip,
} from "@mui/material";
import axios from "../../../axios/axios";
import ConfirmationModal from "../../../components/global/model/ConfirmationModal";
import Title from "../../../components/global/Title/Title";
import Search from "../../../components/global/Search/Search";
import "../BULIST/BUList.css";
import Layout from "../../Layout/Layout";

function BUList() {
  const navigate = useNavigate();
  const [leaves, setLeaves] = useState([]);
  const [filteredLeaves, setFilteredLeaves] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const collapsed = useSelector((state) => state.sidebar.collapsed);

  const { userData } = useContext(UserDataContext);
  const employeeRoleId = userData.employeeRoleId;

  useEffect(() => {
    axios
      .post(
        BU_API.LIST_BU,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setLeaves(response.data);
        setFilteredLeaves(response.data); 
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("error", error);
      });
  }, [logInToken, userId]);

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchText(searchTerm);

    // Filter the leaves based on the search text
    const filteredLeaves = leaves.filter((leaf) => {
      const nameMatch = leaf.buName
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());
      const countryMatch = leaf.country
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());
      const addressMatch = leaf.address
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());
      const zipcodeMatch = leaf.zipcode
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());

      return nameMatch || countryMatch || addressMatch || zipcodeMatch;
    });

    setFilteredLeaves(filteredLeaves);
  };

  const openConfirmationModal = (buId) => {
    setIsConfirmationModalOpen(true);
    setItemToDelete(buId);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirmed = () => {
    handleremove(itemToDelete);
    closeConfirmationModal();
  };

  const handleremove = (buId) => {
    axios
      .post(
        BU_API.DELETE_BU,
        { buId },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        const updatedLeaves = leaves.filter((leaf) => leaf.id !== buId);
        setLeaves(updatedLeaves);
        setFilteredLeaves(updatedLeaves);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  return (
    <Layout>
      <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
        <div
          className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
        />

        <div
          className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
        >
          <div className="BU-page">
            <Title text="BU List" propClass="pb-10" />

            <div className="table-area">
              <Search SearchStyle={{width: "35%"}} searchValue={searchText} handleSearch={handleSearch} />
              
              <TableContainer
                sx={{
                  marginTop: "2rem",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  maxHeight: "60vh",
                }}
                className="table_container"
                component={Paper}
              >
                <Table stickyHeader aria-label="sticky table" className="table">
                  <TableHead>
                    <TableRow>
                      <TableCell>SN</TableCell>
                      <TableCell>BU</TableCell>
                      <TableCell>Country</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Contact Number</TableCell>
                      {employeeRoleId === "1" ? (
                        <TableCell>Action</TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                        {employeeRoleId === "1" ? (
                          <TableCell>
                            <Skeleton animation="wave" height={48} />
                          </TableCell>
                        ) : (
                          <></>
                        )}
                      </TableRow>
                    ) : (
                      filteredLeaves
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((leaf, index) => (
                          <TableRow key={leaf?.id}>
                            <TableCell>
                              {index + 1 + page * rowsPerPage}
                            </TableCell>
                            <TableCell>{leaf?.buName}</TableCell>
                            <TableCell>{leaf?.country}</TableCell>
                            <TableCell
                              sx={{
                                width: "30%",
                              }}
                            >
                              {leaf?.address},{leaf?.zipcode}
                            </TableCell>
                            <TableCell>{leaf?.buContactNumber}</TableCell>
                            {employeeRoleId === "1" ? (
                              <TableCell>
                                <>
                                  <Tooltip title="Edit" arrow>
                                    <IconButton
                                      onClick={() => {
                                        navigate(`/bu/edit/${leaf.id}`);
                                      }}
                                    >
                                      <BiSolidPencil
                                        style={{
                                          width: "26px",
                                          height: "22px",
                                          cursor: "pointer",
                                        }}
                                        className="edit-button"
                                      />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip title="Delete" arrow>
                                    <IconButton
                                      onClick={() =>
                                        openConfirmationModal(leaf.id)
                                      }
                                    >
                                      <MdDeleteOutline
                                        style={{
                                          width: "26px",
                                          height: "22px",
                                          cursor: "pointer",
                                        }}
                                        className="edit-button"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              </TableCell>
                            ) : (
                              <></>
                            )}
                          </TableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                sx={{
                  overflow: "hidden",
                }}
                component="div"
                count={filteredLeaves.length}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onConfirm={handleDeleteConfirmed}
        onCancel={closeConfirmationModal}
      />
    </Layout>
  );
}

export default BUList;
