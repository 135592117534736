import React, { useContext, useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { HiOutlineMail } from "react-icons/hi";
import { LuLock } from "react-icons/lu";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToken } from "../../redux/actions";
import { AUTH_API } from "../../constants/constants";
import { UserDataContext } from "../../context/UserContext";
import stratagile from "../../assets/images/StratAgile.png";
import axios from "../../axios/axios";
import "../../assets/css/login.css";
import { LoadingButton } from "@mui/lab";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formError, setFormError] = useState({ email: "", password: "" });
  const [statusError, setStatusError] = useState("");
  const [loading, setLoading] = useState(false);

  const { updateUserData } = useContext(UserDataContext);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleInputChange = () => {
    setStatusError("");
    setFormError("");
  };

  const validateForm = () => {
    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.?\S*$/.test(email.trim())) {
      errors.email = "Invalid email address";
    }

    if (!password) {
      errors.password = "Password is required";
    }

    setFormError(errors);
    setStatusError(null);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await axios.post(
          AUTH_API.USER_LOGIN,
          {
            password: password,
            email: email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const logInToken = response.data.logInToken;
          const userId = response.data.userId;
          dispatch(setToken({ logInToken, userId }));
          localStorage.setItem("logInToken", logInToken);
          localStorage.setItem("userId", userId);

          const employeeData = await getEmployeeRoleId(userId, logInToken);

          updateUserData({
            userName: employeeData.employeeName,
            employeeRoleId: employeeData.employeeRoleId,
            employeeBuId: employeeData.employeeBuID,
          });
          setLoading(false);
          navigate("/");
        } else {
          console.error("Login failed.");
        }
      } catch (error) {
        if (
          error.response ||
          error.response.status === 404 ||
          error.response.status === 401
        ) {
          setStatusError(error.response.data.userMessage);
          console.error("Resource not found:", error.response.data);
          setLoading(false);
        } else {
          console.error("An error occurred:", error);
        }
      }
    }
  };

  const getEmployeeRoleId = async (userId, logInToken) => {
    try {
      const response = await axios.post(
        AUTH_API.GET_USER_ROLE_ID,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            userId: `${userId}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error occurred:", error);
      throw error;
    }
  };

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="login-logo">
          <img src={stratagile} alt="StratAgile" />
        </div>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="login-user-input">
            <HiOutlineMail style={{ width: "26px", height: "22px" }} />
            <input
              type="text"
              placeholder="Email address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                handleInputChange();
              }}
            />
          </div>
          <div className="error-message">{formError.email}</div>
          <div className="login-user-input">
            <LuLock style={{ width: "26px", height: "22px" }} />
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                handleInputChange();
              }}
            />
            {passwordVisible ? (
              <IconButton onClick={togglePasswordVisibility}>
                <AiOutlineEyeInvisible />
              </IconButton>
            ) : (
              <IconButton onClick={togglePasswordVisibility}>
                <AiOutlineEye />
              </IconButton>
            )}
          </div>
          <div className="error-message">
            {statusError}
            {formError.password}
          </div>
          <div className="login-button-area">
            <LoadingButton
              disabled={loading}
              loading={loading}
              className="login-button"
              type="submit"
            >
              {loading ? <CircularProgress size={20} /> : "Sign In"}
            </LoadingButton>

            <p
              onClick={() => {
                navigate("/forgot-password");
              }}
              className="fzr-09 forgot-password-link"
            >
              Forgot password?
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
