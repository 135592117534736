import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import userprofile from "../../assets/images/user.png";

import { MdOutlineLocationOn, MdOutlineMail } from "react-icons/md";
import { LuPhone } from "react-icons/lu";
import { AiOutlineSkype } from "react-icons/ai";
import { useContext } from "react";
import { UserDataContext } from "../../context/UserContext";
import { PROFILE_API } from "../../constants/constants";
import "../../assets/css/Profile.css";
import Layout from "../Layout/Layout";
import LoadingIndicator from "../../components/global/Loading/LoadingIndicator";

function UserProfile() {
  const [userProfileData, setUserProfileData] = useState([]);
  const [loading, setloading] = useState(true);

  const { userData } = useContext(UserDataContext);
  const employeeRoleId = userData.employeeRoleId;

  const collapsed = useSelector((state) => state.sidebar.collapsed);

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    axios
      .post(
        `${PROFILE_API.GET_USER_PROFILE}${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setUserProfileData(response.data[0]);
        setloading(false);
      })
      .catch((error) => {
        console.error(error);
        setloading(false);
      });
  }, [logInToken, userId]);

  return (
    <Layout>
      <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
        <div
          className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
        />

        <div
          className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
        >
          <div className="BU-page">
            {loading ? (
              <LoadingIndicator />
            ) : employeeRoleId === "1" ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  opacity: "0.7",
                  fontSize: "1.8rem",
                  height: "80vh",
                }}
              >
                No profile available for Admin user !
              </span>
            ) : (
              <div className="user_profile_page_area">
                <div className="user_profile_left_card shadow">
                  <div className="profile_main_details">
                    {userProfileData.profilePicture === null ? (
                      <div
                        className="profile_image shadow"
                        style={{
                          backgroundImage: `url(${userprofile})`,
                        }}
                      />
                    ) : (
                      <div
                        className="profile_image shadow"
                        style={{
                          backgroundImage: `url(${
                            userProfileData?.profilePicture || userprofile
                          })`,
                        }}
                      />
                    )}

                    <div style={{ textAlign: "center" }}>
                      <h1 className="fzr-15">
                        {userProfileData?.employeeName
                          ? userProfileData?.employeeName
                          : "No Name"}
                      </h1>
                      <p>
                        {userProfileData?.designationName
                          ? userProfileData?.designationName
                          : "No Designation"}
                      </p>
                      <p className="user_employee_id">
                        {userProfileData?.employeeId
                          ? userProfileData?.employeeId
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div className="profile_details">
                    <div className="profile_details_items">
                      <MdOutlineLocationOn size={"1rem"} opacity={0.7} />
                      <p>
                        {userProfileData?.buName
                          ? userProfileData?.buName
                          : "No buName"}
                      </p>
                    </div>
                    <div className="profile_details_items">
                      <MdOutlineMail size={"1rem"} opacity={0.7} />
                      <p>
                        {userProfileData?.workEmail
                          ? userProfileData?.workEmail
                          : "No email"}
                      </p>
                    </div>
                    <div className="profile_details_items">
                      <LuPhone size={"1rem"} opacity={0.7} />
                      <p>
                        {userProfileData?.phoneNumber
                          ? userProfileData?.phoneNumber
                          : "No phone number"}
                      </p>
                    </div>
                    <div className="profile_details_items">
                      <AiOutlineSkype size={"1rem"} opacity={0.7} />
                      <p>
                        {userProfileData?.skypeId
                          ? userProfileData.skypeId
                          : "No Skype ID "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="user_profile_right_card">
                  <div className="user_profile_page_area">
                    <div className="profile_office_card shadow">
                      <div className="user_comapany_details">
                        <p className="user_comapany_details_head">Department</p>
                        <p className="user_comapany_details_data">
                          {userProfileData?.departmentName
                            ? userProfileData?.departmentName
                            : "No data"}
                        </p>
                      </div>
                    </div>
                    <div className="profile_office_card shadow">
                      <div className="user_comapany_details">
                        <p className="user_comapany_details_head">
                          Reporting To
                        </p>
                        <p className="user_comapany_details_data">
                          {userProfileData?.reportingToName
                            ? userProfileData?.reportingToName
                            : "No data"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="profile_address_card shadow">
                    <div className="user_comapany_details">
                      <p className="user_comapany_details_head">
                        Personal Address
                      </p>
                      <p className="user_personal_details_data">
                        {userProfileData?.permanentAddress
                          ? userProfileData?.permanentAddress
                          : "No data"}
                      </p>
                    </div>
                    <div className="user_comapany_details">
                      <p className="user_comapany_details_head">
                        Emergency Contact Name
                      </p>
                      <p className="user_personal_details_data">
                        {userProfileData?.EmergencyContactName
                          ? userProfileData?.EmergencyContactName
                          : "No data"}
                      </p>
                    </div>
                    <div className="user_comapany_details">
                      <p className="user_comapany_details_head">
                        Emergency Contact Number
                      </p>
                      <p className="user_personal_details_data">
                        {userProfileData?.emergencyContactNumber
                          ? userProfileData?.emergencyContactNumber
                          : "No data"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default UserProfile;
