import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Skeleton,
} from "@mui/material";
import axios from "../../../axios/axios";
import "./LoginHistory.css";
import { AUTH_API } from "../../../constants/constants";
import Layout from "../../Layout/Layout";
import Title from "../../../components/global/Title/Title";
// import Select from "react-select";

function LoginHistory() {
  const [loginLog, setLoginLog] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [selectedDate, setSelectedDate] = useState(null);

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const collapsed = useSelector((state) => state.sidebar.collapsed);

  // const currentYear = new Date().getFullYear();
  // const years = [];
  // for (let i = currentYear; i <= 2030; i++) {
  //   years.push({ label: i, value: i });
  // }

  useEffect(() => {
    axios
      .post(
        AUTH_API.USER_LOGIN_HISTORY,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setLoginLog(response.data.loginDetails);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("error", error);
      });
  }, [logInToken, userId]);

  // const handleLoginSelectDate = (selectDate) => {
  //   if (selectDate) {
  //     setSelectedDate(selectDate);
  //   } else {
  //     setSelectedDate(null);
  //   }
  // }

  return (
    <Layout>
      <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
        <div
          className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
        />

        <div
          className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
        >
          <div className="BU-page">
            <Title text="Login History" propClass="pb-10" />

            {/* <Select
              options={years}
              value={selectedDate}
              onChange={handleLoginSelectDate}
              placeholder="year"
              styles={{
                control: (provided) => ({
                  ...provided,
                  height: 55,
                  margin: "5px 0",
                }),
              }}
            /> */}
            <div className="table-area">
              <TableContainer
                sx={{
                  marginTop: "2rem",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  maxHeight: "65vh",
                }}
                className="table_container"
                component={Paper}
              >
                <Table stickyHeader aria-label="sticky table" className="table">
                  <TableHead>
                    <TableRow>
                      <TableCell>SN</TableCell>
                      <TableCell>IP Address</TableCell>
                      <TableCell>Date & Time</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" height={48} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      loginLog
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((leaf, index) => (
                          <TableRow key={leaf?.id}>
                            <TableCell>
                              {index + 1 + page * rowsPerPage}
                            </TableCell>
                            <TableCell>{leaf?.ipAddress}</TableCell>
                            <TableCell>{leaf?.attemptedOn}</TableCell>
                            <TableCell
                              sx={{
                                color:
                                  leaf?.loginStatus === "Success"
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {leaf?.loginStatus}
                            </TableCell>
                          </TableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                sx={{
                  overflow: "hidden",
                }}
                component="div"
                count={loginLog.length}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default LoginHistory;
