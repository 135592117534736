import React from "react";

function TodayLate() {
  return (
    <svg
      width="30"
      height="27"
      viewBox="0 0 60 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.886 2.00057L20.7124 2.05594L20.8523 2.17263C21.3933 2.62392 21.8941 3.11852 22.3493 3.65117L22.3908 3.69975L22.4523 3.68249C24.8405 3.01254 27.3626 2.64622 29.9751 2.64622C44.8764 2.64622 56.9302 14.3239 56.9302 28.7469C56.9302 43.17 44.8764 54.8476 29.9751 54.8476C15.0738 54.8476 3.02006 43.17 3.02006 28.7469C3.02006 26.0087 3.4559 23.3702 4.26159 20.8914L4.28236 20.8274L4.23102 20.784C3.69355 20.33 3.19652 19.833 2.74519 19.2983L2.63418 19.1668L2.57489 19.3284C1.4937 22.2746 0.899786 25.4434 0.899786 28.7469C0.899786 44.2914 13.9317 56.9073 29.9751 56.9073C46.0186 56.9073 59.0505 44.2914 59.0505 28.7469C59.0505 13.2024 46.0186 0.586584 29.9751 0.586584C26.8004 0.586584 23.747 1.08775 20.886 2.00057Z"
        fill="#DA1F26"
        stroke="#DA1F26"
        stroke-width="0.2"
      />
      <path
        d="M29.3352 10.6641V26.0799C28.7133 26.2199 28.1585 26.5595 27.7611 27.0435C27.3637 27.5275 27.1469 28.1275 27.1462 28.7458C27.1463 29.4724 27.4445 30.1692 27.975 30.683C28.5055 31.1968 29.225 31.4855 29.9753 31.4856C30.6034 31.4848 31.2133 31.2816 31.709 30.9081C32.2047 30.5346 32.558 30.012 32.7132 29.4226H48.3679V28.0691H32.7145C32.586 27.5832 32.3224 27.1409 31.9523 26.7901C31.5822 26.4394 31.1197 26.1937 30.6154 26.0799V10.6737H29.4353C29.401 10.6737 29.369 10.6657 29.3352 10.6641Z"
        fill="#DA1F26"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.131 0.400024C6.31462 0.400024 1.58568 4.97963 1.58568 10.6123C1.58568 16.245 6.31462 20.8246 12.131 20.8246C17.9474 20.8246 22.6763 16.245 22.6763 10.6123C22.6763 4.97963 17.9474 0.400024 12.131 0.400024ZM12.131 1.64953C17.2501 1.64953 21.3861 5.65489 21.3861 10.6123C21.3861 15.5698 17.2501 19.5751 12.131 19.5751C7.01189 19.5751 2.87592 15.5698 2.87592 10.6123C2.87592 5.65489 7.01189 1.64953 12.131 1.64953ZM12.131 2.10296C10.9771 2.10294 9.83447 2.32303 8.76839 2.75066C7.70231 3.17829 6.73365 3.80508 5.91771 4.59525C5.10177 5.38543 4.45454 6.3235 4.01296 7.35591C3.57139 8.38833 3.34413 9.49486 3.34415 10.6123C3.34413 11.7298 3.57139 12.8363 4.01296 13.8688C4.45454 14.9012 5.10177 15.8392 5.91771 16.6294C6.73365 17.4196 7.70231 18.0464 8.76839 18.474C9.83447 18.9016 10.9771 19.1217 12.131 19.1217C13.2849 19.1217 14.4275 18.9016 15.4936 18.474C16.5597 18.0464 17.5283 17.4196 18.3443 16.6294C19.1602 15.8392 19.8075 14.9012 20.249 13.8688C20.6906 12.8363 20.9179 11.7298 20.9178 10.6123C20.9179 9.49486 20.6906 8.38833 20.249 7.35591C19.8075 6.3235 19.1602 5.38543 18.3443 4.59525C17.5283 3.80508 16.5597 3.17829 15.4936 2.75066C14.4275 2.32303 13.2849 2.10294 12.131 2.10296ZM10.3734 4.79921H13.8885V12.7789H10.3734V4.79921ZM10.3734 13.8892H13.8885V16.4255H10.3734V13.8892Z"
        fill="#DA1F26"
      />
    </svg>
  );
}

export default TodayLate;
