import React from "react";
import "./NotFound.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="page_404">
      <div className="page_404_container">
        <div className="notfound-title fzr-175">Sorry, Page Not Found!</div>
        <p className="notfount-para">
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
          mistyped the URL? Be sure to check your spelling.
        </p>
        <div className="contant_box_404">
          <Button
            onClick={() => {
              navigate("/");
            }}
            className="link_404"
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
