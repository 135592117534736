import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EMPLOYEE_API } from "../../../constants/constants";
import {
  createAlumniList,
  updateAlumniList,
} from "../../../redux/alumniReducer";
import axios from "../../../axios/axios";
import Title from "../../global/Title/Title";
import EmployeeFilterComponent from "../UpdatedEmployeeList/EmployeeFilterComponent";
import EmployeeCard from "../UpdatedEmployeeList/EmployeeCard";
import ConfirmationModal from "../../global/model/ConfirmationModal";
import LoadingIndicator from "../../global/Loading/LoadingIndicator";

function Alumni() {
  const collapsed = useSelector((state) => state.sidebar.collapsed);
  const [loading, setLoading] = useState(true);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(null);
  const [searchFilteredAlumni, setSearchFilteredAlumni] = useState([]);

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const dispatch = useDispatch();

  const alumniList = useSelector((state) => state.alumni.alumni);

  const [searchTerm, setSearcTerm] = useState("");

  const handleFilterSearch = (searchTerm) => {
    setSearcTerm(searchTerm);
    if (searchTerm === "Department") {
      setSearchFilteredAlumni(alumniList);
      return;
    }

    const filteredLeaves = alumniList.filter((data) => {
      const alumniNameMatch = data.employeeName
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());
      const buNameMatch = data.buName
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());
      const designationNameMatch = data.designationName
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase());

      return alumniNameMatch || buNameMatch || designationNameMatch;
    });
    setSearchFilteredAlumni(filteredLeaves);
  };

  useEffect(() => {
    setSearchFilteredAlumni(alumniList);
  }, [alumniList]);

  const handleDeleteConfirmed = () => {
    axios
      .post(
        EMPLOYEE_API.DELETE_EMPLOYEE,
        { employeeId: isConfirmationModalOpen },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        dispatch(updateAlumniList(isConfirmationModalOpen));

        setConfirmationModalOpen(null);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const handleDeleteClicked = (id) => {
    setConfirmationModalOpen(id);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(null);
  };

  useEffect(() => {
    axios
      .post(
        EMPLOYEE_API.LIST_ALUMIN,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        dispatch(createAlumniList(response.data));
        setLoading(false);
      })
      .catch((error) => {
        console.error("error", error);
        setLoading(false);
      });
  }, [logInToken, userId, dispatch]);

  return (
    <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
      <div className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`} />

      <div className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}>
        <div className="BU-page">
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Title text="Alumni List" propClass="pb-5" />

              <EmployeeFilterComponent
                searchText={searchTerm}
                handleFilterSearch={handleFilterSearch}
              />
              <div className="BU-page_cards">
                {searchFilteredAlumni?.length === 0 && (
                  <p className="no-data_message fz-12 p-relative">
                    No data to dispaly
                  </p>
                )}
                {searchFilteredAlumni?.map((obj) => (
                  <EmployeeCard
                    key={obj?.id}
                    id={obj?.id}
                    buName={obj?.buName}
                    employeeId={obj?.employeeId}
                    designation={obj?.designationName}
                    employeeName={obj?.employeeName}
                    workEmail={obj?.workEmail}
                    skypeId={obj?.skypeId}
                    employeeProfilePicture={obj?.employeeProfilePicture}
                    handleDeleteClicked={handleDeleteClicked}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onConfirm={handleDeleteConfirmed}
        onCancel={closeConfirmationModal}
      />
    </div>
  );
}

export default Alumni;
