import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EMPLOYEE_API } from "../../../constants/constants";
import axios from "../../../axios/axios";
import Search from "../../global/Search/Search";
import Dropdown from "../../global/Dropdown/Dropdown";
import "./EmployeeCard.css";

export default function EmployeeFilterComponent({
  searchText,
  handleFilterSearch,
  filterWithDesignation,
}) {
  const location = useLocation();
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");
  const [departmentData, setDepartmentData] = useState([]);
  const [searchTerm, setSearchsearchTerm] = useState(
    searchText?.length === 0 ? searchText : ""
  );
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setSearchsearchTerm(searchText);
  }, [searchText]);

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchsearchTerm(searchTerm);
    handleFilterSearch(searchTerm);
  };

  const onChangeDesignation = (data) => {
    console.log("filter changed" , data);
    filterWithDesignation(data?.label);
  };

  const designationOption = departmentData
    .filter((data) =>
      data.designationName?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
    .map((data) => ({
      value: data?.id,
      label: data?.designationName,
    }));

  useEffect(() => {
    axios
      .post(
        EMPLOYEE_API.LIST_DESIGNATIONS,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [logInToken, userId]);

  return (
    <div className="BU-page_filter BU-filter">
      <Search SearchStyle={{width: "35%"}} searchValue={searchTerm} handleSearch={handleSearch} />
      {location.pathname === "/employee/list" && (
        <div className="select-tab ">
          <Dropdown
            optionsList={designationOption}
            onSelect={onChangeDesignation} // geting selected value from onselect
            isClearable={true}
            placeholder="Designation"
            customDropdownClass="dropdown-width"
          />
        </div>
      )}
    </div>
  );
}
