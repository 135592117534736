import React, { useRef, useState , useEffect , useContext } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { AiOutlineMail, AiOutlineSkype } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";
import { UserDataContext } from "../../../context/UserContext";
import DropDown from "./DropDown";
import userIcon from "../../../assets/images/user.png";
import "./EmployeeCard.css";

export default function EmployeeCard({
  buName,
  designation,
  employeeName,
  employeeId,
  workEmail,
  skypeId,
  employeeProfilePicture,
  id,
  handleDeleteClicked,
  isConfirmationModalOpen,
}) {
  const [dropdownVisible, setDropDownVisible] = useState(null);
  const dropdownRef = useRef(null);
  const { userData } = useContext(UserDataContext);
  const employeeRoleId = userData.employeeRoleId;

  const handleDropDownClick = () => {
    setDropDownVisible(id);
  };

  const handleSpanClick = () => {
    setDropDownVisible(null);
  };
  useEffect(() => {
    if (isConfirmationModalOpen !== null) {
      setDropDownVisible(null);
    }
  }, [isConfirmationModalOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropDownVisible(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="employee-card ">
      {employeeRoleId === "4" || employeeRoleId === "2" ? (
        <></>
      ) : (
        <div className="dropdown">
          <div
            className="employee-card_dropdown"
          >
            {!dropdownVisible && (
              <IconButton onClick={handleDropDownClick}>
                <BiDotsVerticalRounded size={"1.5rem"} opacity={0.7} />
              </IconButton>
            )}
          </div>
          <>
            {dropdownVisible === id && (
              <div className="dropdown_container" ref={dropdownRef}>
                <DropDown
                  handleDeleteClicked={handleDeleteClicked}
                  id={id}
                />
                <span className="verticle_round">
                  <IconButton onClick={handleSpanClick}>
                    <BiDotsVerticalRounded size={"1.5rem"} opacity={0.7} />
                  </IconButton>
                </span>
              </div>
            )}
          </>
        </div>
      )}

      <div className="card_header">
        {employeeProfilePicture === null ? (
          <div
            className="employee-card_img shadow"
            style={{
              backgroundImage: `url(${userIcon})`,
            }}
          />
        ) : (
          <img
            className="employee-card_img shadow"
            src={employeeProfilePicture}
            loading="lazy"
            alt="profilepicture"
          />
        )}
        <div className="card_header-content">
          <h2 className="employee-card_h2">{employeeName}</h2>
          <p className="employee-card_p designation">{designation}</p>
          <div className="employee_details-mobile">
          <div className="content">
            <IoLocationOutline size={"1rem"} opacity={0.7} />
            <p className="content_p">{buName}</p>
          </div>
          <div className="content">
            <AiOutlineMail size={"1rem"} opacity={0.7} />
            <p className="content_p">{workEmail}</p>
          </div>
          {skypeId && (
            <div className="content">
              <AiOutlineSkype size={"1rem"} opacity={0.7} />
              <p className="content_p">{skypeId}</p>
            </div>
          )}
        </div>
        </div>
      </div>
      <div className="details">
        <div className="details_helper"></div>
        <div className="employee_details">
          <div className="content">
            <IoLocationOutline size={"1rem"} opacity={0.7} />
            <p className="content_p">{buName}</p>
          </div>
          <div className="content">
            <AiOutlineMail size={"1rem"} opacity={0.7} />
            <p className="content_p">{workEmail}</p>
          </div>
          {skypeId && (
            <div className="content">
              <AiOutlineSkype size={"1rem"} opacity={0.7} />
              <p className="content_p">{skypeId}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
