import React from "react";
import { ErrorExclamation } from "../svg/Icons";
import "./ErrorPopup.css";

export default function ErrorPopup({ data }) {
  return (
    <div className="Error_popup-container">
      <div className="Error_popup">
        <div className="Error_popup-title">
          <ErrorExclamation />
          <p>error</p>
        </div>
        {Object?.values(data).map((value, index) => {
          if (
            index > 0 &&
            value !== null &&
            value !== false &&
            value !== undefined &&
            value !== ""
          ) {
            return (
              <p className="error_mesg" key={index}>
                {value}
              </p>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}
