import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { WORKING_DAY_API } from "../../../constants/constants";
import axios from "../../../axios/axios";
import Title from "../../../components/global/Title/Title";
import Calender from "../../../components/Calender/Calender";
import Layout from "../../Layout/Layout";

function WorkingDayCalender() {
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");
  const [data, setData] = useState([]);

  const collapsed = useSelector((state) => state.sidebar.collapsed);

  const currentDate = new Date();
  const currentYearIndex = currentDate.getFullYear();
  const currentMonthName = currentDate.toLocaleString("default", {
    month: "long",
  });

  const [selectedValue, setSelectedValue] = useState(currentYearIndex);

  const options = 2;
  const yearOptions = Array.from({ length: options }, (_, index) => {
    const year = currentYearIndex + index;
    return { value: year, label: year.toString() };
  });

  useEffect(() => {
    axios
      .post(
        WORKING_DAY_API.GET_WORKING_DAY_CALENDER,
        { year: selectedValue },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setData(response.data.specialWorkingDays);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [selectedValue, logInToken, userId]);

  return (
    <Layout>
      <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
        <div
          className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
        />

        <div
          className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
        >
          <div className="BU-page">
            <div className="calender-year_select">
              <div>
                <Title text="Special Working Days" propClass="pb-10" />
              </div>
              <div className="year_select">
                <select
                  value={selectedValue}
                  defaultValue={currentYearIndex}
                  onChange={(e) => setSelectedValue(e.target.value)}
                >
                  {yearOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Calender
              CalenderCheck={"1"}
              currentMonthName={currentMonthName}
              currentYearIndex={currentYearIndex}
              data={data}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default WorkingDayCalender;
