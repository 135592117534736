import React from "react";
import CustomDatePicker from "../../global/Datepicker/CustomDatePicker";
import SingleSelect from "../../global/Select/SingleSelect";

export default function DateNSession({
  placeholder,
  Date,
  setDate,
  className,
  options,
  handleSesssionSelect,
  minSelectableDate,
}) {
  return (
    <div className="date-and-session">
      <div className="calender-section">
        <CustomDatePicker
          className={`${className}`}
          placeholderText={`${placeholder}`}
          Date={Date}
          setDate={setDate}
          minDate={minSelectableDate}
        />
      </div>
      <div className="select-section">
        <SingleSelect
          selectOptions={options}
          placeholder={"Session"}
          isMulty={false}
          handleSelection={handleSesssionSelect}
        />
      </div>
    </div>
  );
}
