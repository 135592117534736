import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "../sidebarSlice";
import authReducer from "../authReducer";
import employeeReducer from "../employeeReducer";
import alumniReducer from "../alumniReducer";

const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    auth: authReducer,
    employee: employeeReducer,
    alumni: alumniReducer,
  },
});

export default store;
