import React from "react";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import Footer from "../../components/Footer/Footer";
import {
  toggleCollapsedOnDesk,
  togglecollapsedOnMob,
} from "../../redux/sidebarSlice";

function Layout({ children }) {
  return (
    <div className="d-flex">
      <div>
        <div className="desktopview-sidebar">
          <SideBar collapse={toggleCollapsedOnDesk} />
        </div>
        <div className="mobileview-sidebar">
          <SideBar collapse={togglecollapsedOnMob} />
        </div>
      </div>
      <div className="w-100">
        <Header collapse={togglecollapsedOnMob} />
        {children}
        <Footer />
      </div>
    </div>
  );
}

export default Layout;