import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "../../../context/UserContext";
import { AUTH_API, BU_API, HOLIDAY_API } from "../../../constants/constants";
import axios from "../../../axios/axios";
import Title from "../../../components/global/Title/Title";
import DatePicker from "react-datepicker";
import "./AddHoliday.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";
import Layout from "../../Layout/Layout";

function AddHoliday() {
  const navigate = useNavigate();
  const [bu, setBu] = useState([]);
  const [employyeBuId, setEmployyeBuId] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const collapsed = useSelector((state) => state.sidebar.collapsed);
  const [formData, setFormData] = useState({
    holidayName: "",
    holidayDate: "",
    buId: "",
  });
  const [errors, setErrors] = useState({});

  const { userData } = useContext(UserDataContext);
  const employeeRoleId = userData.employeeRoleId;

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  useEffect(() => {
    axios
      .post(
        AUTH_API.GET_USER_ROLE_ID,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setEmployyeBuId(response.data.employeeBuID);
        if (response.data.employeeRoleId !== "1") {
          setFormData((prevFormData) => ({
            ...prevFormData,
            buId: response.data.employeeBuID,
          }));
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [logInToken, userId]);

  useEffect(() => {
    axios
      .post(
        BU_API.LIST_BU,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setBu(response.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [logInToken, userId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!formData.holidayName || formData.holidayName?.trim() === "") {
      validationErrors.holidayName = "Name is required";
    } else if (
      !/^[A-Za-z0-9,.\s]+$/.test(formData.holidayName) ||
      /[()<>"?;'!#$%*]/.test(formData.holidayName)
    ) {
      validationErrors.holidayName =
        "Name should only contain letters, numbers, and spaces";
    }

    if (!formData.buId || formData.buId?.trim() === "") {
      validationErrors.buId = "BU is required";
    }

    formData.holidayDate = startDate;
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      axios
        .post(HOLIDAY_API.ADD_HOLIDAY, formData, {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        })
        .then(() => {
          navigate("/calendar/holiday/list");
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };

  return (
    <Layout>
      <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
        <div
          className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`}
        />

        <div
          className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}
        >
          <div className="BU-page">
            <Title text="Add Holidays" propClass="pb-5" />

            <div className="create-BU-area">
              <form onSubmit={handleSubmit}>
                <div className="BU-form-input">
                  <label htmlFor="name">
                    Name
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="holidayName"
                    value={formData.holidayName}
                    onChange={handleChange}
                    placeholder="Enter the name"
                  />
                  {errors.holidayName && (
                    <span className="error-message">{errors.holidayName}</span>
                  )}
                </div>

                <div className="BU-form-input">
                  <label htmlFor="Description">
                    Date
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  <DatePicker
                    className="custom-datepicker"
                    selected={startDate}
                    minDate={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Select a date"
                    dateFormat="dd - MM - yyyy"
                  />
                </div>

                <div className="BU-form-input">
                  <label htmlFor="BU">
                    BU
                    <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
                  </label>
                  {employeeRoleId === "1" ? (
                    <select
                      name="buId"
                      value={formData.buId}
                      onChange={handleChange}
                    >
                      <option value=""> Please Select the BU </option>
                      {bu?.map((bu, id) => (
                        <option key={id} value={bu.id}>
                          {bu?.buName}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select
                      name="buId"
                      value={formData.buId}
                      onChange={handleChange}
                    >
                      {bu?.map((buItem, id) => {
                        if (buItem.id === employyeBuId) {
                          return (
                            <option key={id} selected value={buItem.id}>
                              {buItem.buName}
                            </option>
                          );
                        }
                        return null;
                      })}
                    </select>
                  )}
                  {errors.buId && (
                    <span className="error-message">{errors.buId}</span>
                  )}
                  <div className="button-group">
                    <Button className="save-button" type="submit">
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      className="exit-button"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddHoliday;
