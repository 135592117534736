import React from "react";
import Layout from "../Layout/Layout";
import LeaveAllocation from "../../components/Employee/CreateEmployee/LeaveAllocation";

function EmployeeLeaveAllocation() {
  return (
    <Layout>
      <LeaveAllocation />
    </Layout>
  );
}

export default EmployeeLeaveAllocation;
