import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, useMediaQuery } from "@mui/material";
import { HiOutlineCalendar } from "react-icons/hi";
import { UserDataContext } from "../../context/UserContext";
import { useContext } from "react";
import { togglecollapsedOnMob } from "../../redux/sidebarSlice";

//icons
import {
  BUIcon,
  DashboardIco,
  DriveIco,
  EmployeeIco,
  LeaveIco,
  SettingsIco,
} from "../global/svg/Icons";
import { FaMailBulk, FaRegCalendarCheck } from "react-icons/fa";
import { MdAdminPanelSettings } from "react-icons/md";
import { CgMenuRightAlt } from "react-icons/cg";

import useTerminateSession from "../../hooks/useTerminateSession";
import stratagile from "../../assets/images/StratAgile.png";
import "./SideBar.css";

function SideBar({ collapse }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = useTerminateSession();

  const [subMenuCollapsed, setSubMenuCollapsed] = useState(false);

  const logInToken = localStorage.getItem("logInToken");

  //context data
  const { userData } = useContext(UserDataContext);
  const employeeRoleId = userData.employeeRoleId;

  const isMobileView = useMediaQuery("(max-width: 768px)"); // Adjust the breakpoint as needed

  const collapsed = useSelector((state) =>
    isMobileView ? state.sidebar.collapsedOnMob : state.sidebar.collapsedOnDesk
  );

  const toggleSubMenu = (menuName) => {
    setSubMenuCollapsed({
      ...subMenuCollapsed,
      [menuName]: !subMenuCollapsed[menuName],
    });
  };
  
  useEffect(() => {
    if (!logInToken || userData.employeeRoleId === "") {
      handleLogout();
    }
  }, [logInToken, userData.employeeRoleId, handleLogout]);


  return (
    <div
      className={`container ${!collapsed ? "sidebar-collapsed-mobile" : ""}`}
    >
      <Sidebar
        collapsed={collapsed}
        style={{
          minWidth: collapsed ? "60px" : "100%",
          width: collapsed ? "75px" : "220px",
          height: "100vh",
          zIndex: "8",
          backgroundColor: "#f4f6f8",
        }}
      >
        <div className="sidebar-logo">
          {!collapsed && (
            <img
              onClick={() => {
                navigate("/");
                dispatch(togglecollapsedOnMob());
              }}
              src={stratagile}
              alt="StratAgile"
            />
          )}

          <div className="menu-icon" onClick={() => dispatch(collapse())}>
            <IconButton
              sx={{
                width: "50px",
                height: "50px",
              }}
            >
              <CgMenuRightAlt style={{ width: "24px", height: "24px" }} />
            </IconButton>
          </div>
        </div>

        <Menu className="menuitem-loading">
          <MenuItem
            style={{
              fontSize: "0.85rem",
              fontWeight: "500",
            }}
            onClick={() => {
              navigate("/");
              dispatch(togglecollapsedOnMob());
            }}
            icon={<DashboardIco style={{ width: "1rem" }} color="#f94254cb" />}
            className={location.pathname === "/" ? "active bg-active" : ""}
          >
            Dashboard
          </MenuItem>
          {employeeRoleId === "1" ||
          employeeRoleId === "2" ||
          employeeRoleId === "3" ? (
            <SubMenu
              style={{
                fontSize: "0.85rem",
                fontWeight: "500",
              }}
              icon={<MdAdminPanelSettings size="1.2rem" color="#f94254cb" />}
              label="Administrative"
              defaultOpen={location.pathname.startsWith("/administrative")}
              onToggle={() => toggleSubMenu("administrative")}
              collapsed={subMenuCollapsed.administrative}
            >
              <MenuItem
                style={{
                  paddingLeft: collapsed ? "2rem" : "4rem",
                  fontSize: "0.80rem",
                }}
                onClick={() => {
                  navigate("/administrative/leave-report");
                  dispatch(togglecollapsedOnMob());
                }}
                className={
                  location.pathname === "/administrative/leave-report"
                    ? "active bg-active"
                    : ""
                }
              >
                Leave Report
              </MenuItem>
              <MenuItem
                style={{
                  paddingLeft: collapsed ? "2rem" : "4rem",
                  fontSize: "0.80rem",
                }}
                onClick={() => {
                  navigate("/administrative/attendance-report");
                  dispatch(togglecollapsedOnMob());
                }}
                className={
                  location.pathname === "/administrative/attendance-report"
                    ? "active bg-active"
                    : ""
                }
              >
                Attendance Report
              </MenuItem>
              <MenuItem
                style={{
                  paddingLeft: collapsed ? "2rem" : "4rem",
                  fontSize: "0.80rem",
                }}
                onClick={() => {
                  navigate("/administrative/first-login-report");
                  dispatch(togglecollapsedOnMob());
                }}
                className={
                  location.pathname === "/administrative/first-login-report"
                    ? "active bg-active"
                    : ""
                }
              >
                First Login Report
              </MenuItem>
            </SubMenu>
          ) : null}
          <SubMenu
            style={{
              fontSize: "0.85rem",
              fontWeight: "500",
            }}
            icon={<BUIcon style={{ width: "1rem" }} color="#f94254cb" />}
            label="BU"
            defaultOpen={location.pathname.startsWith("/bu")}
            onToggle={() => toggleSubMenu("bu")}
            collapsed={subMenuCollapsed.bu}
          >
            {employeeRoleId === "1" ? (
              <MenuItem
                className={
                  location.pathname === "/bu/create" ? "active bg-active" : ""
                }
                style={{
                  paddingLeft: collapsed ? "2rem" : "4rem",
                  fontSize: "0.80rem",
                }}
                onClick={() => {
                  navigate("/bu/create");
                  dispatch(togglecollapsedOnMob());
                }}
              >
                Create BU
              </MenuItem>
            ) : (
              <></>
            )}

            <MenuItem
              style={{
                paddingLeft: collapsed ? "2rem" : "4rem",
                fontSize: "0.80rem",
              }}
              className={
                location.pathname === "/bu/list" ||
                location.pathname.startsWith("/bu/edit/")
                  ? "active bg-active"
                  : ""
              }
              onClick={() => {
                navigate("/bu/list");
                dispatch(togglecollapsedOnMob());
              }}
            >
              BU List
            </MenuItem>
          </SubMenu>
          <SubMenu
            style={{
              fontSize: "0.85rem",
              fontWeight: "500",
            }}
            icon={<LeaveIco style={{ width: "1rem" }} color="#f94254cb" />}
            label="Leave"
            defaultOpen={location.pathname.startsWith("/leave")}
            onToggle={() => toggleSubMenu("leave")}
            collapsed={subMenuCollapsed.leave}
          >
            {employeeRoleId === "4" ? (
              <></>
            ) : (
              <>
                {employeeRoleId === "2" ? (
                  <></>
                ) : (
                  <MenuItem
                    style={{
                      paddingLeft: collapsed ? "2rem" : "4rem",
                      fontSize: "0.80rem",
                    }}
                    className={
                      location.pathname === "/leave/create"
                        ? "active bg-active"
                        : ""
                    }
                    onClick={() => {
                      navigate("/leave/create");
                      dispatch(togglecollapsedOnMob());
                    }}
                  >
                    Create Leave Types
                  </MenuItem>
                )}

                <MenuItem
                  style={{
                    paddingLeft: collapsed ? "2rem" : "4rem",
                    fontSize: "0.80rem",
                  }}
                  className={
                    location.pathname === "/leave/list" ||
                    location.pathname.startsWith("/leave/edit/")
                      ? "active bg-active"
                      : ""
                  }
                  onClick={() => {
                    navigate("/leave/list");
                    dispatch(togglecollapsedOnMob());
                  }}
                >
                  Types of Leaves
                </MenuItem>
              </>
            )}
            <>
              {employeeRoleId === "1" ? (
                <></>
              ) : (
                <>
                  <MenuItem
                    style={{
                      paddingLeft: collapsed ? "2rem" : "4rem",
                      fontSize: "0.80rem",
                    }}
                    className={
                      location.pathname === "/leave/leave-apply"
                        ? "active bg-active"
                        : ""
                    }
                    onClick={() => {
                      navigate("/leave/leave-apply");
                      dispatch(togglecollapsedOnMob());
                    }}
                  >
                    Leave Apply
                  </MenuItem>
                  <MenuItem
                    style={{
                      paddingLeft: collapsed ? "2rem" : "4rem",
                      fontSize: "0.80rem",
                    }}
                    className={
                      location.pathname === "/leave/applied-leaves"
                        ? "active bg-active"
                        : ""
                    }
                    onClick={() => {
                      navigate("/leave/applied-leaves");
                      dispatch(togglecollapsedOnMob());
                    }}
                  >
                    Leave List
                  </MenuItem>

                  <MenuItem
                    style={{
                      paddingLeft: collapsed ? "2rem" : "4rem",
                      fontSize: "0.80rem",
                    }}
                    className={
                      location.pathname === "/leave/balances"
                        ? "active bg-active"
                        : ""
                    }
                    onClick={() => {
                      navigate("/leave/balances");
                      dispatch(togglecollapsedOnMob());
                    }}
                  >
                    Leave Balances
                  </MenuItem>
                </>
              )}
            </>
          </SubMenu>

          {employeeRoleId === "1" ? (
            <></>
          ) : (
            <>
              <SubMenu
                style={{
                  fontSize: "0.85rem",
                  fontWeight: "500",
                }}
                icon={<FaMailBulk size="1.2rem" color="#f94254cb" />}
                label="Requests"
                defaultOpen={location.pathname.startsWith("/request")}
                onToggle={() => toggleSubMenu("request")}
                collapsed={subMenuCollapsed.requests}
              >
                <MenuItem
                  style={{
                    paddingLeft: collapsed ? "2rem" : "4rem",
                    fontSize: "0.80rem",
                  }}
                  className={
                    location.pathname === "/request/leaves" ||
                    location.pathname.startsWith("/request/leaves/history")
                      ? "active bg-active"
                      : ""
                  }
                  onClick={() => {
                    navigate("/request/leaves");
                    dispatch(togglecollapsedOnMob());
                  }}
                >
                  Leave Requests
                </MenuItem>
              </SubMenu>
            </>
          )}
          <SubMenu
            style={{
              fontSize: "0.85rem",
              fontWeight: "500",
            }}
            icon={<HiOutlineCalendar size="1.2rem" color="#f94254cb" />}
            label="Calendar"
            defaultOpen={
              location.pathname.startsWith("/calendar") ||
              location.pathname.startsWith("/calendar")
            }
            onToggle={() => toggleSubMenu("holiday")}
            collapsed={subMenuCollapsed.calendar}
          >
            {employeeRoleId === "" ? (
              <></>
            ) : (
              <>
                {employeeRoleId === "" ? (
                  <></>
                ) : (
                  <>
                    {employeeRoleId === "2" ||
                    employeeRoleId === "4" ||
                    employeeRoleId === "1" ? (
                      <></>
                    ) : (
                      <>
                        <MenuItem
                          style={{
                            paddingLeft: collapsed ? "2rem" : "4rem",
                            fontSize: "0.80rem",
                            whiteSpace: "pre-line",
                          }}
                          className={
                            location.pathname === "/calendar/wokingdays/add"
                              ? "active bg-active"
                              : ""
                          }
                          onClick={() => {
                            navigate("/calendar/wokingdays/add");
                          }}
                        >
                          Add Special Working Days
                        </MenuItem>

                        <MenuItem
                          style={{
                            paddingLeft: collapsed ? "2rem" : "4rem",
                            fontSize: "0.80rem",
                          }}
                          className={
                            location.pathname === "/calendar/wokingdays/edit"
                              ? "active bg-active"
                              : ""
                          }
                          onClick={() => {
                            navigate("/calendar/wokingdays/edit");
                            dispatch(togglecollapsedOnMob());
                          }}
                        >
                          Special Working Days list
                        </MenuItem>
                      </>
                    )}
                    <MenuItem
                      style={{
                        paddingLeft: collapsed ? "2rem" : "4rem",
                        fontSize: "0.80rem",
                      }}
                      className={
                        location.pathname === "/calendar/wokingdays/list"
                          ? "active bg-active"
                          : ""
                      }
                      onClick={() => {
                        navigate("/calendar/wokingdays/list");
                        dispatch(togglecollapsedOnMob());
                      }}
                    >
                      Special Working Days
                    </MenuItem>
                  </>
                )}

                {employeeRoleId === "2" || employeeRoleId === "4" ? (
                  <></>
                ) : (
                  <>
                    <MenuItem
                      style={{
                        paddingLeft: collapsed ? "2rem" : "4rem",
                        fontSize: "0.80rem",
                      }}
                      className={
                        location.pathname === "/calendar/holiday/add"
                          ? "active bg-active"
                          : ""
                      }
                      onClick={() => {
                        navigate("/calendar/holiday/add");
                        dispatch(togglecollapsedOnMob());
                      }}
                    >
                      Add Holiday
                    </MenuItem>

                    <MenuItem
                      style={{
                        paddingLeft: collapsed ? "2rem" : "4rem",
                        fontSize: "0.80rem",
                      }}
                      className={
                        location.pathname === "/calendar/holiday/list" ||
                        location.pathname.startsWith("/calendar/holiday/edit/")
                          ? "active bg-active"
                          : ""
                      }
                      onClick={() => {
                        navigate("/calendar/holiday/list");
                        dispatch(togglecollapsedOnMob());
                      }}
                    >
                      Holiday List
                    </MenuItem>
                  </>
                )}
              </>
            )}
            <MenuItem
              style={{
                paddingLeft: collapsed ? "2rem" : "4rem",
                fontSize: "0.80rem",
              }}
              className={
                location.pathname === "/calendar/holiday"
                  ? "active bg-active"
                  : ""
              }
              onClick={() => {
                navigate("/calendar/holiday");
                dispatch(togglecollapsedOnMob());
              }}
            >
              Holiday Calendar
            </MenuItem>
          </SubMenu>
          <SubMenu
            style={{
              fontSize: "0.85rem",
              fontWeight: "500",
            }}
            icon={<EmployeeIco style={{ width: "1rem" }} color="#f94254cb" />}
            label="Employee"
            onToggle={() => toggleSubMenu("employee")}
            defaultOpen={location.pathname.startsWith("/employee")}
            collapsed={subMenuCollapsed.employee}
          >
            {employeeRoleId === "4" || employeeRoleId === "2" ? (
              <></>
            ) : (
              <>
                <MenuItem
                  style={{
                    paddingLeft: collapsed ? "2rem" : "4rem",
                    fontSize: "0.80rem",
                  }}
                  className={
                    location.pathname === "/employee/create"
                      ? "active bg-active"
                      : ""
                  }
                  onClick={() => {
                    navigate("/employee/create");
                    dispatch(togglecollapsedOnMob());
                  }}
                >
                  Create Employee
                </MenuItem>

                <MenuItem
                  style={{
                    paddingLeft: collapsed ? "2rem" : "4rem",
                    fontSize: "0.80rem",
                  }}
                  className={
                    location.pathname === "/employee/leave-allocation"
                      ? "active bg-active"
                      : ""
                  }
                  onClick={() => {
                    navigate("/employee/leave-allocation");
                    dispatch(togglecollapsedOnMob());
                  }}
                >
                  Leave Allocation
                </MenuItem>

                <MenuItem
                  style={{
                    paddingLeft: collapsed ? "2rem" : "4rem",
                    fontSize: "0.80rem",
                  }}
                  className={
                    location.pathname ===
                    "/employee/add-compensation-leave-allocation"
                      ? "active bg-active"
                      : ""
                  }
                  onClick={() => {
                    navigate("/employee/add-compensation-leave-allocation");
                    dispatch(togglecollapsedOnMob());
                  }}
                >
                  Grant comp-off leave
                </MenuItem>

                <MenuItem
                  style={{
                    paddingLeft: collapsed ? "2rem" : "4rem",
                    fontSize: "0.80rem",
                  }}
                  className={
                    location.pathname === "/employee/compensation-leave-list"
                      ? "active bg-active"
                      : ""
                  }
                  onClick={() => {
                    navigate("/employee/compensation-leave-list");
                    dispatch(togglecollapsedOnMob());
                  }}
                >
                  Comp-off leave list
                </MenuItem>
              </>
            )}
            <MenuItem
              style={{
                paddingLeft: collapsed ? "2rem" : "4rem",
                fontSize: "0.80rem",
              }}
              className={
                location.pathname === "/employee/list" ||
                location.pathname.startsWith("/employee/edit/")
                  ? "active bg-active"
                  : ""
              }
              onClick={() => {
                navigate("/employee/list");
                dispatch(togglecollapsedOnMob());
              }}
            >
              Employee List
            </MenuItem>
            <MenuItem
              style={{
                paddingLeft: collapsed ? "2rem" : "4rem",
                fontSize: "0.80rem",
              }}
              className={
                location.pathname === "/employee/alumni"
                  ? "active bg-active"
                  : ""
              }
              onClick={() => {
                navigate("/employee/alumni");
                dispatch(togglecollapsedOnMob());
              }}
            >
              Alumni
            </MenuItem>
          </SubMenu>
          <MenuItem
            style={{
              fontSize: "0.85rem",
              fontWeight: "500",
            }}
            className={
              location.pathname === "/attendance" ? "active bg-active" : ""
            }
            icon={<FaRegCalendarCheck size="1.2rem" color="#f94254cb" />}
            onClick={() => {
              navigate("/attendance");
              dispatch(togglecollapsedOnMob());
            }}
          >
            Attendance
          </MenuItem>
          <MenuItem
            style={{
              fontSize: "0.85rem",
              fontWeight: "500",
            }}
            className={
              location.pathname === "/stratdrive" ? "active bg-active" : ""
            }
            icon={<DriveIco style={{ width: "1rem" }} color="#f94254cb" />}
            onClick={() => {
              navigate("/stratdrive");
              dispatch(togglecollapsedOnMob());
            }}
          >
            Strat Drive
          </MenuItem>
          <SubMenu
            style={{
              fontSize: "0.85rem",
              fontWeight: "500",
            }}
            icon={<SettingsIco style={{ width: "1rem" }} color="#f94254cb" />}
            label="Settings"
            onToggle={() => toggleSubMenu("settings")}
            defaultOpen={location.pathname.startsWith("/settings")}
            collapsed={subMenuCollapsed.employee}
          >
            <MenuItem
              style={{
                paddingLeft: collapsed ? "2rem" : "4rem",
                fontSize: "0.80rem",
              }}
              className={
                location.pathname === "/settings/login-history"
                  ? "active bg-active"
                  : ""
              }
              onClick={() => {
                navigate("/settings/login-history");
                dispatch(togglecollapsedOnMob());
              }}
            >
              Login History
            </MenuItem>
            <MenuItem
              style={{
                paddingLeft: collapsed ? "2rem" : "4rem",
                fontSize: "0.80rem",
              }}
              className={
                location.pathname === "/settings/change-password"
                  ? "active bg-active"
                  : ""
              }
              onClick={() => {
                navigate("/settings/change-password");
                dispatch(togglecollapsedOnMob());
              }}
            >
              Change Password
            </MenuItem>
          </SubMenu>
        </Menu>
      </Sidebar>
    </div>
  );
}

export default SideBar;
