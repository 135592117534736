import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress } from "@mui/material";
import { GrAttachment } from "react-icons/gr";
import { getYear } from "date-fns";
import { LEAVE_API } from "../../../constants/constants";
import DateNSession from "./DateNSession";
import useFetch from "../../../hooks/useFetch";
import ErrorPopup from "../../global/Error/ErrorPopup";
import SingleSelect from "../../global/Select/SingleSelect";
import AllocatedLeaves from "./AllocatedLeaves";
import Title from "../../global/Title/Title";
import axios from "../../../axios/axios";
import "./ApplyLeave.css";
import LoadingIndicator from "../../global/Loading/LoadingIndicator";

export default function ApplyLeave() {
  // ----------------- loacla storage ---------------
  const navigate = useNavigate();

  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");

  const minSelectableDate = new Date();

  // -------------- variable -----------------
  const collapsed = true;

  // -----------states -----------------------

  const [loading, setLoading] = useState(true);

  const [file, setFile] = useState();
  const [leaveBalances, setLeaveBalances] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [applyingData, setApplyingData] = useState([]);
  const [leaveType, setLeaveType] = useState([]);
  const [totalDays, setTotalDays] = useState(null);

  const [selectedOption, setSelectedOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [enableSubmission, setEnableSubmission] = useState(false);

  //########################## test

  const year = new Date().getFullYear();

  const sessionOptions = [
    {
      value: "1",
      label: "Session 1",
    },
    {
      value: "2",
      label: "Session 2",
    },
  ];

  const [formData, setFormData] = useState({
    startDate: startDate.toISOString().split("T")[0],
    startSession: null,
    endDate: startDate.toISOString().split("T")[0],
    endSession: null,
    noOfDaysOfLeave: 0,
    leaveTypeId: "",
    reportingTo: "",
    applyingTo: [],
    attachment: undefined,
    reason: "",
  });
  const [validationError, setValidationError] = useState({
    errorState: false,
    dateError: "",
    leaveTypeError: "",
    attachmentError: "",
    reasonError: "",
  });

  const { responseData: reportingDataObj, loading: reportingDataObjLoading } =
    useFetch("employee/employee_reportingto");
  const { responseData: applyingDataObj, loading: applyingDataObjLoading } =
    useFetch("/employee/get_applying_to_employees_list");

  useEffect(() => {
    axios
      .post(
        `${LEAVE_API.GET_LEAVE_BALANCE + userId}`,
        { userId, year },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setLeaveBalances(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.error("error", error);
      });
  }, [logInToken, userId, year]);

  useEffect(() => {
    const year = getYear(startDate);
    axios
      .post(
        "leaves/employee_leave_allocation_data",
        { userId, year },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setLeaveType(response.data.data);
      })
      .catch((error) => {
        // console.error("error", error);
      });
  }, [logInToken, userId, startDate]);

  // ----------------- select options generator functions ----------------------------------
  const leaveTypeOptions = leaveType
    .filter((data) =>
      data.leaveTypeName?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
    .map((data) => ({
      value: data.leaveTypeId,
      label: data.leaveTypeName,
    }));

  const reportingOptions = reportingDataObj.data
    ?.filter((data) =>
      data.employeeName?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
    .map((data) => ({
      value: data.id,
      label: data.employeeName,
    }));

  const applyingOptions = applyingDataObj
    .filter((data) =>
      data.employeeName?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
    .map((data) => ({
      value: data.id,
      label: data.employeeName,
    }));

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile(selectedFile);
      };
      reader.readAsDataURL(selectedFile);
    }
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        attachment: selectedFile,
      };
    });
  };

  const handleApplyingToSelection = (selectedValue, selectObj) => {
    let status = selectObj.action;
    if (status === "select-option") {
      if (selectedValue.length !== 0) {
        const updatedEmployeeOptions = applyingOptions.filter(
          (cc) => cc.value !== selectedValue.value
        );
        setApplyingData(updatedEmployeeOptions);
      } else {
        setApplyingData(applyingOptions);
      }
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          applyingTo: [
            ...prevFormData.applyingTo,
            selectedValue[selectedValue.length - 1].value,
          ],
        };
      });
    } else if (status === "clear") {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          applyingTo: [],
        };
      });
    } else {
      const updatedList = selectedValue.map((item) => item.value);
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          applyingTo: updatedList,
        };
      });
    }
  };

  const handleLeaveType = (selectedValue) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        leaveTypeId: selectedValue?.value ? selectedValue?.value : "",
      };
    });
  };

  const handleStartData = (value) => {
    const date = value?.toISOString().split("T")[0];
   // new updates
    setStartDate(value);
    // end new Updates

    if (date > formData.endDate) {
      setFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          startDate: date,
          endDate: date,
        };
        handleNumberOfDays(updatedFormData);
        return updatedFormData;
      });
    }
    // new updates
    else if (date < formData.endDate) {
      setFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          startDate: date,
          endDate: prevFormData.startDate,
        };
        handleNumberOfDays(updatedFormData);
        return updatedFormData;
      });
    }
    // end new updaates

    setFormData((prevFormData) => {
      let updatedFormData = {
        ...prevFormData,
        startDate: date,
      };

      if (prevFormData.startDate === prevFormData.endDate) {
        updatedFormData.endDate = date;
      }
      handleNumberOfDays(updatedFormData);

      return updatedFormData;
    });
  };

  const handleEndDate = (value) => {
    const date = value.toISOString().split("T")[0];
    setEndDate(value);
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        endDate: date,
      };
      handleNumberOfDays(updatedFormData);
      return updatedFormData;
    });
  };

  const handleStartDataSession = (selectedValue) => {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        startSession: selectedValue?.value ? selectedValue?.value : null,
      };
      handleNumberOfDays(updatedFormData);
      return updatedFormData;
    });
  };

  const handleEndDataSession = (selectedValue) => {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        endSession: selectedValue?.value ? selectedValue?.value : null,
      };
      handleNumberOfDays(updatedFormData);
      return updatedFormData;
    });
  };

  // return {
  //   ...prevFormData,
  //   endSession: selectedValue?.value ? selectedValue?.value : "1",
  // };

  const calculateDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInMilliseconds = end - start;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return Math.abs(differenceInDays);
  };

  const handleNumberOfDays = (updatedFormData) => {
    let calculatedDays = 0;
    const startDate = updatedFormData.startDate;
    const endDate = updatedFormData.endDate;
    const startSession = updatedFormData.startSession;
    const endSession = updatedFormData.endSession;

    if (!startSession || !endSession) {
      // number of days will only display after selecting the session
      setTotalDays(0);
      return;
    }

    if (endDate === startDate) {
      if (startSession === "1" && endSession === "2") {
        calculatedDays = 1;
      } else if (startSession === "2" && endSession === "1") {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            endSession: "2",
          };
        });
      } else {
        calculatedDays = 0.5;
      }
    } else {
      calculatedDays = calculateDaysDifference(startDate, endDate) - 1;

      if (startSession === "1" && endSession === "2") {
        calculatedDays += 2;
      } else if (startSession === "2" && endSession === "1") {
        calculatedDays += 1;
      } else {
        calculatedDays += 1.5;
      }
    }
    setTotalDays(calculatedDays);
  };

  const handleReason = (e) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        reason: e.target.value,
      };
    });
  };
  const handleTabKey = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          reason: prevFormData.reason + "    ",
        };
      });
    }
  };

  useEffect(() => {
    if (validationError.errorState) {
      // Set a timer to hide the error after 2 seconds
      const timer = setTimeout(() => {
        setValidationError((prevState) => ({
          ...prevState,
          errorState: false,
        }));
        setEnableSubmission(false);
      }, 4000);

      // Clear the timer when the component is unmounted or validationError changes
      return () => clearTimeout(timer);
    }
  }, [validationError]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setEnableSubmission(true);

    const leaveObj = leaveType.filter(
      (leave) => leave.leaveTypeId === formData.leaveTypeId
    );
    const remainigLeave = leaveObj[0]?.remainingDays;

    let dataToSubmit = { ...formData, reportingTo: reportingOptions[0]?.value };

    let validationErrorObj = {
      errorState: false,
      dateError: "",
      leaveTypeError: "",
      attachmentError: "",
      reasonError: "",
    };
    if (formData.startSession && formData.endSession) {
      if (
        formData.endDate === formData.startDate &&
        formData.startSession > formData.endSession
      ) {
        validationErrorObj = {
          ...validationErrorObj,
          dateError: "Choose the right sessions",
          errorState: true,
        };
      }
    } else {
      validationErrorObj = {
        ...validationErrorObj,
        dateError: "Please choose sessions",
        errorState: true,
      };
    }

    if (formData.leaveTypeId === "") {
      validationErrorObj = {
        ...validationErrorObj,
        leaveTypeError: "Choose a leave type",
        errorState: true,
      };
    }

    if (
      leaveObj[0]?.isAttachmentRequired === "1" &&
      formData.attachment === undefined
    ) {
      validationErrorObj = {
        ...validationErrorObj,
        attachmentError: `Attachment is mandatory for ${
          leaveObj[0]?.leaveTypeName
        } ${leaveObj[0]?.leaveTypeName.includes("Leave") ? "" : "Leave"}`,
        errorState: true,
      };
    }
    if (formData.reason === "") {
      validationErrorObj = {
        ...validationErrorObj,
        reasonError: "Provide a reason to take leave",
        errorState: true,
      };
    }
    if (totalDays !== formData.noOfDaysOfLeave) {
      dataToSubmit = { ...dataToSubmit, noOfDaysOfLeave: totalDays };
    }
    if (totalDays > remainigLeave) {
      validationErrorObj = {
        ...validationErrorObj,
        leaveTypeError: `You can't take leave more than ${remainigLeave} for ${leaveObj[0].leaveTypeName}`,
        errorState: true,
      };
    }
    if (!validationErrorObj.errorState) {
      axios
        .post(LEAVE_API.APPLY_LEAVE, dataToSubmit, {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setEnableSubmission(false);

          navigate("/leave/applied-leaves");
        })
        .catch((error) => {
          setEnableSubmission(false);

          console.error("error", error);
        });
      setValidationError((pre) => ({ ...pre, ...validationErrorObj }));
    } else {
      setValidationError((pre) => ({ ...pre, ...validationErrorObj }));
    }
  };

  // ------------------- component --------------------
  return (
    <div className={`page-area ${collapsed ? "sidebar-collapsed" : ""}`}>
      <div className={`sidebar-area ${collapsed ? "sidebar-collapsed" : ""}`} />
      <div className={`postpage-area ${collapsed ? "postpage-collapsed" : ""}`}>
        <section className="BU-page">
          {validationError.errorState && <ErrorPopup data={validationError} />}
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Title
                text="Apply Leave"
                propClass="pb-5 leave-apply_header page_header"
              />

              <div className="leave-apply_top-form">
                <div className="leave-apply_details_container">
                  {/* section to  */}
                  <div className="leave-apply_details-section1">
                    <div className="leave-apply_To w-100">
                      <h3 className="leave-apply_input-label">To</h3>
                      <div className="leave-apply_section">
                        <div className="leave-apply_reporting-to ">
                          <input
                            type="text"
                            disabled
                            value={
                              reportingOptions !== undefined
                                ? reportingOptions[0]?.label
                                : "loading..."
                            }
                          ></input>
                        </div>
                        <div className="leave-apply_applying-to ">
                          <SingleSelect
                            selectOptions={applyingOptions}
                            handleSelection={handleApplyingToSelection}
                            value={selectedOption}
                            placeholder={"Cc"}
                            isMulty={true}
                          />
                        </div>
                      </div>
                    </div>

                    {/* date section  */}
                    <div className="leave-apply_date w-60">
                      <h3 className="leave-apply_input-label">
                        Choose Date and Session
                      </h3>
                      <div className="leave-apply_date_container">
                        <div className="leave-apply_duration ">
                          <div className="leave-apply_date-and-session ">
                            <div className="leave-apply_datepicker">
                              <div className="leave-apply_start-date w-fit-content">
                                <DateNSession
                                  options={sessionOptions}
                                  placeholder={"Start date"}
                                  Date={startDate}
                                  setDate={handleStartData}
                                  handleSesssionSelect={handleStartDataSession}
                                  // minSelectableDate={minSelectableDate}
                                />
                              </div>
                              <div className="leave-apply_end-date w-fit-content">
                                <DateNSession
                                  options={sessionOptions}
                                  placeholder={"Start date"}
                                  Date={
                                    endDate < startDate ? startDate : endDate
                                  }
                                  setDate={handleEndDate}
                                  handleSesssionSelect={handleEndDataSession}
                                  minSelectableDate={startDate}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="leave-apply_totaldays">
                            <p>No. of days :</p>
                            <p className="leave-apply_count">
                              {totalDays ? totalDays : formData.noOfDaysOfLeave}
                            </p>

                            <p className="leave-apply_exclude">
                              Holidays in-between are not excluded
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* showing available leaves  */}
                  <div className="show-leaves_container">
                    <div className="leave-apply_show-leaves">
                      <AllocatedLeaves allocatedLeavesList={leaveBalances} />
                    </div>
                  </div>
                </div>

                {/* reason  */}
                <div className="leave-apply_info">
                  <h3 className="leave-apply_input-label">Leave Information</h3>
                  <div className="leave-apply_info_area">
                    <div className="leave-apply_leavetype-selection">
                      <SingleSelect
                        // options={reportingToOptions}
                        selectOptions={leaveTypeOptions}
                        handleSelection={handleLeaveType}
                        // onChange={handleSelection}
                        value={selectedOption}
                        placeholder={"Leave Type"}
                        isMulty={false}
                      />
                    </div>
                    <div className="leave-apply-reason_container">
                      <h4 className="leave-apply_reason-h4">Reason:</h4>
                      <textarea
                        name="reason"
                        id=""
                        cols="20"
                        rows="7"
                        value={formData.reason}
                        onChange={handleReason}
                        onKeyDown={handleTabKey}
                      ></textarea>
                    </div>
                    <div className="leave-apply_footer">
                      <div className="leave-apply_attach">
                        <h4>Attach File</h4>
                        <div className="leave-apply_submit">
                          <div className="leave_apply_attachment">
                            <Button>
                              <input
                                type="file"
                                accept="image/*"
                                name="attachment"
                                onChange={handleFileUpload}
                              />
                            </Button>
                          </div>
                          <GrAttachment className="leave_apply_attachment-icon" />
                          <p>File Types: jpg, jpeg, png</p>
                        </div>
                      </div>
                      <div className="leave-apply_footer_btn">
                        <LoadingButton
                          loading={enableSubmission}
                          onClick={handleSubmit}
                          className="leave_apply_submit_btn"
                        >
                          {enableSubmission ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Submit"
                          )}
                        </LoadingButton>
                        <Button
                          onClick={() => {
                            navigate("/");
                          }}
                          className="leave_apply_cancel_btn"
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
    </div>
  );
}
