import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { UserDataContext } from "../../context/UserContext";
import { useDispatch } from "react-redux";
//icons
import { AiOutlinePoweroff } from "react-icons/ai";
import { BiSolidUser } from "react-icons/bi";
import { CgMenuRightAlt } from "react-icons/cg";

import useTerminateSession from "../../hooks/useTerminateSession";
import "./Header.css";

function Header({ collapse }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = useTerminateSession();
  const { userData } = useContext(UserDataContext);

  return (
    <div className="main-header">
      <div className="header-wrapper">
        <div className="mobileview-sidebarToggle">
          <IconButton
            sx={{
              width: "50px",
              height: "50px",
            }}
            onClick={() => dispatch(collapse())}
          >
            <CgMenuRightAlt
              style={{ width: "30px", height: "30px", marginTop: "0px" }}
              className="menu-icon"
            />
          </IconButton>
        </div>
        <div className="header-btn-group">
          <div>
            <h3 className="fzr-1">{userData?.userName}</h3>
          </div>
          <Tooltip title="Profile" arrow>
            <IconButton
              className="profile-btn header-btn"
              onClick={() => {
                navigate("/profile");
              }}
            >
              <BiSolidUser style={{ width: "18px", height: "18px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout" arrow>
            <IconButton
              className="logout-btn header-btn"
              onClick={() => handleLogout()}
            >
              <AiOutlinePoweroff style={{ width: "18px", height: "18px" }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default Header;
