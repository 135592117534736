import React from "react";

function TodayLogout() {
  return (
    <svg
      width="30"
      height="27"
      viewBox="0 0 53 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0018823 27.6716C0.00251136 27.5778 0.0119456 27.4834 0.0308171 27.3908C0.04214 27.3384 0.0616438 27.2897 0.0773701 27.2391C0.0912092 27.1952 0.101275 27.1502 0.119517 27.1075C0.145937 27.0448 0.18116 26.9887 0.215128 26.9308C0.231484 26.9028 0.244067 26.8724 0.262939 26.845C0.322069 26.7591 0.389377 26.6793 0.464863 26.6061L11.0669 16.3389C11.6815 15.7438 12.6766 15.7438 13.2906 16.3389C13.9052 16.9335 13.9052 17.8978 13.2906 18.4924L5.36957 26.1645H33.1302C33.9989 26.1645 34.7028 26.8462 34.7028 27.6875C34.7028 28.5287 33.9989 29.2104 33.1302 29.2104H5.36831L13.2918 36.8825C13.9064 37.4771 13.9064 38.4414 13.2918 39.036C12.9849 39.3333 12.5823 39.4819 12.1797 39.4819C11.7771 39.4819 11.3751 39.3333 11.0675 39.036L0.460462 28.7651C0.451027 28.756 0.445364 28.745 0.435929 28.7359C0.373653 28.6725 0.315778 28.6061 0.265454 28.5336C0.235259 28.4904 0.214504 28.4435 0.189342 28.3972C0.166696 28.3557 0.139646 28.3155 0.121403 28.2717C0.0974995 28.2162 0.0830278 28.159 0.0666725 28.1023C0.0553496 28.0639 0.0396235 28.0268 0.0314458 27.9872C0.0113162 27.8885 -3.80949e-06 27.7886 -3.80949e-06 27.6887C-3.80949e-06 27.6826 0.0018823 27.6765 0.0018823 27.6716ZM21.9255 31.6484C22.7943 31.6484 23.4982 32.3301 23.4982 33.1713V41.332C23.4982 47.21 28.4368 51.9927 34.5066 51.9927H38.8464C44.9161 51.9927 49.8547 47.21 49.8547 41.332V14.0435C49.8547 8.1655 44.9161 3.38278 38.8464 3.38278H34.5066C28.4368 3.38278 23.4982 8.1655 23.4982 14.0435V21.6979C23.4982 22.5392 22.7943 23.2209 21.9255 23.2209C21.0568 23.2209 20.3529 22.5392 20.3529 21.6979V14.0435C20.3529 6.48597 26.7026 0.336853 34.5066 0.336853H38.8464C46.6504 0.336853 53 6.48597 53 14.0435V41.332C53 48.8896 46.6504 55.0387 38.8464 55.0387H34.5066C26.7026 55.0387 20.3529 48.8896 20.3529 41.332V33.1713C20.3529 32.3301 21.0568 31.6484 21.9255 31.6484Z"
        fill="#47A7FF"
      />
    </svg>
  );
}

export default TodayLogout;
