import React from "react";
// import Layout from "../Layout/Layout";
import AttendanceData from "../../components/Attendance/Attendance/AttendanceData";
import AdministrativeLayout from "../Administrative/AdministrativeLayout";

function AttendancePage() {
  return (
    <AdministrativeLayout>
      <AttendanceData />
    </AdministrativeLayout>
  );
}

export default AttendancePage;
