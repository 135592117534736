import React from "react";
import useFetch from "../../hooks/useFetch";
import { DASHBOARD_API } from "../../constants/constants";

function OnLeavePending() {

  const { responseData } = useFetch(
    DASHBOARD_API.FETCH_ON_LEAVE_PENDING
  );

  return (
    <div className="dashboard_onleave-today-pending">
      <div className="Holiday-wrap-head">
        <h2 className="fzr-1">Approval Pendings</h2>
      </div>
      <div className="dashboard_onleave-inner-wrap">
        {responseData?.whoseOnLeave && responseData.whoseOnLeave.length > 0 ? (
          <div className="onLeave-card-list">
            {responseData.whoseOnLeave.map((onLeave, index) => (
              <div className="onLeave-card" key={index}>
                <h4 className="fzr-09">{onLeave?.employeeName}</h4>
                <div className="onLeaveCardlistitem">
                  <p className="fzr-08">{onLeave?.departmentName}</p>
                  <p className="fzr-08">{onLeave?.buName}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="onLeave-message fzr-09">No leave pendings.</p>
        )}
      </div>
    </div>
  );
}

export default OnLeavePending;
