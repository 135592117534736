
const useTimeExtractor = (datetimeString) => {
  const extractTime = () => {
    return new Date(datetimeString).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  return extractTime();
};

export default useTimeExtractor;
