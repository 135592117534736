import React, { useState, useRef, useEffect } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { IconButton } from "@mui/material";
import "./Dropdown.css";

export default function Dropdown({
  optionsList, // displaying options list
  onSelect, // passing options to dropdown
  placeholder, // display placeholder
  customDropdownClass, // passing custom style class name
  isClearable, // if isclear is true then user can clear selection value else cross icon will be hidden
}) {
  const [isOpen, setIsOpen] = useState(false); // this is used for dropdown visible
  const [filteringOption, setFilteringOption] = useState([]); // this is used for filtering
  const [searchTerm, setSearchTerm] = useState(""); // this is used for search designations
  const [userSelected, setUserSelected] = useState(null); // this is used for displaying user selection options in the dropdown

  const dropdownRef = useRef(null);

  const toggledOptions = () => {
    // this is used for displaying options in the dropdown
    setIsOpen(!isOpen);
    if (!isOpen) {
      setFilteringOption(optionsList);
    }
  };


  const handleClickOption = (options) => {
    // option clicked value passed to dropdown component
    console.log("Option selected:", options);
    onSelect(options);
    setIsOpen(!isOpen);
    setUserSelected(options);
  };

  const handleResetSelect = (e) => {
    // reset selected value
    e.stopPropagation();

    setFilteringOption(optionsList);
    onSelect(null);
    setUserSelected(null);
    setSearchTerm("");
  };

  const handleFilter = (e) => {
    // search  function
    console.log("search value ", e.target.value);
    setSearchTerm(e.target.value);
    // filtering data according to the search criteria
    const filteredOptions = optionsList.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteringOption(filteredOptions);
  };

  const handleOutsideClick = (e) => {
    // this is used to close the dropdown when the user clicks outside
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // this is used to close the dropdown when the user clicks outside for triggering
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className={`custom-dropdown ${customDropdownClass}`} ref={dropdownRef}>
      <div className="dropdown-header shadow" onClick={toggledOptions}>
        <input
          type="text"
          placeholder={!userSelected ? placeholder : ""}
          value={userSelected ? userSelected.label : searchTerm}
          onChange={handleFilter}
        />
        {userSelected && isClearable && (
          <IconButton onClick={handleResetSelect}>
            <RxCross2 size="1rem" opacity={0.7} color="rgba(71, 167, 255, 1)" />
          </IconButton>
        )}
        <IconButton>
          {isOpen ? (
            <MdKeyboardArrowUp
              size="1.5rem"
              opacity={0.7}
              color="rgba(71, 167, 255, 1)"
            />
          ) : (
            <MdKeyboardArrowDown
              size="1.5rem"
              opacity={0.7}
              color="rgba(71, 167, 255, 1)"
            />
          )}
        </IconButton>
      </div>
      {isOpen && (
        <div className="dropdown-options shadow">
          {filteringOption?.map((options) => (
            <div
              className="dropdown-items"
              key={options.value}
              onClick={() => handleClickOption(options)}
            >
              {options.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
