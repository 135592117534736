import React from "react";
import Layout from "../Layout/Layout";
// import LeaveRequests from "../../components/Leaves/LeaveRequests/LeaveRequests";
// import LeaveRequests from "../../components/Leaves/LeaveRequests/LeaveRequests";
import RequestLeave from "../../components/Leaves/LeaveRequests/RequestLeave";
function LeaveRequestsPage() {
  return (
    <Layout>
      {/* <LeaveRequests /> */}
      {/* <RequestLea  */}
      <RequestLeave />
    </Layout>
  );
}

export default LeaveRequestsPage;
