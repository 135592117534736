import React from 'react'

function LoadingIndicator() {
  return (
    <div className="loader_container">
      <div className="loader" />
    </div>
  )
}

export default LoadingIndicator