import React, { useEffect, useState } from "react";
import { FcManager, FcOrganization } from "react-icons/fc";
import { DASHBOARD_API } from "../../constants/constants";
import useTerminateSession from "../../hooks/useTerminateSession";
import axios from "../../axios/axios";
import "../../assets/css/DashBoard.css";

function DashBoardCard() {
  const [cardData, setCardData] = useState([]);
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");
  const handleLogout = useTerminateSession();

  useEffect(() => {
    axios
      .post(
        DASHBOARD_API.FETCH_DASHBOARD_DATA,
        {},
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((response) => {
        setCardData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          handleLogout();
        }
        console.error("error", error);
      });
  }, [logInToken, userId]);

  return (
    <>
      <div className="dashboard-card-wrap">
        <div className="dashboard-card-items">
          <div className="dashboard-card-details">
            <FcManager style={{ width: "28px", height: "24px" }} />
            <p>Total Employees</p>
          </div>
          <p className="dashboard-total">{cardData?.employeeCount}</p>
        </div>

        <div className="dashboard-card-items">
          <div className="dashboard-card-details">
            <FcManager style={{ width: "28px", height: "24px" }} />
            <p>New Joiners</p>
          </div>
          <p className="dashboard-total">{cardData?.newEmployeeCount}</p>
        </div>

        <div className="dashboard-card-items">
          <div className="dashboard-card-details">
            <FcOrganization style={{ width: "28px", height: "24px" }} />
            <p>Total BU</p>
          </div>
          <p className="dashboard-total">{cardData?.buCount}</p>
        </div>
      </div>
    </>
  );
}

export default DashBoardCard;
