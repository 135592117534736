import React, { useEffect, useState } from "react";
import AttendanceReport from "../AttendanceReport/AttendanceReport";
import CustomDatePicker from "../../global/Datepicker/CustomDatePicker";
import "../AttendanceReport/AttendanceReport.css";
import axios from "../../../axios/axios";
import formateDate from "../../../util/formateDate";
import AttendancesHeader from "../Attendance/AttendancesHeader";
import { ATTENDANCE } from "../../../constants/constants";
import Title from "../../global/Title/Title";

function AttendanceData() {
  const logInToken = localStorage.getItem("logInToken");
  const userId = localStorage.getItem("userId");


  const [selectDate, setSelectDate] = useState();
  const [Attendance, setAttendance] = useState([]);
  const [AttendanceTotal, setAttendanceTotal] = useState([]);
  const [firstAndLastLog, setFirstAndLastLog] = useState([]);
  const [loading, setLoading] = useState(true);

  const selectStartDate = (value) => {
    setSelectDate(value);
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];

    const logDate = selectDate === currentDate ? null : selectDate;

    setFirstAndLastLog({
      lastLogout: "",
      firstLogin: "",
    });

    axios
      .post(
        ATTENDANCE.USER_ATTENDANCE,
        { logDate: logDate ? formateDate(logDate) : null },
        {
          headers: {
            Authorization: `Bearer ${logInToken}`,
            UserId: `${userId}`,
          },
        }
      )
      .then((res) => {
        setAttendance(res.data.employeeAttendanceReport);
        setAttendanceTotal(res.data);
        const lastLogout = res.data.employeeAttendanceReport.filter(
          (log) => log?.inOrOut === "out"
        )[0];
        if (lastLogout) {
          setFirstAndLastLog({
            lastLogout: lastLogout.LogDate,
            firstLogin: res.data.firstAndLastLogin.firstLogin,
          });
        } else {
          setFirstAndLastLog({
            lastLogout: "",
            firstLogin: res.data.firstAndLastLogin.firstLogin,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [selectDate, logInToken, userId, setAttendance, setFirstAndLastLog]);

  return (
    <div className="BU-page">
      <Title text="Attendance" propClass="pb-10" />
      <div className="attendance_report_function">
        <div className="leave-report_query-section_data ">
          <CustomDatePicker
            Date={selectDate}
            setDate={selectStartDate}
            className={""}
            placeholderText={`${new Date()
              .toLocaleDateString("en-GB")
              .split("/")
              .join(" - ")}`}
          />
        </div>
      </div>
      <AttendancesHeader
        AttendanceDatasToday={AttendanceTotal}
        firstAndLastLog={firstAndLastLog}
        loading={loading}
      />
      <AttendanceReport AttendanceDatas={Attendance} loading={loading} />
    </div>
  );
}

export default AttendanceData;
