import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="bg-footer">
      <div className="footer-content">
       <strong>StratAgile</strong> © {new Date().getFullYear()} All rights reserved.
      </div>
    </div>
  );
}

export default Footer;
