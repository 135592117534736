const useFormattedDate = (dateFormat) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const formattedDate = formatDate(dateFormat);

  return <>{formattedDate}</>;
};

export default useFormattedDate;
