import React, { useState } from "react";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import axios from "../../axios/axios";
import "../../assets/css/ForgotPassword.css";
import { AUTH_API } from "../../constants/constants";

function Otp() {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (otpValue) => {
    setOtp(otpValue);

    if (!otpValue) {
      setError("");
    }
  };

  const handleSubmit = () => {
    axios
      .post(
        AUTH_API.VERIFY_OTP,
        {
          employeeEmail: location.state.employeeEmail,
          otp: otp,
        },
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          const token = response.data;
          const logInToken = response.data.token;
          const userId = response.data.userId;

          localStorage.setItem("token", token);
          localStorage.setItem("logInToken", logInToken);
          localStorage.setItem("userId", userId);
          setError("");
          navigate("/update-password");
        } else {
          console.error("Login failed.");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError("Invalid otp");
        }
        console.error("Error:", error);
      });
  };

  const inputBorderColor = error ? "1px solid red" : "1px solid #6d6d6d";

  return (
    <div className="forgotPassword-page">
      <div className="otp-container">
        <h2 className="fzr-175 forgotPassword-title">
          Enter Verification Code
        </h2>
        <div className="fzr-09 otp-para">
          Kindly check your email inbox for the 6-digit verification code.
        </div>

        <div>
          <OTPInput
            containerStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              marginBottom: "10px",
            }}
            inputStyle={{
              width: "40px",
              height: "40px",
              fontSize: "18px",
              borderRadius: "5px",
              border: inputBorderColor,
            }}
            inputType="tel"
            value={otp}
            onChange={handleInputChange}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
            isInputNum
          />
        </div>

        <div className="otp-btn-area">
          <Button onClick={handleSubmit} className="otp-button">
            Verify
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Otp;
