import React from "react";
import "../../pages/Holiday/HolidayCalender/HolidayCalender.css";

function Calender({ data, currentMonthName, currentYearIndex, CalenderCheck }) {
  return (
    <div className="calender_box">
      {data?.map((data, index) => (
        <div
          key={index}
          className={`calender_card shadow ${
            data?.month === currentMonthName &&
            data?.year.toString() === currentYearIndex.toString()
              ? "highlighted"
              : ""
          }`}
        >
          <div className="calender_head">
            <h3>{data?.month}</h3>
            <p style={{ color: "#999999", fontSize: "0.9rem" }}>{data?.year}</p>
          </div>

          {data?.events?.map((event, id) => (
            <div className="calender_events" key={id}>
              <div>
                <p className="calender_events_date">{event?.date}</p>
                {CalenderCheck === "1" ? (
                  <></>
                ) : (
                  <p className="calender_events_day">{event?.day}</p>
                )}
              </div>
              <div>
                {CalenderCheck === "1" ? (
                  <p className="calender_events_name">{event?.day}</p>
                ) : (
                  <p className="calender_events_name">{event?.event}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Calender;
